import React, { useEffect, useState } from 'react'
import { IonFabButton, IonFab, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { add } from 'ionicons/icons'
import { useTranslation, Trans } from 'react-i18next'
import { ITEMS_PER_PAGE } from '../../../utility/pagination'
import AcceptorComment from '../../elements/AcceptorComment/AcceptorComment'
import MerchantProfileCustomerRate from './merchantProfileCustomerRate'
import api from '../../../api/Api'
import { Modal, parseHeaderForLinks, loadMoreDataWhenScrolled } from '../../../ayria'

interface MerchantProfileCustomerRatingsProps {
  merchant: any
}

const MerchantProfileCustomerRatings: React.FC<MerchantProfileCustomerRatingsProps> = ({ merchant }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<any>([])
  const [fetch, setFetch] = useState(false)
  const [pagination, setPagination] = useState({
    itemsPerPage: ITEMS_PER_PAGE,
    sort: 'createdDate',
    order: 'desc',
    activePage: 1,
  })
  const [links, setLinks] = useState<any>({})
  const [allowedToComment, setAllowedToComment] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false)

  const getData = async (page: number, size: number, sort: string) => {
    setFetch(false)
    const response: any = await api.get(
      `/user/comments/${merchant.referralCode}?page=${page}&size=${size}&sort=${sort}`
    )

    setLinks(parseHeaderForLinks(response.headers.link))
    setData(loadMoreDataWhenScrolled(data, response.data, links))
  }

  const handleLoadMore = () => {
    if (!fetch) {
      setPagination({ ...pagination, activePage: pagination.activePage + 1 })
    }
  }

  const checkIfAllowedToComment = async () => {
    const response: any = await api.get(`/customer/purchases/count/${merchant.code}`)
    setAllowedToComment(response.ok && response.data > 0 ? true : false)
  }

  const onSuccessfulComment = (comment: any) => {
    data.unshift(comment)
    setShowCommentModal(false)
  }

  useEffect(() => {
    setFetch(true)
  }, [pagination.activePage]) // eslint-disable-line

  useEffect(() => {
    if (fetch) {
      getData(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
    }
  }, [fetch]) // eslint-disable-line

  useEffect(() => {
    checkIfAllowedToComment()
  }, []) // eslint-disable-line

  return (
    <>
      {allowedToComment && (
        <IonFab vertical='bottom' horizontal='start' slot='fixed' style={{ position: 'fixed', bottom: '5px' }} edge>
          <IonFabButton onClick={(e) => setShowCommentModal(true)} title={t('label.addComment')}>
            <IonIcon icon={add} color='light' />
          </IonFabButton>
        </IonFab>
      )}
      <br />
      {data && (
        <div>
          {data.map((element: any, i: number) => (
            <MerchantProfileCustomerRate element={element} i={i} key={'parent-' + i} />
          ))}
          <IonInfiniteScroll
            threshold='100px'
            disabled={pagination.activePage - 1 >= links?.last}
            onIonInfinite={() => handleLoadMore()}>
            <IonInfiniteScrollContent loadingText={'نظرات بیشتر ...'}></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          {data.length === 0 && (
            <div className='text-center'>
              <div className='alert alert-dark'>{'هیچ نظری وجود ندارد'}</div>
              <div className='alert alert-info'>
                <Trans i18nKey='no_item_comment_hint' />
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        showModal={showCommentModal}
        toggle={setShowCommentModal}
        title={t('label.addComment')}
        onClosed={(e) => setShowCommentModal(false)}
        content={<AcceptorComment acceptor={merchant} onSuccess={onSuccessfulComment} />}
      />
    </>
  )
}

export default MerchantProfileCustomerRatings
