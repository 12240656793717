import React, { FC, useEffect, useState } from 'react'
import { IonAvatar, IonLabel, IonText } from '@ionic/react'
import { isDesktop } from '../../utility'
import { CDN_BASE_URL } from '../../config'
import { PinButton } from '../shared/map'
import './AcceptorCard.scss'
import OveralScore from '../../components/screens/merchant-profile/overalScore'
import { ApiService } from '../../ayria'

interface OwnProps {
  item: any
  savedClub?: any
  onCenterClick?: () => void
}
interface AcceptorCardProps extends OwnProps {}

const AcceptorCard: FC<AcceptorCardProps> = ({ item, savedClub, onCenterClick = () => {} }) => {
  const api = new ApiService()
  const [voucherBalance, setVoucherBalance] = useState<any>()
  const mobileFirst = () => {
    return !!item.banner ? item.banner : item.bannerDesktop
  }

  const desktopFirst = () => {
    return !!item.bannerDesktop ? item.bannerDesktop : item.banner
  }

  const fetchVoucherBalance = async () => {
    const res: any = await api.userVoucherBalance(savedClub?.code)
    if (res.ok) {
      setVoucherBalance(res.data)
    }
  }

  useEffect(() => {
    if (!!savedClub?.code) {
      fetchVoucherBalance()
    }
  }, []) // eslint-disable-line

  return (
    <div
      className='overflow-hidden d-flex flex-row align-items-center justify-content-center'
      style={{
        margin: 10,
        position: 'relative',
        height: '200px',
        borderRadius: '15px',
        backgroundColor: 'lightgray',
        backgroundSize: 'cover',
        backgroundImage: `url(${CDN_BASE_URL}/public/${isDesktop() ? desktopFirst() : mobileFirst()})`,
      }}
      id='acceptor-card-component'>
      <div
        style={{
          backgroundColor: '#f0e6e6cc',
          height: 100,
          bottom: 0,
          width: '90%',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        }}
        className={`position-absolute ${item.open ? '' : ' gray-effect'}`}>
        <div className='w-100 h-100 position-relative d-flex flex-row pt-0 pb-0'>
          <IonAvatar
            className='pointer position-absolute'
            style={{ top: '-45%', right: 10, zIndex: 2 }}
            onClick={onCenterClick}>
            <img
              data-src={!!item.logo ? `${CDN_BASE_URL}/public/${item.logo}` : ''}
              alt=''
              width='58px'
              height='58px'
              style={{
                border: `0.5px solid var(--ion-color-${item.open ? 'primary' : 'medium'})`,
              }}
              className={`lazyload ${item.open ? '' : ' gray-effect'}`}
              crossOrigin='anonymous'
            />
          </IonAvatar>

          <div
            onClick={onCenterClick}
            className='col-7 pt-4 pb-0 h-100 d-flex flex-column justify-content-center overflow-hidden'
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <h6 style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.acceptorName}</h6>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</span>
            {item.code === savedClub?.owneraCode && savedClub?.code && voucherBalance && (
              <IonLabel slot='end'>
                <IonText color='success'>{voucherBalance?.toLocaleString(navigator.language)}+</IonText> {' اسپوند '}
              </IonLabel>
            )}
          </div>

          <div
            onClick={onCenterClick}
            className='col-5 pb-0 pt-0 d-flex flex-column align-items-end justify-content-end'>
            {!!item.rateStats && item.rateStats.total > 0 && (
              <div className='d-flex flex-row justify-content-center align-items-center'>
                <span className='pl-1 pr-1' style={{ direction: 'rtl' }}>
                  {`${item.rateStats.total} نفر `}
                </span>
                <div style={{ width: 30, height: 30 }}>
                  <OveralScore statistics={item.rateStats} fontSize='50px' />
                </div>
              </div>
            )}

            <div className='d-flex flex-row  justify-content-center align-items-center'>
              {item?.tables && (
                <span className='span-click-title' title='سالن'>
                  <img src='/assets/images/tables.png' alt='' width='25' height='25' />
                </span>
              )}
              {item?.pickup && (
                <span className='span-click-title' title='حضوری'>
                  <img src='/assets/images/pickup.png' alt='' width='25' height='25' />
                </span>
              )}
              {item?.delivery && (
                <span className='span-click-title' title='پیک'>
                  <img src='/assets/images/delivery.png' alt='' width='25' height='25' />
                </span>
              )}
            </div>

            {!!item.coordinates && (
              <PinButton
                latLng={item.coordinates}
                distanceTo={item.distanceTo}
                divider=','
                title='آدرس فروشگاه'
                address={item.storeType === 'VIRTUAL' ? 'فروشگاه اینترنتی' : item.address}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcceptorCard
