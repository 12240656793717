import React, { useState, useContext, useEffect } from 'react'
import {
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonCard,
  IonIcon,
  IonButton,
} from '@ionic/react'
import PaymentService from '../../components/elements/PaymentService/payment-service'
import { connect } from '../../data/connect'
import PinModal from '../settings/PinModal'
import history from '../../utility/history'
import ReferralModal from '../home/ReferralModal'
import { IS_PAYMENT_FIRST, Modal, ApiService, ProfileContext, Banner, Acceptor, CustomModal } from '../../ayria'
import { Header } from '../../ayria'
import './PaymentServiceTab.scss'
import { isDesktop } from '../../utility'
import { add, qrCode, remove, swapHorizontal } from 'ionicons/icons'
import PayerResponseCount from '../../components/elements/PaymentService/PayerResponseCount'
import { useTranslation } from 'react-i18next'
import WalletPurchase from '../../components/elements/WalletPurchase/WalletPurchase'
import WalletPayeeRequestPersonal from '../../components/elements/WalletPayeeRequest/WalletPayeeRequestPersonal'
import IdentityVerifier from '../../components/elements/IdentityVerifier/IdentityVerifier'
import WalletPayerResponse from '../../components/elements/WalletPayerResponse/WalletPayerResponse'
import AyriaPayment from '../../components/elements/AyriaPayment/AyriaPayment'
import BankShebaTransferService from '../../components/elements/PaymentService/bank-sheba-transfer-service'
import BillPaymentGhabzino from '../../components/elements/PaymentService/bill-payment-ghabzino'
import BillPayment from '../../components/screens/building-service/BillPayment'
import MobileCharge from '../../components/elements/PaymentService/mobile-charge'
import InternetCharge from '../../components/elements/PaymentService/internet-charge'
import WalletThread from '../wallet/WalletThread'
import ScrollContent from '../shared/ScrollContent'

interface StateProps {
  pinChecked: boolean
  unpaidFactorCount?: number
  unpaidPersonCount?: number
}

interface OwnProps {
  acceptorCode?: string
  type?: string
}

interface PaymentServiceTabProps extends OwnProps, StateProps {}

const PaymentServiceTab: React.FC<PaymentServiceTabProps> = ({
  pinChecked,
  type = 'normal',
  acceptorCode,
  unpaidPersonCount,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [showPinModal, setShowPinModal] = useState(false)
  const [showPaymentSelectorModal, setShowPaymentSelectorModal] = useState(false)
  const [profileContext, setProfileContext] = useContext<any>(ProfileContext)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showWalletPurchaseModal, setShowWalletPurchaseModal] = useState(false)
  const [showRequestResponseModal, setShowRequestResponseModal] = useState(false)
  const [showWalletPayeeRequestModal, setShowWalletPayeeRequestModal] = useState(false)
  const [showIdentityVerifierModal, setShowIdentityVerifierModal] = useState(false)
  const [showWalletPayerResponseModal, setShowWalletPayerResponseModal] = useState(false)
  const [callbackFunction, setCallbackFunction] = useState('')
  const [ayriaPayment, setAyriaPayment] = useState<any>()
  const [showAyriaPaymentModal, setShowAyriaPaymentModal] = useState(false)
  const [showShebaTransferModal, setShowShebaTransferModal] = useState(false)
  const [showBillPaymentModal1, setShowBillPaymentModal1] = useState(false)
  const [showBillPaymentModal2, setShowBillPaymentModal2] = useState(false)
  const [showMobileChargeModal, setShowMobileChargeModal] = useState(false)
  const [showInternetChargeModal, setShowInternetChargeModal] = useState(false)

  const [scrollTop, setScrollTop] = useState(0)

  const funcMap = {} as any

  const getProfile = async () => {
    const res: any = await api.get('/account/profile')
    if (res.ok) {
      setProfileContext((state: any) => ({ ...state, profile: res.data }))
      if (res.data.pinOn) {
        if (pinChecked) {
          setShow(true)
        } else {
          setShowPinModal(true)
        }
      } else {
        setShow(true)
      }
    }
  }

  useIonViewWillEnter(() => {
    if (!profileContext.profile) {
      getProfile()
      if (history && history.back) {
        history.back()
      }
    } else if (profileContext.profile.pinOn) {
      if (pinChecked) {
        setShow(true)
      } else {
        setShowPinModal(true)
      }
    } else {
      setShow(true)
    }
  })

  useIonViewWillLeave(() => {
    setShow(false)
  })

  const onSuccessWalletPurchase = (acceptor: Acceptor) => {
    setShowWalletPurchaseModal(false)
    history.push(`/buy/${acceptor.referralCode}`)
  }
  const hasUnpaidWalletRequest = () => {
    return unpaidPersonCount
  }
  const doShowIdentityVerifierModal = (callbackFunction: string) => {
    setCallbackFunction(callbackFunction)
    setShowIdentityVerifierModal(true)
  }

  const hideShowIdentityVerifierModal = () => {
    setShowIdentityVerifierModal(false)
    if (callbackFunction) {
      funcMap[callbackFunction](true)
    }
  }
  funcMap.method = function (name: string, callback: () => void) {
    this[name] = callback
  }
  funcMap.method('setShowWalletPayerResponseModal', (value: boolean) => {
    setShowWalletPayerResponseModal(value)
  })

  const onScroll = (event: any) => {
    setScrollTop(Math.round(event.detail.scrollTop))
  }

  return (
    <IonPage id='paymentservice-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <ScrollContent onScroll={onScroll}>
        <div className='col-12 p-0' style={{ position: 'fixed', zIndex: 1999, backgroundColor: '#FFFFFF' }}>
          <Header showBackButton={false} showScoreBalance={IS_PAYMENT_FIRST} />
          <PaymentService ShowWalletBalance={true} ScrollTop={scrollTop} />
          <h6 className='px-2 pt-3 col-12'>مراودات قبلی</h6>
        </div>
        {show && (
          <div style={{ marginTop: 280 }}>
            {IS_PAYMENT_FIRST && (
              <IonCard onClick={() => setShowInviteModal(true)}>
                <Banner />
              </IonCard>
            )}
            {/*<div style={{minHeight:3000}} className="col-12">d</div>*/}
            <WalletThread />
          </div>
        )}
        <div
          className='col-12 d-flex flex-row align-items-center justify-content-center py-1 position-sticky '
          style={{ bottom: 0, zIndex: 2 }}>
          <button
            className='floatingButton blue ml-1 position-relative'
            onClick={() => {
              setShowPaymentSelectorModal((prevState) => !prevState)
            }}>
            <div className='position-absolute' style={{ top: -10, right: 0 }}>
              <PayerResponseCount />
            </div>
            <span className='font-weight-bold pl-1'>
              درخواست یا پرداخت
              <img width='50px' className='mr-1' src='/assets/images/apg_blue.png' alt='apg' />
            </span>
          </button>

          <a className='floatingButton' href='/tabs/qr'>
            <span className='font-weight-bold pl-1'>Scan</span>
            <IonIcon icon={qrCode} />
          </a>
        </div>

        <Modal
          showModal={showPaymentSelectorModal}
          toggle={setShowPaymentSelectorModal}
          onClosed={() => setShowPaymentSelectorModal(false)}
          title='انتخاب کنید'
          content={
            <>
              <div className={'display-inline mt-2'}>
                <div
                  className={'col-md-6 payment-service-row pointer'}
                  onClick={() => {
                    setShowWalletPurchaseModal(true)
                  }}>
                  {!showWalletPurchaseModal && <PayerResponseCount receiverType='company' marginLeft='-16px' />}{' '}
                  <img alt='' src='/assets/images/checkout.png' className='payment-image' />
                  <div className={'m-2'}>{'پرداخت صورت حساب'}</div>
                </div>

                <div
                  className={'col-md-6 payment-service-row mr-2 pointer'}
                  onClick={() => {
                    setShowRequestResponseModal(true)
                  }}>
                  {!showRequestResponseModal && <PayerResponseCount receiverType='personal' marginLeft='-10px' />}{' '}
                  <img src='/assets/images/wallet_request.png' alt='' className='payment-image' />
                  <div className={'m-2'}>{t('payments.requestAndResponse')}</div>
                </div>
                {/*  MAIN */}
                <CustomModal
                  title='انتقال وجه شبا به شبا'
                  showModal={showShebaTransferModal}
                  toggle={setShowShebaTransferModal}
                  content={
                    <BankShebaTransferService
                      onSuccess={setShowShebaTransferModal}
                      onCancel={setShowShebaTransferModal}
                    />
                  }
                />
                <CustomModal
                  title='پرداخت صورت حساب'
                  showModal={showWalletPurchaseModal}
                  toggle={setShowWalletPurchaseModal}
                  content={
                    <WalletPurchase
                      onSelect={(acceptor: Acceptor) => onSuccessWalletPurchase(acceptor)}
                      onCancel={() => setShowWalletPurchaseModal(false)}
                    />
                  }
                />
                <CustomModal
                  title='پرداخت قبض'
                  showModal={showBillPaymentModal1}
                  toggle={setShowBillPaymentModal1}
                  content={<BillPaymentGhabzino />}
                />
                <Modal
                  title='مدیریت پرداخت قبض'
                  showModal={showBillPaymentModal2}
                  toggle={setShowBillPaymentModal2}
                  onClosed={() => setShowBillPaymentModal2(false)}
                  content={<BillPayment />}
                />
                <CustomModal
                  title='شارژ شماره همراه'
                  showModal={showMobileChargeModal}
                  toggle={setShowMobileChargeModal}
                  onClosed={() => setShowMobileChargeModal(false)}
                  content={<MobileCharge />}
                />
                <CustomModal
                  title={t('payments.internetCharge')}
                  showModal={showInternetChargeModal}
                  toggle={setShowInternetChargeModal}
                  onClosed={() => setShowInternetChargeModal(false)}
                  content={<InternetCharge />}
                />

                <Modal
                  showModal={showAyriaPaymentModal}
                  toggle={setShowAyriaPaymentModal}
                  onClosed={() => {
                    setShowAyriaPaymentModal(false)
                    setAyriaPayment(null)
                  }}
                  title={`درخواست وجه ${ayriaPayment?.trackingNumber}`}
                  content={<AyriaPayment payment={ayriaPayment} onSuccess={() => {}} />}
                  actionButtons={<IonButton onClick={() => setShowAyriaPaymentModal(false)}>{'بستن'}</IonButton>}
                />
                <PinModal
                  isOpen={showPinModal}
                  onCancel={() => {
                    setShowPinModal(false)
                  }}
                  onSuccess={() => {
                    setShowPinModal(false)
                    setShow(true)
                  }}
                />
                <Modal
                  showModal={showInviteModal}
                  toggle={setShowInviteModal}
                  onClosed={() => setShowInviteModal(false)}
                  title='دعوت از دوستان'
                  content={<ReferralModal />}
                />

                <CustomModal
                  showModal={showIdentityVerifierModal}
                  toggle={setShowIdentityVerifierModal}
                  title={'تایید اطلاعات هویتی'}
                  isPlatformTheme={type === 'acceptor'}
                  content={<IdentityVerifier onIsOk={() => hideShowIdentityVerifierModal()} />}
                />

                <Modal
                  showModal={showWalletPayerResponseModal}
                  toggle={setShowWalletPayerResponseModal}
                  onClosed={() => setShowWalletPayerResponseModal(false)}
                  title='پرداخت وجه'
                  isPlatformTheme={type === 'acceptor'}
                  content={<WalletPayerResponse receiverType='personal' />}
                />
                <Modal
                  showModal={showWalletPayeeRequestModal}
                  toggle={setShowWalletPayeeRequestModal}
                  onClosed={() => setShowWalletPayeeRequestModal(false)}
                  title='درخواست وجه'
                  isPlatformTheme={type === 'acceptor'}
                  content={
                    <WalletPayeeRequestPersonal
                      acceptorCode={acceptorCode}
                      onSuccess={(ap: any, share: boolean) => {
                        setShowWalletPayeeRequestModal(false)
                        if (share) {
                          setAyriaPayment(ap)
                          setShowAyriaPaymentModal(true)
                        }
                      }}
                    />
                  }
                />
                <Modal
                  title={t('payments.requestAndResponse')}
                  showModal={showRequestResponseModal}
                  toggle={setShowRequestResponseModal}
                  onClosed={() => setShowRequestResponseModal(false)}
                  content={
                    <>
                      <div className='display-inline-100 text-center font14 mr-0 row' style={{ paddingTop: '5px' }}>
                        <p>
                          شما با گزینه <span className='text-success'>درخواست وجه</span> می‌توانید از سایرین حتی غیر از
                          اعضای باشگاه وجه درخواست نمایید.
                          {hasUnpaidWalletRequest() && (
                            <>
                              {'و از طریق '} <span className='text-danger'>پرداخت وجه</span>{' '}
                              {'، وجوه درخواست شده از شما را بپردازید.'}
                            </>
                          )}
                        </p>
                        <div
                          className={
                            'col border-color-04 border-style-1 pointer mr-1' +
                            (hasUnpaidWalletRequest() ? '' : ' col-12')
                          }
                          onClick={(e) => setShowWalletPayeeRequestModal(true)}>
                          <IonIcon icon={add} color='success' size='large'></IonIcon>
                          <br />
                          <span>{'درخواست وجه'}</span>
                        </div>
                        {hasUnpaidWalletRequest() && (
                          <div
                            className='col border-color-06 border-style-1 pointer mr-1'
                            onClick={(e) => doShowIdentityVerifierModal('setShowWalletPayerResponseModal')}>
                            <PayerResponseCount receiverType='personal' />
                            <IonIcon icon={remove} color='danger' size='large' />
                            <br />
                            <span>{'پرداخت وجه'}</span>
                          </div>
                        )}
                      </div>
                      <a
                        href='/tabs/group-payment-request'
                        className='display-inline-100 row ion-text-center mr-1 mt-5 font14 text-black'>
                        <div className='col border-color-04 border-style-1 pointer col-12'>
                          <IonIcon icon={add} color='success' size='large'></IonIcon>
                          <br />
                          <span>{'درخواست وجه گروهی'}</span>
                        </div>
                      </a>
                      <a
                        className='display-inline-100 row ion-text-center mr-1 mt-5 font14 text-black'
                        href='/tabs/group-transfer-instance/0'>
                        <div className='col border-color-05 border-style-1 pointer col-12 '>
                          <IonIcon icon={swapHorizontal} color='warning' size='large'></IonIcon>
                          <br />
                          <span>{'انتقال وجه گروهی'}</span>
                        </div>
                      </a>
                    </>
                  }
                />
                {/*  MAIN */}
              </div>
            </>
          }
        />
      </ScrollContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state: any) => ({
    pinChecked: state.user.pinChecked,
    unpaidPersonCount: state.user.unpaidPersonCount,
  }),
  component: PaymentServiceTab,
})
