import React, { useState, useEffect, useRef, lazy } from 'react'
import { IonPage, IonContent, useIonViewWillEnter, useIonViewWillLeave, IonGrid } from '@ionic/react'
import { IonRow, IonCol, IonItem, IonLabel, IonDatetime, IonButton, IonHeader } from '@ionic/react'
import { IonToolbar, IonButtons, IonImg, IonToggle } from '@ionic/react'
import { useDebounce } from 'use-debounce'
import { saveAs } from 'file-saver'
import { RouteComponentProps, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { User, Acceptor, ApiService, connect, setMenuEnabled, setShiftTimeFrom, AyriaBackButton } from '../../ayria'
import { setShiftTimeTo, CLUB_LOWER_CASE, CLUB_LOGO_WIDTH, toJalaliFromString } from '../../ayria'
import { toGregorian, Cashdesk, CashdeskSelect } from '../../ayria'
import './CashDeskDailyReport.scss'
const DatePicker = lazy(() => import('react-modern-calendar-datepicker'))

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  loggedUser: User | any
  isAuthenticated: boolean
  shiftTimeFrom: string
  shiftTimeTo: string
  hasWaiter: boolean
  hasCashdesk: boolean
  currentCashdesk: Cashdesk
}

interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled
  setShiftTimeFrom: typeof setShiftTimeFrom
  setShiftTimeTo: typeof setShiftTimeTo
}

interface CashDeskDailyReportProps extends OwnProps, StateProps, DispatchProps {}

const CashDeskDailyReport: React.FC<CashDeskDailyReportProps> = ({
  loggedUser,
  setMenuEnabled,
  isAuthenticated,
  setShiftTimeFrom,
  setShiftTimeTo,
  shiftTimeFrom,
  shiftTimeTo,
  hasWaiter,
  hasCashdesk,
  currentCashdesk,
  history,
}) => {
  const api = new ApiService()
  const { acceptorCode, selectedDay } = useParams<any>()
  const { t } = useTranslation()
  const [acceptor, setAcceptor] = useState<Acceptor>()
  const [factors, setFactors] = useState([])
  const [totalWallet, setTotalWallet] = useState(0)
  const [totalCash, setTotalCash] = useState(0)
  const [totalPos, setTotalPos] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalTip, setTotalTip] = useState('')
  const [totalRefund, setTotalRefund] = useState()
  const [totalReduction, setTotalReduction] = useState()
  const [perWaiters, setPerWaiters] = useState([])
  const [originalTitle] = useState(document.title)
  const [selectedDate, setSelectedDate] = useState(selectedDay)
  const [targetDate, setTargetDate] = useState(toJalaliFromString(selectedDay))
  const [inprogress, setInprogress] = useState(false)
  const printRef = useRef<any>()
  const [showKala, setShowKala] = useState(true)
  const [cashDesk, setCashDesk] = useState(null)
  const [queryParamChange] = useDebounce(isAuthenticated + selectedDate + shiftTimeFrom + shiftTimeTo + cashDesk, 300)

  const setTimeFrom = async (timeFrom: string) => {
    await setShiftTimeFrom(timeFrom)
  }
  const setTimeTo = async (timeTo: string) => {
    await setShiftTimeTo(timeTo)
  }
  const getAcceptor = async () => {
    const res: any = await api.get(`/acceptor-roles/current/${acceptorCode}`)
    setAcceptor(res.ok ? res.data : null)
  }
  const timeParam = () => {
    return `fromTime=${shiftTimeFrom}&toTime=${shiftTimeTo}`
  }
  const cashDeskParam = () => {
    return !!cashDesk ? `&cashdesk.equals=${cashDesk}` : ''
  }
  const getFactors = async () => {
    setInprogress(true)
    const res: any = await api.get(
      `/acceptor-roles/factors/${acceptorCode}/${selectedDate}?paid.equals=true&sort=paidDate,asc&${timeParam()}${cashDeskParam()}`
    )
    const data = res.ok ? res.data.filter((item: any) => !item.refunded) : []
    setFactors(data)
    setTotal(data.reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0))
    setTotalReduction(data.reduce((a: number, b: any) => +a + +b.reductionAmount, 0))
    setTotalPos(
      data
        .filter((item: any) => item.paymentMethod === 'POS')
        .reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0)
    )
    setTotalCash(
      data
        .filter((item: any) => item.paymentMethod === 'CASH')
        .reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0)
    )
    setTotalWallet(
      data
        .filter((item: any) => item.paymentMethod === 'WALLET')
        .reduce((a: number, b: any) => +a + +b.acceptorAmountActual, 0)
    )
    const resTip: any = await api.get(
      `/acceptor-roles/factors/${acceptorCode}/${selectedDate}/TIP?${timeParam()}${cashDeskParam()}`
    )
    setTotalTip(resTip.ok ? resTip.data : '-')
    const resRefund: any = await api.get(
      `/acceptor-roles/factors/${acceptorCode}/${selectedDate}/REFUND?${timeParam()}${cashDeskParam()}`
    )
    setTotalRefund(resRefund.ok ? resRefund.data : '-')
    const resPerWaiter: any = await api.get(
      `/acceptor-roles/factors/${acceptorCode}/${selectedDate}/per-waiter?${timeParam()}${cashDeskParam()}`
    )
    setPerWaiters(resPerWaiter.ok ? resPerWaiter.data : [])
    setInprogress(false)
  }

  const printA4 = async () => {
    setInprogress(true)
    api.setHeader('Accept', 'application/pdf')
    const queryParams = {
      'paid.equals': true,
      sort: 'paidDate,asc',
      fromTime: shiftTimeFrom,
      toTime: shiftTimeTo,
      showKala,
    }
    if (!!cashDesk) {
      queryParams['cashdesk.equals'] = cashDesk
    }
    const res: any = await api.get(`/acceptor-roles/factors/${acceptorCode}/${selectedDate}/pdf`, queryParams, {
      responseType: 'blob',
      headers: [{ Accept: 'application/pdf' }],
    })
    if (res.ok) {
      saveAs(res.data, `dailysellreport-${targetDate.year}-${targetDate.month}-${targetDate.day}.pdf`)
    }
    api.setHeader('Accept', 'application/json') // Necessary!
    setInprogress(false)
  }

  useIonViewWillEnter(() => {
    setMenuEnabled(false)
    document.title = 'سامانه هوشمند آیریا'
  })

  useIonViewWillLeave(() => {
    setMenuEnabled(true)
    document.title = originalTitle
  })

  useEffect(() => {
    setSelectedDate(toGregorian(targetDate))
  }, [targetDate]) // eslint-disable-line

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
    getFactors()
  }, [queryParamChange]) // eslint-disable-line

  useEffect(() => {
    getAcceptor()
  }, []) // eslint-disable-line

  return (
    <IonPage id='cashDeskDailyReportPage'>
      <IonContent fullscreen>
        <IonHeader className='ion-no-border non-printable'>
          <IonToolbar>
            <IonButtons slot='start'>
              <AyriaBackButton defaultHref='/tabs/home' text='برگشت' />
              {/*<IonButton color='primary' onClick={() => window.print()} disabled={inprogress}>
                {'چاپ گزارش'}
              </IonButton>*/}
              <IonButton color='primary' onClick={printA4} disabled={inprogress}>
                {t('Print A4')}
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonImg
                src={`/assets/${CLUB_LOWER_CASE}/logo.png`}
                style={{ width: CLUB_LOGO_WIDTH }}
                onClick={() => history.push('/tabs')}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonGrid className='printable' ref={printRef}>
          <IonRow>
            {loggedUser && !loggedUser.authorities?.includes('ROLE_ACCEPTOR') && (
              <IonCol>
                <span className='ion-padding-start'>
                  {'تاریخ: '} {targetDate.year}/{targetDate.month}/{targetDate.day}{' '}
                </span>
              </IonCol>
            )}
            {loggedUser?.authorities?.includes('ROLE_ACCEPTOR') && (
              <IonCol>
                <IonLabel className='ion-padding-start'>
                  {'تاریخ'}{' '}
                  <span className='print-only float-left'>
                    {targetDate.year}/{targetDate.month}/{targetDate.day}
                  </span>
                </IonLabel>
                <DatePicker
                  value={targetDate}
                  onChange={setTargetDate}
                  inputPlaceholder='تاریخ'
                  shouldHighlightWeekends
                  locale='fa'
                  inputClassName='non-printable'
                />
              </IonCol>
            )}
            <IonCol className='text-center'>
              <span className='h3'>{'گزارش فروش'}</span>
            </IonCol>
            <IonCol className='text-left'>
              {'نام کاربر: '} {loggedUser?.firstName} {loggedUser?.lastName}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className='flex-container' style={{ justifyContent: 'normal' }}>
                <IonItem>
                  <IonLabel>
                    {'از ساعت'} <span className='print-only float-left printable'>{shiftTimeFrom}</span>
                  </IonLabel>
                  <IonDatetime
                    presentation='time'
                    value={shiftTimeFrom}
                    onIonChange={(e) => setTimeFrom(e.detail.value!)}
                    disabled={inprogress}
                    className='non-printable'
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>
                    {'تا ساعت'} <span className='print-only float-left printable'>{shiftTimeTo}</span>
                  </IonLabel>
                  <IonDatetime
                    presentation='time'
                    value={shiftTimeTo}
                    onIonChange={(e) => setTimeTo(e.detail.value!)}
                    disabled={inprogress}
                    className='non-printable'
                  />
                </IonItem>
              </div>
            </IonCol>
            <IonCol className='text-center'>
              <span className='h4'>{acceptor?.acceptorName}</span>
            </IonCol>
            <IonCol>
              {hasCashdesk && <CashdeskSelect acceptorCode={acceptorCode} onChange={(value) => setCashDesk(value)} />}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <strong className='ion-padding-start'>{'جمع فروش نقد'}</strong>
            </IonCol>
            <IonCol className='text-start'>
              <strong className='ion-padding-end'>{(totalCash || 0).toLocaleString(navigator.language)}</strong>{' '}
              {'ریال'}
            </IonCol>
            <IonCol className='text-left'>
              <strong className='ion-padding-start'>{'جمع عودت'}</strong>
            </IonCol>
            <IonCol className='text-center'>
              <strong className='ion-padding-end'>{(totalRefund || 0).toLocaleString(navigator.language)}</strong>{' '}
              {'ریال'}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <strong className='ion-padding-start'>{'جمع فروش کارتخوان'}</strong>
            </IonCol>
            <IonCol className='text-start'>
              <strong className='ion-padding-end'>{(totalPos || 0).toLocaleString(navigator.language)}</strong> {'ریال'}
            </IonCol>
            <IonCol className='text-left'>
              {hasWaiter && <strong className='ion-padding-start'>{'جمع انعام'}</strong>}
            </IonCol>
            <IonCol className='text-center'>
              {hasWaiter && (
                <>
                  <strong className='ion-padding-end'>{(totalTip || 0).toLocaleString(navigator.language)}</strong>
                  {' ریال '}
                </>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <strong className='ion-padding-start'>{'جمع فروش کیف پول'}</strong>
            </IonCol>
            <IonCol className='text-start'>
              <strong className='ion-padding-end'>{(totalWallet || 0).toLocaleString(navigator.language)}</strong>
              {' ریال '}
            </IonCol>
            <IonCol className='text-left'>
              <strong>{'جمع تخفیف'}</strong>
            </IonCol>
            <IonCol className='text-center'>
              <strong className='ion-padding-end'>{(totalReduction || 0).toLocaleString(navigator.language)}</strong>{' '}
              {' ریال '}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <strong className='ion-padding-start'>{'جمع فروش کل'}</strong>
            </IonCol>
            <IonCol className='text-start'>
              <strong className='ion-padding-end'>{(total || 0).toLocaleString(navigator.language)}</strong> {'ریال'}
            </IonCol>
            <IonCol></IonCol>
            <IonCol>
              <IonItem className='non-printable'>
                <IonLabel>نمایش کالا و خدمات</IonLabel>
                <IonToggle checked={showKala} onIonChange={(e) => setShowKala(e.detail.checked)}></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          {hasWaiter && (
            <IonRow className='ion-padding-top'>
              <IonCol className='text-center container'>
                <span className='h6'>{'سر جمع فروش به تفکیک پیشخدمت‌ها'}</span>
                <table className='table table-borderless'>
                  <thead>
                    <tr>
                      <th>{'نام پیشخدمت'}</th>
                      <th>{'مبلغ فروش (ریال)'}</th>
                      <th>{'مبلغ انعام دریافتی (ریال)'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {perWaiters.map((row: any, index: number) => {
                      return (
                        <tr key={row[0] + index}>
                          <td>
                            {row[0]} {row[1]}
                          </td>
                          <td>{(row[3] || 0).toLocaleString(navigator.language)}</td>
                          <td>{(row[2] || 0).toLocaleString(navigator.language)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </IonCol>
            </IonRow>
          )}
          <IonRow className='ion-padding-top'>
            <IonCol className='text-center container'>
              <span className='printable'>{'جزئیات صورتحساب‌ها'}</span>
              <table className='table table-borderless'>
                <thead>
                  <tr>
                    <th>{'ردیف'}</th>
                    <th>{'شماره'}</th>
                    <th>{'نام مشتری'}</th>
                    {showKala && <th>{'کالا و خدمات'}</th>}
                    <th>{'مبلغ(ریال)'}</th>
                    <th>{'تخفیف(ریال)'}</th>
                    <th>{'دریافتی(ریال)'}</th>
                    <th>{'نوع پرداخت'}</th>
                  </tr>
                </thead>
                <tbody>
                  {factors.map((factor: any, index: number) => {
                    return (
                      <tr key={factor.number} className={index === 10 ? 'pageBreak' : ''}>
                        <td>{index + 1}</td>
                        <td>{factor.number}</td>
                        <td dir='ltr'>{factor.payerName}</td>
                        {showKala && (
                          <td>
                            {factor.kalas?.map(
                              (k: any, i: number) => k.name + (i !== factor.kalas?.length - 1 ? '، ' : '')
                            )}
                          </td>
                        )}
                        <td>{factor.unitedAmount.toLocaleString(navigator.language)}</td>
                        <td>{factor.reductionAmount.toLocaleString(navigator.language)}</td>
                        <td>{factor.acceptorAmountActual.toLocaleString(navigator.language)}</td>
                        <td>
                          {factor.paymentMethod === 'WALLET' && <span>{'کیف پول'} </span>}
                          {factor.paymentMethod === 'CASH' && <span>{'نقد'} </span>}
                          {factor.paymentMethod === 'POS' && <span>{'کارتخوان'} </span>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setMenuEnabled,
    setShiftTimeFrom,
    setShiftTimeTo,
  },
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
    isAuthenticated: state.user.isLoggedIn,
    shiftTimeFrom: state.shop.shiftTimeFrom,
    shiftTimeTo: state.shop.shiftTimeTo,
    hasWaiter: state.shop.hasWaiter,
    hasCashdesk: state.shop.hasCashdesk,
    currentCashdesk: state.shop.currentCashdesk,
  }),
  component: CashDeskDailyReport,
})
