import {createBrowserHistory} from 'history';
import {createMemoryHistory} from 'history';
import withQuery from 'history-query-enhancer';
import qs from 'qs';

const selectHistoryType = () => {
  if (typeof window === 'undefined') {
    return createMemoryHistory();
  }
  return createBrowserHistory();
};

qs.parseOld = qs.parse;
qs.parse = str => qs.parseOld(str, { ignoreQueryPrefix: true });
const history = withQuery(qs)(selectHistoryType());

export default history;
