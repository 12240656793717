import React, { Suspense, useState, useEffect, useRef, useContext } from 'react'
import { URLSearchParams } from 'whatwg-url'
import { useHistory } from 'react-router-dom'
import { parseHeaderForLinks } from '../../ayria'
import { loadMoreDataWhenScrolled } from '../../utility/pagination'
import { IonSearchbar } from '@ionic/react'
import { IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner } from '@ionic/react'
import ApiService from '../../api/ApiService'
import useDebounce from '../../utility/use-debounce'
import { Club } from '../../models'
import { useToast } from '../../utility/toaster/useToast'
import { connect } from '../../data'
import { LayerContext } from '../shared/map'
import './acceptorList.scss'
import AcceptorCard from './AcceptorCard'

interface OwnProps {
  savedClub?: Club
  rowClicked?: Function
  hideDepartments?: boolean
  scrollTop: number
  homeMode?: boolean
}
interface StateProps {
  shippingAddress: any
}
interface AcceptorListProps extends OwnProps, StateProps {}

const AcceptorList: React.FC<AcceptorListProps> = ({
  savedClub,
  rowClicked,
  hideDepartments = false,
  shippingAddress,
  scrollTop,
  homeMode = false,
}) => {
  const api = new ApiService()
  const history = useHistory()
  const toast = useToast()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const [acceptors, setAcceptors] = useState([])
  const [links, setLinks] = useState<any>({})
  const [searchText, setSearchText] = useState(urlSearchParams.get('name') ? urlSearchParams.get('name') : undefined)
  const debouncedSearchText = useDebounce(searchText, 1000)
  const debouncedSlideIndex = useDebounce(1, 500)
  const [pagination, setPagination] = useState({ itemsPerPage: 10, sort: 'soldSum', order: 'desc', activePage: 1 })
  const [reset, setReset] = useState(false)
  const [fetch, setFetch] = useState(false)
  const [inprogress, setInprogress] = useState(false)
  const [voucherBalance, setVoucherBalance] = useState<any>(null)
  const [firstTime, setFirstTime] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const searchbar = useRef<any>()
  const { currentLocation } = useContext(LayerContext)
  const [oldScrollTop, setOldScrollTop] = useState(0)
  const [isScrollToTop, setIsScrollToTop] = useState(true)

  const getAcceptors = async (page: number, size: number) => {
    setInprogress(true)
    setFetch(false)
    let dataSearch = ''
    if (searchText) {
      dataSearch +=
        (/^[A-Za-z]*$/.test(searchText) ? '&acceptorNameEn.contains=' : '&acceptorName.contains=') + searchText
    }
    if (urlSearchParams.get('sort')) {
      if (urlSearchParams.get('sort') === 'new') {
        dataSearch += '&createdDate.lessThan=2060-06-06T00:00:00Z'
      } else if (urlSearchParams.get('sort') === 'rank') {
        dataSearch += '&rank.greaterThan=0'
      } else if (urlSearchParams.get('sort') === 'rate') {
        dataSearch += '&rate.greaterThan=0'
      }
    }
    api
      .get(
        `/acceptors/active?page=${reset ? 0 : page}&size=${size}${
          savedClub?.code ? `&clubCode.equals=${savedClub?.code}` : ''
        }${
          homeMode
            ? shippingAddress?.id
              ? `&shippingAddressLatLng=${shippingAddress?.latitude} ${shippingAddress?.longitude}`
              : `${currentLocation?.length ? `&shippingAddressLatLng=${currentLocation[0]} ${currentLocation[1]}` : ''}`
            : ''
        }` + dataSearch
      )
      .then((res: any) => {
        if (res.ok) {
          if (firstTime) {
            setShowSearch(res.data.length >= 10 ? true : false)
          }
          setFirstTime(false)
          setLinks(parseHeaderForLinks(res.headers.link))
          if (reset) {
            setAcceptors(res.data)
          } else {
            setAcceptors(loadMoreDataWhenScrolled(acceptors, res.data))
          }
        } else {
          toast.error(res)
        }
        setReset(false)
        setInprogress(false)
      })
  }
  // eslint-disable-next-line

  const fetchVoucherBalance = async () => {
    const res: any = await api.userVoucherBalance(savedClub?.code)
    if (res.ok) {
      setVoucherBalance(res.data)
    }
  }
  const handleLoadMore = () => {
    if (!fetch) {
      if (reset) {
        setPagination({ ...pagination, activePage: 1 })
      } else {
        setPagination({ ...pagination, activePage: pagination.activePage + 1 })
      }
    }
  }

  const handleRowClick = (center: any) => {
    history.push(`/tabs/center/${center?.referralCode}`)
    if (rowClicked) {
      rowClicked()
    }
  }

  useEffect(() => {
    searchbar?.current?.getInputElement().then((searchinput: any) => {
      if (!!searchinput && searchinput.setAttribute) {
        searchinput.setAttribute('name', 'search')
        searchinput.setAttribute('id', 'search')
      }
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (savedClub?.code) {
      fetchVoucherBalance()
    }
  }, [savedClub?.code]) // eslint-disable-line

  useEffect(() => {
    if (reset) {
      setPagination({ ...pagination, activePage: 1 })
    }
  }, [reset]) //eslint-disable-line
  useEffect(() => {
    setReset(true)
    setFetch(true)
  }, [debouncedSearchText, debouncedSlideIndex, shippingAddress?.id, currentLocation, hideDepartments]) // eslint-disable-line
  useEffect(() => {
    setFetch(true)
  }, [pagination.activePage]) // eslint-disable-line
  useEffect(() => {
    if (fetch) {
      getAcceptors(pagination.activePage - 1, pagination.itemsPerPage)
    }
  }, [fetch]) // eslint-disable-line

  useEffect(() => {
    setOldScrollTop(scrollTop)
    setIsScrollToTop(oldScrollTop > scrollTop)
  }, [scrollTop]) // eslint-disable-line

  return (
    <Suspense fallback={<IonSpinner color='primary' />}>
      {showSearch && (
        <div
          className='row'
          style={{ position: 'sticky', top: isScrollToTop ? '0' : '-100px', zIndex: 999, backgroundColor: 'white' }}>
          <h6 className='font-weight-bold col-12 text-center'>{urlSearchParams?.get('title')}</h6>
          <div className={'col'}>
            <IonSearchbar
              value={!homeMode ? searchText : undefined}
              placeholder={'جستجو'}
              onIonChange={(e) => {
                if (homeMode && !!e.detail.value) {
                  history.push('/search')
                } else {
                  setSearchText(e.detail.value)
                }
              }}
              autocomplete='off'
              onClick={() => {
                if (homeMode) {
                  history.push('/search')
                }
              }}
              ref={searchbar}
            />
          </div>
        </div>
      )}

      <div className='clear-both'>
        <div id='acceptor-list-item'>
          {!reset &&
            acceptors.length > 0 &&
            acceptors.map((item: any, index: number) => {
              return (
                <div key={item.code + ' ' + index}>
                  <AcceptorCard
                    item={item}
                    voucherBalance={voucherBalance}
                    savedClub={savedClub}
                    onCenterClick={() => handleRowClick(item)}
                  />
                </div>
              )
            })}
        </div>
        <div className='ion-text-center'>{inprogress && <IonSpinner />}</div>
        <IonInfiniteScroll
          threshold='100px'
          disabled={pagination.activePage - 1 >= links?.last || reset || fetch}
          onIonInfinite={() => handleLoadMore()}>
          <IonInfiniteScrollContent loadingText={'فروشگاه بیشتر'}></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>
    </Suspense>
  )
}
export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    shippingAddress: state.user.shippingAddress,
  }),
  component: AcceptorList,
})
