import React, { useState, useEffect } from 'react'
import { IonInput } from '@ionic/react'
import { conformToMask } from 'react-text-mask'
import createNumberMask from './createNumberMask'
import { convertToEnglish } from '../../../utility/number'
import './CurrencyIonInput.scss'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  // integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

interface CurrencyInputProps {
  maskOptions?: any
  onValueChange: React.Dispatch<React.SetStateAction<string>>
  defaultValue?: string
  componentDidMount?: Function
  placeholder?: string
  debounce?: number
}

const CurrencyIonInput: React.FC<CurrencyInputProps> = ({
  maskOptions,
  onValueChange,
  defaultValue,
  componentDidMount = () => {},
  placeholder,
  debounce,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })
  const [amount, setAmount] = useState(defaultValue || undefined)
  const maskAmount = (rawAmount = '') => {
    rawAmount = convertToEnglish(rawAmount)
    rawAmount = rawAmount.replace(/\D+/g, '')
    const res = conformToMask(rawAmount, currencyMask(rawAmount), {})
    let value = res.conformedValue.replace('_', '')
    value = convertToEnglish(value) // this is necessary for some Android phone e.g. Samsung G5
    setAmount(value)
    let thousandsSeparator = (1000).toLocaleString(navigator.language).substring(1, 2)
    onValueChange(value.split(defaultMaskOptions.thousandsSeparatorSymbol).join('').split(thousandsSeparator).join(''))
  }

  useEffect(() => {
    componentDidMount()
  }, []) // eslint-disable-line

  return (
    <IonInput
      value={amount || ''}
      onIonChange={(e: any) => maskAmount(e.detail.value)}
      className='dir-ltr ion-text-start font-roboto'
      placeholder={!!placeholder ? placeholder : 'مبلغ مورد نظر - ریال'}
      inputMode='numeric'
      pattern='[0-9]*'
      {...(!!debounce && { debounce: debounce })}
      {...inputProps}
    />
  )
}

export default CurrencyIonInput
