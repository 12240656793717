import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useToast } from '../../../utility'
import { ProfileContext } from '../../structures/contexts/ProfileContext'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import CurrencyInput from '../../units/CurrencyInput/CurrencyInput'
import WalletBalance from '../WalletBalance/WalletBalance'
import { ApiService, CASHOUT_OPERATION_COST_MIN_VALUE } from '../../../ayria'
import { CASHOUT_OPERATION_COST_MAX_VALUE } from '../../../ayria'

const WalletCashout = ({ amount, acceptorCode, onSuccess, theme }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const btnClassName = theme === 'acceptor' ? 'btn btn-theme-platform text-white btn-sm' : 'btn btn-theme btn-sm'
  const [inprogress, setInprogress] = useState(false)
  const [profileContext, setProfileContext] = useContext(ProfileContext)
  const [dynamicAmount, setDynamicAmount] = useState(amount || null)
  const [acceptorContext] = useContext(AcceptorContext)

  const handleCashout = async () => {
    setInprogress(true)
    const res = await api.post(`/wallet/cashout`, { amount: dynamicAmount, senderAcceptorCode: acceptorCode })
    if (res.ok) {
      toast.success(t('label.successfulOperation'))
      onSuccess()
    } else {
      toast.error(res, 10000)
    }
    setInprogress(false)
  }
  const actualWithdraw = () => {
    if (isNotAcceptor() || +dynamicAmount <= 10000000) {
      return +(+dynamicAmount + CASHOUT_OPERATION_COST_MIN_VALUE).toFixed(0)
    }
    const operationCost = +dynamicAmount * (4 / 10000)
    return +(
      +dynamicAmount +
      (operationCost > CASHOUT_OPERATION_COST_MAX_VALUE
        ? CASHOUT_OPERATION_COST_MAX_VALUE
        : operationCost < CASHOUT_OPERATION_COST_MIN_VALUE
        ? CASHOUT_OPERATION_COST_MIN_VALUE
        : operationCost)
    ).toFixed(0)
  }
  const isNotAcceptor = () => {
    return !acceptorCode
  }
  const isAcceptor = () => {
    return !isNotAcceptor()
  }
  const getProfile = async () => {
    const res = await api.get('/account/profile')
    setProfileContext((state) => ({ ...state, profile: res.data }))
  }
  const setDynamicAmountLimit = (limit) => {
    if (isNotAcceptor() && limit > 100000000) {
      setDynamicAmount(100000000)
    } else if (limit > 500000000) {
      setDynamicAmount(500000000)
    } else {
      setDynamicAmount(limit)
    }
  }

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div>
        <WalletBalance acceptorCode={acceptorCode} showScore={false} />
        <div className='row'>
          <span className='col-8'>مبلغ مورد نظر شمابرای انتقال به حساب بانکی : </span>
          <span className='col-4'>
            {(+dynamicAmount).toLocaleString(navigator.language)} {t('label.riyal')}
          </span>
        </div>
        <div className='row'>
          <span className='col-8'>{'با احتساب کارمزد :'}</span>
          <span className='col-4'>
            {actualWithdraw().toLocaleString(navigator.language)} {t('label.riyal')}
          </span>
        </div>
        <br />
        <br />
        <div className='form-group'>
          <CurrencyInput onValueChange={setDynamicAmountLimit} defaultValue={dynamicAmount} />
        </div>
        <div className='form-group'>
          <label htmlFor='sheba'>{'شبای مقصد'}</label>
          <br />
          {isNotAcceptor() && (
            <>
              {!profileContext.profile.nationalCodeVerified && (
                <div>
                  <span className='text-danger'>
                    {'لطفا کد ملی و شماره شبا را در بخش پروفایل با مشخصات خود مطابقت دهید'}
                  </span>
                  <br />
                  <div className='text-center'>
                    <Link to={'/profile'}>
                      <button className='btn btn-theme'>{'ورود به پروفایل'}</button>
                    </Link>
                  </div>
                </div>
              )}
              {profileContext.profile.nationalCodeVerified && !profileContext.profile.shebaNumberVerified && (
                <div>
                  <span className='text-danger'>{'لطفا شماره شبا را در بخش پروفایل با مشخصات خود مطابقت دهید'}</span>
                  <div className='text-center'>
                    <Link to={'/profile?tab=bank'}>
                      <button className='btn btn-theme'>{'ورود به پروفایل'}</button>
                    </Link>
                  </div>
                </div>
              )}
              {profileContext.profile.shebaNumberVerified && (
                <span className='text-bold'>{profileContext.profile.bankShebaNumber}</span>
              )}
            </>
          )}
          {isAcceptor() && (
            <>
              {!acceptorContext.current.shebaCode && (
                <span className='danger'>{'لطفا برای تعیین شماره شبا فروشگاه با پشتیبانی آیریا تماس بگیرید.'}</span>
              )}
              {acceptorContext.current.shebaCode && (
                <span className='text-bold'>{acceptorContext.current.shebaCode}</span>
              )}
            </>
          )}
        </div>
        <div className='form-group'>
          {isNotAcceptor() && (
            <>
              <label>نام و نام خانوادگی</label>
              <div className='text-bold'>
                {profileContext.profile.firstName} {profileContext.profile.lastName}
              </div>
            </>
          )}
          {isAcceptor() && (
            <>
              <label>نام فروشگاه</label>
              <div className='text-bold'>{acceptorContext.current.acceptorName}</div>
            </>
          )}
        </div>
        <br />
        {isNotAcceptor() && (
          <>
            {profileContext.profile.shebaNumberVerified && (
              <button className={btnClassName} onClick={handleCashout} disabled={inprogress}>
                انجام
              </button>
            )}
          </>
        )}
        {isAcceptor() && (
          <>
            {acceptorContext.current.shebaCode && (
              <button className={btnClassName} onClick={handleCashout} disabled={inprogress}>
                انجام
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default WalletCashout
