import React, { useState, useEffect, useContext, lazy } from 'react'
import { IonToggle, IonRow, IonCol, IonItem, IonLabel, IonButton, IonIcon } from '@ionic/react'
import { document } from 'ionicons/icons'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { utils } from 'react-modern-calendar-datepicker'
import Ayriachart from '../../units/Ayriachart'
import { ApiService, groupByIndex, connect, AcceptorContext } from '../../../ayria'
import { toGregorian, toJalaliStringFromGregorianString } from '../../../ayria'
const DatePicker = lazy(() => import('react-modern-calendar-datepicker'))

interface StateProps {
  hasWaiter: boolean
  hasKala: boolean
  hasCashdesk: boolean
}

interface SellChartDailyProps extends StateProps {}

const SellChartDaily: React.FC<SellChartDailyProps> = ({ hasWaiter, hasKala, hasCashdesk }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [data, setData] = useState([])
  const [waiterData, setWaiterData] = useState<any>()
  const [waiterToggle, setWaiterToggle] = useState(false)
  const [kalaData, setKalaData] = useState<any>()
  const [exportInprogress, setExportInprogress] = useState(false)
  const [kalaToggle, setKalaToggle] = useState(false)
  const [cashdeskData, setCashdeskData] = useState<any>()
  const [cashdeskToggle, setCashdeskToggle] = useState(false)
  const [paymentMethodData, setPaymentMethodData] = useState<any>()
  const [paymentMethodToggle, setPaymentMethodToggle] = useState(false)
  const [fromDate, setFromDate] = useState({
    year: utils('fa').getToday().year,
    month: utils('fa').getToday().month,
    day: 1,
  })
  const [toDate, setToDate] = useState({
    year: utils('fa').getToday().year,
    month: utils('fa').getToday().month,
    day: 'x',
  })
  const [options, setOptions] = useState<any>({
    yAxis: {
      title: { text: 'فروش به ریال' },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const self: any = this
        return `<span>${self.series.name} <br /><br /> <strong>${self.y.toLocaleString(
          navigator.language
        )}</strong> <br /><br />${self.key}</span>`
      },
    },
    legend: { enabled: true },
  })
  const fromString = () => {
    return `${fromDate.year}-${fromDate.month}-${fromDate.day}`
  }
  const toString = () => {
    return `${toDate.year}-${toDate.month}-${toDate.day}`
  }

  const fetchChartData = async () => {
    const res: any = await api.get(
      `/acceptor/purchases/${acceptorContext.current.code}/daily?${fromDate ? `&fromDate=${fromString()}` : ''}${
        toDate ? `&toDate=${toString()}` : ''
      }`
    )
    if (res.ok) {
      const to = res.data.to.split('-')
      if (toDate.day !== parseInt(to[2])) {
        setToDate({ year: parseInt(to[0]), month: parseInt(to[1]), day: parseInt(to[2]) })
      }
      const array = res.data.labels.map((label: string, index: number) => {
        return { name: label, y: res.data.data[index] }
      })
      setData(array)
    }
  }

  const getWaiterData = async () => {
    const res: any = await api.get(
      `/acceptor-roles/factors/${acceptorContext.current.code}/${fromString()}/${toString()}/per-waiter`
    )
    const uniques = [...new Set<string>(res.data.map((item: any) => item[3]))]
    const groups: any = groupByIndex(res.data, 3)
    const array = uniques.map((waiter: any) => {
      const iarray = groups[waiter].map((iary: any) => {
        return { name: toJalaliStringFromGregorianString(iary[0]).substr(2), y: iary[1] }
      })
      return { name: waiter, data: iarray }
    })
    setWaiterData(array)
  }

  const getKalaData = async () => {
    const res: any = await api.get(
      `/acceptor-roles/factors/${acceptorContext.current.code}/${fromString()}/${toString()}/per-kala`
    )
    const uniques = [...new Set<string>(res.data.map((item: any) => item[1]))]
    const groups: any = groupByIndex(res.data, 1)
    const array = uniques.map((kala: any) => {
      const iarray = groups[kala].map((iary: any) => {
        return { name: toJalaliStringFromGregorianString(iary[0]).substr(2), y: iary[2] }
      })
      return { name: kala, data: iarray }
    })
    setKalaData(array)
  }
  const getPaymentMethodData = async () => {
    const res: any = await api.get(
      `/acceptor-roles/factors/${acceptorContext.current.code}/${fromString()}/${toString()}/per-payment-method`
    )
    const uniques = [...new Set<string>(res.data.map((item: any) => item[1]))]
    const groups: any = groupByIndex(res.data, 1)
    const array = uniques.map((pm: any) => {
      const iarray = groups[pm].map((iary: any) => {
        return { name: toJalaliStringFromGregorianString(iary[0]).substr(2), y: iary[2] }
      })
      return { name: t(`purchasePayType.${pm}`), data: iarray }
    })
    setPaymentMethodData(array)
  }
  const getCashdeskData = async () => {
    const res: any = await api.get(
      `/acceptor-roles/factors/${acceptorContext.current.code}/${fromString()}/${toString()}/per-cashdesk`
    )
    const uniques = [...new Set<string>(res.data.map((item: any) => item[1]))]
    const groups: any = groupByIndex(res.data, 1)
    const array = uniques.map((cd: any) => {
      const iarray = groups[cd].map((iary: any) => {
        return { name: toJalaliStringFromGregorianString(iary[0]).substr(2), y: iary[2] }
      })
      return { name: !!cd ? cd : 'بدون صندوق', data: iarray }
    })
    setCashdeskData(array)
  }
  const downloadXls = async () => {
    setExportInprogress(true)
    api.setHeader('Accept', 'application/xls')
    const res: any = await api.get(
      `/acceptor-roles/factors/${acceptorContext.current.code}/report/xls?paidDate.greaterThanOrEqual=${toGregorian(
        fromDate
      )}T00:00:00Z&paidDateTo.lessThanOrEqual=${toGregorian(toDate)}T23:59:59Z`,
      {},
      { responseType: 'blob', headers: [{ Accept: 'application/xls' }] }
    )
    if (res.ok) {
      saveAs(res.data, `sell-report-${fromString()}-${toString()}.xls`)
    }
    api.setHeader('Accept', 'application/json')
    setExportInprogress(false)
  }

  useEffect(() => {
    if (paymentMethodToggle) {
      getPaymentMethodData()
    } else {
      setPaymentMethodData([])
    }
  }, [paymentMethodToggle]) // eslint-disable-line

  useEffect(() => {
    if (waiterToggle) {
      getWaiterData()
    } else {
      setWaiterData([])
    }
  }, [waiterToggle]) // eslint-disable-line

  useEffect(() => {
    if (kalaToggle) {
      getKalaData()
    } else {
      setKalaData([])
    }
  }, [kalaToggle]) // eslint-disable-line

  useEffect(() => {
    if (cashdeskToggle) {
      getCashdeskData()
    } else {
      setCashdeskData([])
    }
  }, [cashdeskToggle]) // eslint-disable-line

  useEffect(() => {
    const series = [{ type: 'line', data: data, name: 'فروش کل' }]
    if (waiterToggle) {
      // eslint-disable-next-line
      waiterData.map((row: any) => {
        series.push({ type: 'line', name: row.name, data: row.data })
      })
    }
    if (kalaToggle) {
      // eslint-disable-next-line
      kalaData.map((row: any) => {
        series.push({ type: 'line', name: row.name, data: row.data })
      })
    }
    if (paymentMethodData) {
      // eslint-disable-next-line
      paymentMethodData.map((row: any) => {
        series.push({ type: 'line', name: row.name, data: row.data })
      })
    }
    if (cashdeskData) {
      // eslint-disable-next-line
      cashdeskData.map((row: any) => {
        series.push({ type: 'line', name: row.name, data: row.data })
      })
    }
    setOptions({ ...options, series })
  }, [data, waiterData, kalaData, paymentMethodData, cashdeskData]) // eslint-disable-line

  useEffect(() => {
    if (fromDate || toDate) {
      fetchChartData()
    }
  }, [fromDate, toDate]) // eslint-disable-line

  return (
    <>
      <IonRow className='p-2'>
        <IonCol>
          <DatePicker
            value={fromDate}
            onChange={setFromDate}
            inputPlaceholder='از تاریخ'
            shouldHighlightWeekends
            locale='fa'
          />
        </IonCol>
        <IonCol>
          <DatePicker
            value={toDate}
            onChange={setToDate}
            inputPlaceholder='تا تاریخ'
            shouldHighlightWeekends
            locale='fa'
          />
        </IonCol>
        {hasKala && (
          <IonCol>
            <IonItem>
              <IonLabel>{'به تفکیک کالا و خدمات'}</IonLabel>
              <IonToggle onIonChange={(e) => setKalaToggle(e.detail.checked)} />
            </IonItem>
          </IonCol>
        )}
        <IonCol>
          <IonItem>
            <IonLabel>{'به تفکیک نوع پرداخت'}</IonLabel>
            <IonToggle onIonChange={(e) => setPaymentMethodToggle(e.detail.checked)} />
          </IonItem>
        </IonCol>
        {hasCashdesk && (
          <IonCol>
            <IonItem>
              <IonLabel>{'به تفکیک صندوق'}</IonLabel>
              <IonToggle onIonChange={(e) => setCashdeskToggle(e.detail.checked)} />
            </IonItem>
          </IonCol>
        )}
        {hasWaiter && (
          <IonCol>
            <IonItem>
              <IonLabel>{'به تفکیک پیشخدمت'}</IonLabel>
              <IonToggle onIonChange={(e) => setWaiterToggle(e.detail.checked)} />
            </IonItem>
          </IonCol>
        )}
        <IonCol>
          <IonButton color='success' fill='clear' onClick={downloadXls} disabled={exportInprogress}>
            <IonIcon icon={document} slot='start' />
            {'دانلود XLS'}
          </IonButton>
        </IonCol>
      </IonRow>
      <Ayriachart options={options} />
    </>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasWaiter: state.shop.hasWaiter,
    hasKala: state.shop.hasKala,
    hasCashdesk: state.shop.hasCashdesk,
  }),
  component: SellChartDaily,
})
