import React, { useState, useEffect } from 'react'
import { IonRow, IonCol, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { IonSelect, IonSelectOption, IonButton, IonCheckbox } from '@ionic/react'
import { add, trash } from 'ionicons/icons'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ApiService, useToast } from '../../../ayria'

interface OwnProps {
  acceptorCode: string
}
interface DeliveryHoursEditProps extends OwnProps {}

const DeliveryHoursEdit: React.FC<DeliveryHoursEditProps> = ({ acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const { t } = useTranslation()
  const [everyDay, setEveryDay] = useState(true)
  const [from, setFrom] = useState('00:00')
  const [to, setTo] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [deleteInprogress, setDeleteInprogress] = useState(false)
  const [items, setItems] = useState([])
  const [saturday, setSaturday] = useState(true)
  const [sunday, setSunday] = useState(true)
  const [monday, setMonday] = useState(true)
  const [tuesday, setTuesday] = useState(true)
  const [wednesday, setWednesday] = useState(true)
  const [thursday, setThursday] = useState(true)
  const [friday, setFriday] = useState(true)

  const getItems = async () => {
    setInprogress(true)
    const res: any = await api.get(`/acceptor/delivery-hours/${acceptorCode}`)
    if (res.ok) {
      setItems(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const addTimeFrame = async () => {
    setInprogress(true)
    const cmd = everyDay ? [{ acceptorCode, from, to, dayOfWeek: 'ANYDAY' }] : daysCommand()
    const res: any = await api.post(`/acceptor/delivery-hours/${acceptorCode}/bulk`, cmd)
    if (res.ok) {
      await getItems()
      setTo(null)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const deleteTimeframe = async (deliveryHourId: string) => {
    setDeleteInprogress(true)
    const res: any = await api.delete(`/acceptor/delivery-hours/${acceptorCode}/${deliveryHourId}`)
    if (res.ok) {
      await getItems()
      toast.success('بازه زمانی با موفقیت حذف شد')
    } else {
      toast.error(res)
    }
    setDeleteInprogress(false)
  }

  const daysCommand = () => {
    const days = []
    if (saturday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'SATURDAY' })
    }
    if (sunday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'SUNDAY' })
    }
    if (monday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'MONDAY' })
    }
    if (tuesday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'TUESDAY' })
    }
    if (wednesday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'WEDNESDAY' })
    }
    if (thursday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'THURSDAY' })
    }
    if (friday) {
      days.push({ acceptorCode, from, to, dayOfWeek: 'FRIDAY' })
    }
    return days
  }

  const isAfter = (time: string) => {
    return moment(time, 'HH:mm').isAfter(moment(from, 'HH:mm'))
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (everyDay) {
      setSaturday(true)
      setSunday(true)
      setMonday(true)
      setTuesday(true)
      setWednesday(true)
      setThursday(true)
      setFriday(true)
    }
  }, [everyDay])

  useEffect(() => {
    if (!saturday || !sunday || !monday || !tuesday || ! wednesday || !thursday || !friday) {
      setEveryDay(false)
    }
  }, [saturday, sunday, monday, tuesday, wednesday, thursday, friday])

  return (
    <>
      <IonRow class='ion-align-items-center'>
        <IonCol>
          <IonItem>
            <IonLabel position='stacked'>{'همه روزه'}</IonLabel>
            <IonCheckbox checked={everyDay} onIonChange={(e) => setEveryDay(e.detail.checked)} />
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel position='stacked'>{'از'}</IonLabel>
            <IonSelect value={from} onIonChange={(e) => setFrom(e.detail.value)} okText='انتخاب' cancelText='انصراف'>
              <IonSelectOption value={'00:00'}>00:00</IonSelectOption>
              <IonSelectOption value={'01:00'}>01:00</IonSelectOption>
              <IonSelectOption value={'02:00'}>02:00</IonSelectOption>
              <IonSelectOption value={'03:00'}>03:00</IonSelectOption>
              <IonSelectOption value={'04:00'}>04:00</IonSelectOption>
              <IonSelectOption value={'05:00'}>05:00</IonSelectOption>
              <IonSelectOption value={'06:00'}>06:00</IonSelectOption>
              <IonSelectOption value={'07:00'}>07:00</IonSelectOption>
              <IonSelectOption value={'08:00'}>08:00</IonSelectOption>
              <IonSelectOption value={'09:00'}>09:00</IonSelectOption>
              <IonSelectOption value={'10:00'}>10:00</IonSelectOption>
              <IonSelectOption value={'11:00'}>11:00</IonSelectOption>
              <IonSelectOption value={'12:00'}>12:00</IonSelectOption>
              <IonSelectOption value={'13:00'}>13:00</IonSelectOption>
              <IonSelectOption value={'14:00'}>14:00</IonSelectOption>
              <IonSelectOption value={'15:00'}>15:00</IonSelectOption>
              <IonSelectOption value={'16:00'}>16:00</IonSelectOption>
              <IonSelectOption value={'17:00'}>17:00</IonSelectOption>
              <IonSelectOption value={'18:00'}>18:00</IonSelectOption>
              <IonSelectOption value={'19:00'}>19:00</IonSelectOption>
              <IonSelectOption value={'20:00'}>20:00</IonSelectOption>
              <IonSelectOption value={'21:00'}>21:00</IonSelectOption>
              <IonSelectOption value={'22:00'}>22:00</IonSelectOption>
              <IonSelectOption value={'23:00'}>23:00</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel position='stacked'>{'تا'}</IonLabel>
            <IonSelect value={to} onIonChange={(e) => setTo(e.detail.value)} okText='انتخاب' cancelText='انصراف'>
              {isAfter('01:00') && <IonSelectOption value={'01:00'}>01:00</IonSelectOption>}
              {isAfter('02:00') && <IonSelectOption value={'02:00'}>02:00</IonSelectOption>}
              {isAfter('03:00') && <IonSelectOption value={'03:00'}>03:00</IonSelectOption>}
              {isAfter('04:00') && <IonSelectOption value={'04:00'}>04:00</IonSelectOption>}
              {isAfter('05:00') && <IonSelectOption value={'05:00'}>05:00</IonSelectOption>}
              {isAfter('06:00') && <IonSelectOption value={'06:00'}>06:00</IonSelectOption>}
              {isAfter('07:00') && <IonSelectOption value={'07:00'}>07:00</IonSelectOption>}
              {isAfter('08:00') && <IonSelectOption value={'08:00'}>08:00</IonSelectOption>}
              {isAfter('09:00') && <IonSelectOption value={'09:00'}>09:00</IonSelectOption>}
              {isAfter('10:00') && <IonSelectOption value={'10:00'}>10:00</IonSelectOption>}
              {isAfter('11:00') && <IonSelectOption value={'11:00'}>11:00</IonSelectOption>}
              {isAfter('12:00') && <IonSelectOption value={'12:00'}>12:00</IonSelectOption>}
              {isAfter('13:00') && <IonSelectOption value={'13:00'}>13:00</IonSelectOption>}
              {isAfter('14:00') && <IonSelectOption value={'14:00'}>14:00</IonSelectOption>}
              {isAfter('15:00') && <IonSelectOption value={'15:00'}>15:00</IonSelectOption>}
              {isAfter('16:00') && <IonSelectOption value={'16:00'}>16:00</IonSelectOption>}
              {isAfter('17:00') && <IonSelectOption value={'17:00'}>17:00</IonSelectOption>}
              {isAfter('18:00') && <IonSelectOption value={'18:00'}>18:00</IonSelectOption>}
              {isAfter('19:00') && <IonSelectOption value={'19:00'}>19:00</IonSelectOption>}
              {isAfter('20:00') && <IonSelectOption value={'20:00'}>20:00</IonSelectOption>}
              {isAfter('21:00') && <IonSelectOption value={'21:00'}>21:00</IonSelectOption>}
              {isAfter('22:00') && <IonSelectOption value={'22:00'}>22:00</IonSelectOption>}
              {isAfter('23:00') && <IonSelectOption value={'23:00'}>23:00</IonSelectOption>}
              {isAfter('24:00') && <IonSelectOption value={'23:59'}>24:00</IonSelectOption>}
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol class='ion-align-self-center ion-text-center'>
          {!!to && (
            <IonButton onClick={addTimeFrame} disabled={inprogress}>
              <IonIcon icon={add} slot='icon-only' />
            </IonButton>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12'>
          <IonRow>
            <IonCol>
            <IonItem>
              <IonLabel>{'شنبه'}</IonLabel>
              <IonCheckbox checked={saturday} onIonChange={(e) => setSaturday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
            <IonCol>
            <IonItem>
              <IonLabel>{'یکشنبه'}</IonLabel>
              <IonCheckbox checked={sunday} onIonChange={(e) => setSunday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
            <IonCol>
            <IonItem>
              <IonLabel>{'دوشنبه'}</IonLabel>
              <IonCheckbox checked={monday} onIonChange={(e) => setMonday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
            <IonCol>
            <IonItem>
              <IonLabel>{'سه‌شنبه'}</IonLabel>
              <IonCheckbox checked={tuesday} onIonChange={(e) => setTuesday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
            <IonCol>
            <IonItem>
              <IonLabel>{'چهارشنبه'}</IonLabel>
              <IonCheckbox checked={wednesday} onIonChange={(e) => setWednesday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
            <IonCol>
            <IonItem>
              <IonLabel>{'پنجشنبه'}</IonLabel>
              <IonCheckbox checked={thursday} onIonChange={(e) => setThursday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
            <IonCol>
            <IonItem>
              <IonLabel>{'جمعه'}</IonLabel>
              <IonCheckbox checked={friday} onIonChange={(e) => setFriday(e.detail.checked)}></IonCheckbox>
            </IonItem>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      {items.map((itm) => (
        <IonRow class='ion-align-items-center' key={itm.id}>
          <IonCol>{t(itm.dayOfWeek)}</IonCol>
          <IonCol>{itm.from?.substr(0, 5)}</IonCol>
          <IonCol>{itm.to?.substr(0, 5)}</IonCol>
          <IonCol class='ion-align-self-center ion-text-center'>
            <IonButton onClick={() => deleteTimeframe(itm.id)} disabled={deleteInprogress} fill='clear' color='danger'>
              <IonIcon icon={trash} slot='icon-only' />
            </IonButton>
          </IonCol>
        </IonRow>
      ))}
    </>
  )
}

export default DeliveryHoursEdit
