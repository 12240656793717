import React, { useEffect, useRef } from 'react'
import { IonModal, IonHeader, IonFooter, IonContent, IonToolbar, IonButtons, IonTitle, IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'
import { connect } from '../../data/connect'
import { SmallLogo, AcceptorLogo } from '../../ayria'
import './Modal.scss'

interface StateProps {
  theme?: string
  loggedUser?: any
}

interface OwnProps {
  showModal: boolean
  title: any
  content: any
  component?: any
  actionButtons?: any
  toggle?: React.Dispatch<React.SetStateAction<boolean>>
  isPlatformTheme?: boolean
  onClosed: () => void
  backdrop?: boolean
  id?: string
  contentUsePadding?: boolean
  acceptorLogo?: string
}

interface ModalProps extends OwnProps, StateProps {}

const Modal: React.FC<ModalProps> = ({
  showModal,
  title,
  component,
  content,
  toggle,
  isPlatformTheme = false,
  onClosed = () => {},
  theme,
  backdrop = true,
  id,
  actionButtons,
  contentUsePadding = true,
  loggedUser,
  acceptorLogo,
}) => {
  const modalRef = useRef<any>()
  const innerToggle = () => {
    if (toggle) {
      toggle(false)
    }
  }

  useEffect(() => {
    document.addEventListener('ionBackButton', (ev) => {
      ev.detail.register(0, (processNextHandler) => {
        console.log('back button called!', processNextHandler)
        modalRef?.current?.dismiss()
      })
    })
  }, []) // eslint-disable-line

  return (
    <IonModal
      ref={modalRef}
      isOpen={showModal}
      swipeToClose={true}
      backdropDismiss={toggle ? true : false}
      onDidDismiss={onClosed}
      id={id}
      cssClass={isPlatformTheme ? 'platform-theme' : `${theme}-theme`}>
      <IonContent>
        <IonHeader className='non-printable'>
          <IonToolbar color='primary'>
            <IonButtons slot='start'>
              {toggle && <IonIcon slot='icon-only' icon={close} onClick={innerToggle} className='m-2 pointer' />}
            </IonButtons>
            <IonTitle className='dir-ltr'>{title}</IonTitle>
            <IonButtons slot='end'>
              {!acceptorLogo && isPlatformTheme && (
                <img alt='logo' src={'/assets/share/ayria-platform-a-logo.png'} height='45px'></img>
              )}
              {!acceptorLogo && !isPlatformTheme && <SmallLogo />}
              {!!acceptorLogo && <AcceptorLogo referralCode={acceptorLogo} />}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className={contentUsePadding ? 'ion-padding' : 'ion-no-padding'}>{content}</div>
      </IonContent>
      {actionButtons && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot='start'>{actionButtons}</IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    theme: state.user.theme,
    loggedUser: state.user.loggedUser,
  }),
  component: Modal,
})
