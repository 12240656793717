import React, { useState, useEffect, useContext } from 'react'
import { IonItem, IonButton, IonHeader, IonToolbar, IonPage, IonButtons } from '@ionic/react'
import { IonMenuButton, IonCard, IonRow, IonCol, IonText, IonLabel } from '@ionic/react'
import { IonIcon, IonBadge, useIonViewWillEnter } from '@ionic/react'
import { starOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import AcceptorList from './AcceptorList'
import ReferralModal from './ReferralModal'
import ApiService from '../../api/ApiService'
import Modal from '../../utility/modal/Modal'
import ScrollContent from '../shared/ScrollContent'
import Banner from '../shared/Banner'
import { Club } from '../../models'
import { connect } from '../../data'
import { CLUB, CLUB_HAS_CLUB_NUMBER, CLUB_HAS_LOTTERY, CLUB_AYRIA_CODE } from '../../config'
import AddressCombo from './AddressCombo'
import './HomePage.scss'
import { isDesktop } from '../../utility'
import { NotificationContext } from '../../components'
import AcceptorListHomePage from './AcceptorListHomePage'

declare var window: any

interface StateProps {
  kalaList: any[]
  savedClub?: Club
}
interface HomeProps extends StateProps {}

const HomePage: React.FC<HomeProps> = ({ kalaList, savedClub }) => {
  const [notificationContext] = useContext<any>(NotificationContext)
  const api = new ApiService()
  const history = useHistory()
  const [clubNumber, setClubNumber] = useState('')
  const [deferredPrompt, setDeferredPrompt] = useState<any>()
  const [showAddToHomeButton, setShowAddToHomeButton] = useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [unrateCount, setUnrateCount] = useState(0)
  const [showDepartment, setShowDepartment] = useState(true)
  const [scrollTop, setScrollTop] = useState(0)

  const getUnrateCount = async () => {
    const res: any = await api.get(`/customer/purchases/for-rate-count`)
    setUnrateCount(res.ok ? res.data : 0)
  }
  const getClubNumber = async () => {
    const res: any = await api.get(`/user/club-member/${CLUB}/number`)
    if (res.ok) {
      setClubNumber(res.data)
    } else if (res.status === 404) {
      const res: any = await api.post(`/user/club-member/${CLUB}/join`, {})
      if (res.ok) {
        setClubNumber(res.data)
      }
    }
  }
  const getDepartmentStatus = async () => {
    const res: any = await api.get(`/user/club/${savedClub?.code}/hide-departments`)
    setShowDepartment(!(res.ok && res.data))
  }

  const addToHome = () => {
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        setShowAddToHomeButton(false)
      } else {
        setShowAddToHomeButton(false)
      }
      setDeferredPrompt(null)
      setShowAddToHomeButton(false)
    })
  }
  const hideDepartments = () => {
    if (!!savedClub?.code) {
      if (+savedClub?.code === CLUB_AYRIA_CODE) {
        return false
      } else {
        return !showDepartment
      }
    } else {
      return false
    }
  }
  const onScroll = (event: any) => {
    setScrollTop(event.detail.scrollTop)
  }

  useIonViewWillEnter(() => {
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      // Prevent Chrome 67 and ealier from automatically showing the prompt
      e.preventDefault()
      setDeferredPrompt(e)
      setShowAddToHomeButton(true)
    })

    window.addEventListener('appinstalled', () => {
      setShowAddToHomeButton(false)
    })

    if (window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches) {
      setShowAddToHomeButton(false)
    }
  })

  useEffect(() => {
    if (!!savedClub?.code && +savedClub?.code !== CLUB_AYRIA_CODE) {
      getDepartmentStatus()
    }
  }, [savedClub?.code]) // eslint-disable-line

  useEffect(() => {
    getUnrateCount()
    getClubNumber()
  }, []) // eslint-disable-line

  return (
    <IonPage id='home-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <ScrollContent onScroll={onScroll}>
        <IonHeader className='ion-no-border' style={{ position: 'fixed', zIndex: 1999 }}>
          <IonToolbar className='text-center'>
            <IonButtons slot='start'>
              <div className='position-relative p-0 d-flex flex-column align-items-center justify-content-center ml-2'>
                <IonMenuButton />
                {!isDesktop() && notificationContext?.badge !== 0 && (
                  <IonBadge color='danger' className='position-absolute' style={{ top: -7, left: -7 }}>
                    {notificationContext.badge}
                  </IonBadge>
                )}
              </div>

              {CLUB_HAS_CLUB_NUMBER && (
                <IonButton>
                  <span>{'شماره عضویت'}</span>
                  <span className='pr-1'> {clubNumber}</span>
                </IonButton>
              )}
            </IonButtons>

            <AddressCombo />

            <IonButtons slot='end'>
              {unrateCount && unrateCount > 0 && (
                <div
                  onClick={() => history.push('/tabs/rate')}
                  className='position-relative p-0 d-flex flex-column align-items-center justify-content-center ml-2'>
                  <IonIcon slot='start' className='p-0 m-0' icon={starOutline} />
                  <IonBadge color='danger' className='position-absolute' style={{ top: -7, right: -7 }}>
                    {unrateCount}
                  </IonBadge>
                </div>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div style={{ marginTop: '50px' }}></div>
        {showAddToHomeButton && window.location.hostname === 'dashboard.ayria.club' && (
          <IonCard id='add-to-home' className='ion-text-center'>
            <IonItem>
              <IonLabel className='ion-text-wrap'>
                {'برای تجربه کاربری بهتر لطفا وب اپلیکیشن'} <IonText color='primary'>{document.title}</IonText>{' '}
                {'را نصب کنید'}
              </IonLabel>
              <IonButton slot='end' onClick={addToHome}>
                {'نصب برنامه'}
              </IonButton>
            </IonItem>
          </IonCard>
        )}

        {(unrateCount || CLUB_HAS_LOTTERY) && (
          <IonCard className='pointer' id='unrate-card'>
            <IonRow>
              {CLUB_HAS_LOTTERY && (
                <IonCol
                  size={unrateCount ? '6' : '12'}
                  className='ion-text-center ion-no-padding'
                  onClick={() => history.push('/tabs/lottery')}>
                  <IonCard id='lottery'>
                    <span style={{ fontSize: '1.2em' }}>{'قرعه کشی'}</span>
                    <br />
                    <img src='/assets/share/lottery.png' alt='rate' width='100px' />
                  </IonCard>
                </IonCol>
              )}
            </IonRow>
          </IonCard>
        )}

        <IonCard id='acceptors' style={{ minHeight: 900, overflow: 'initial' }}>
          {(!savedClub?.code || +savedClub?.code === +CLUB_AYRIA_CODE) && (
            <AcceptorListHomePage
              savedClub={savedClub}
              hideDepartments={hideDepartments()}
              scrollTop={scrollTop}
              homeMode={true}
            />
          )}
          {!!savedClub?.code && +savedClub?.code !== +CLUB_AYRIA_CODE && (
            <>
              <IonCard onClick={() => setShowInviteModal(true)} className='ion-text-center' id='banner'>
                <Banner />
              </IonCard>
              <AcceptorList savedClub={savedClub} scrollTop={scrollTop} homeMode={true} />
            </>
          )}
        </IonCard>

        <Modal
          showModal={showInviteModal}
          toggle={setShowInviteModal}
          onClosed={() => setShowInviteModal(false)}
          title='دعوت از دوستان'
          content={<ReferralModal />}
        />
      </ScrollContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
    savedClub: state.user.savedClub,
  }),
  component: HomePage,
})
