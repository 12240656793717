import { IonItem, IonLabel, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { api } from '../../ayria'

interface OwnProps {
  clubCode: string
  onValueChange: Function
  groupId?: string
}
interface ClubGroupSelectProps extends OwnProps {}

const ClubGroupSelect: FC<ClubGroupSelectProps> = ({ clubCode, onValueChange, groupId }) => {
  const [group, setGroup] = useState<any>(groupId)
  const [items, setItems] = useState([])
  const [inprogress, setInprogress] = useState(true)

  const fetchItems = async () => {
    setInprogress(true)
    const res: any = await api.get(`/club/club-group/list/${clubCode}`)
    if (res.ok) {
      setItems(res.data)
    }
    setInprogress(false)
  }

  useEffect(() => {
    onValueChange(group)
  }, [group]) // eslint-disable-line

  useEffect(() => {
    fetchItems()
  }, [clubCode]) // eslint-disable-line

  return (
    <>
      {inprogress && <IonSpinner />}
      {!inprogress && items.length > 0 && (
        <IonItem>
          <IonLabel>{'انتخاب گروه اعضا'}</IonLabel>
          <IonSelect value={group} onIonChange={(e) => setGroup(e.detail.value)} okText='انتخاب' cancelText='انصراف'>
            {items.map((itm: any) => (
              <IonSelectOption key={itm?.id} value={itm?.id}>
                {itm?.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      )}
    </>
  )
}

export default ClubGroupSelect
