import React, { useRef, useContext, useState } from 'react'
import { IonModal, IonItem, IonLabel, IonList, IonToast } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import PinInput from '../../components/units/PinInput/PinInput'
import ApiService from '../../api/ApiService'
import { ProfileContext } from '../../components/structures/contexts/ProfileContext'
import { setPinChecked } from '../../data/user/user.actions'
import { connect } from '../../data/connect'
import './PinModal.scss'

interface DispatchProps {
  setPinChecked: typeof setPinChecked
}

interface OwnProps {
  isOpen: boolean
  firstTimePin?: boolean
  turnPinOff?: boolean
  changePin?: boolean
  onSuccess: () => void
  onCancel: () => void
}

interface PinModalProps extends OwnProps, DispatchProps {}

const PinModal: React.FC<PinModalProps> = ({
  isOpen,
  firstTimePin = false,
  turnPinOff = false,
  changePin = false,
  onSuccess,
  onCancel,
  setPinChecked,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const [showToast, setShowToast] = useState(false)
  const [toast, setToast] = useState('')
  // eslint-disable-next-line
  const [profileContext, setProfileContext] = useContext<any>(ProfileContext)
  const pinInput = useRef() as React.MutableRefObject<PinInput>
  const [newPin, setNewPin] = useState()

  const submit = async (value: string, index: number) => {
    if (firstTimePin) {
      const res: any = await api.post('/account/profile/pin/set', { pin: value })
      if (res.ok) {
        setProfileContext((state: any) => ({ ...state, profile: res.data }))
        onSuccess()
      } else {
        setToast(res.data && res.data.title ? res.data.title : t('label.operationFailed', { status: res.status }))
        setShowToast(true)
      }
    } else if (turnPinOff) {
      const res: any = await api.post('/account/profile/pin/off', { pin: value })
      if (res.ok) {
        setProfileContext((state: any) => ({ ...state, profile: res.data }))
        onSuccess()
      } else if (res.status === 403) {
        setToast('شما بیش از ۳ بار رمز عبور خورد را اشتباه وارد کردید، لطفا بعد از ۱ ساعت دوباره تلاش کنید')
        setShowToast(true)
      } else {
        pinInput.current.clear()
        setToast(`رمز عبور وارد شده اشتباه است ${res.status}`)
        setShowToast(true)
      }
    } else if (changePin) {
      const res: any = await api.post('/account/profile/pin/set', { pin: newPin, previous: value })
      if (res.ok) {
        setProfileContext((state: any) => ({ ...state, profile: res.data }))
        onSuccess()
      } else if (res.status === 403) {
        setToast('شما بیش از ۳ بار رمز عبور خورد را اشتباه وارد کردید، لطفا بعد از ۱ ساعت دوباره تلاش کنید')
        setShowToast(true)
      } else {
        pinInput.current.clear()
        setToast(`رمز عبور وارد شده اشتباه است ${res.status}`)
        setShowToast(true)
      }
    } else {
      const res: any = await api.post('/account/profile/pin/check', { pin: value })
      if (res.ok) {
        await setPinChecked(res.data)
        onSuccess()
      } else if (res.status === 403) {
        setToast('شما بیش از ۳ بار رمز عبور خورد را اشتباه وارد کردید، لطفا بعد از ۱ ساعت دوباره تلاش کنید')
        setShowToast(true)
      } else {
        pinInput.current.clear()
        setToast(`رمز عبور وارد شده اشتباه است ${res.status}`)
        setShowToast(true)
      }
    }
  }

  return (
    <IonModal isOpen={isOpen} swipeToClose={true} onDidDismiss={() => onCancel()} id='pinModal'>
      <IonList>
        {changePin && (
          <IonItem>
            <IonLabel>
              {'رمز عبور جدید خود را وارد کنید'}
              <div className='ltr ion-text-center'>
                <PinInput
                  length={4}
                  focus
                  type='numeric'
                  onComplete={(value: any, index: number) => {
                    setNewPin(value)
                    pinInput.current.focus()
                  }}
                  inputMode='numeric'
                  inputStyle={{ textSecurity: 'disc' }}
                />
              </div>
            </IonLabel>
          </IonItem>
        )}
        <IonItem>
          <IonLabel className='ion-text-wrap'>
            <p>
              {firstTimePin && 'لطفا رمز عبور مورد نظر خود را وارد کنید'}
              {(turnPinOff || changePin) && 'لطفا رمز عبور قبلی خود را وارد کنید'}
              {!firstTimePin && !turnPinOff && !changePin && 'لطفا رمز ورود به صفحه پرداخت خود را وارد کنید'}
            </p>
            <div className='ltr text-center'>
              <PinInput
                length={4}
                focus={true}
                type='numeric'
                onComplete={submit}
                ref={pinInput}
                inputMode='numeric'
                inputStyle={{ textSecurity: 'disc' }}
              />
            </div>
          </IonLabel>
        </IonItem>
      </IonList>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toast}
        duration={2000}
        color='danger'
      />
    </IonModal>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setPinChecked,
  },
  component: PinModal,
})
