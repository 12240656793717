import React, { Suspense, useState, useEffect, useContext, lazy } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProfileDropDownAcceptor from '../../elements/AcceptorProfileDropDown/profile-drop-down-acceptor'
import { ScoreContext } from '../../structures/contexts/ScoreContext'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import { ProfileContext } from '../../structures/contexts/ProfileContext'
import { NotificationContext } from '../../structures/contexts/NotificationContext'
import Auth from '../../../utility/auth/Auth'
import PlatformModal from '../../elements/CustomModal/PlatformModal'
import notification from '../../../assets/images/notification.png'
import inviteFriend from '../../../assets/images/invite-friend-platform.png'
import '../../../assets/styles/css/Club-theme/dashboard.css'
import { IonSpinner, IonRow, IonCol, IonIcon } from '@ionic/react'
import { ApiService, connect, setAcceptorCode } from '../../../ayria'

const ReferralLink = lazy(() => import('../../modules/referral-link/referral-link'))

interface StateProps {
  hasSeenRegisterClub?: boolean
}
interface DispatchProps {
  setAcceptorCode: typeof setAcceptorCode
}
interface HeaderProps extends StateProps, DispatchProps {}

const Header: React.FC<HeaderProps> = ({ hasSeenRegisterClub, setAcceptorCode }) => {
  const api = new ApiService()
  const auth = new Auth()
  const history = useHistory()
  const { t } = useTranslation()
  const [acceptors, setAcceptors] = useState()
  const [profile, setProfile] = useState<any>({}) // eslint-disable-line
  const [scoreContext, setScoreContext] = useContext<any>(ScoreContext) // eslint-disable-line
  const [acceptorContext, setAcceptorContext] = useContext<any>(AcceptorContext)
  const [profileContext, setProfileContext] = useContext<any>(ProfileContext)
  const [notificationContext, setNotificationContext] = useContext<any>(NotificationContext)
  const [showInviteFriend, setShowInviteFriend] = useState(false)
  const [inprogress, setInprogress] = useState(false)

  const initData = async () => {
    setInprogress(true)
    const profileRes: any = await api.get('/account/profile')
    if (profileRes.ok) {
      setProfile(profileRes.data)
      setProfileContext((state: any) => ({ ...state, profile: profileRes.data }))
    }
    const res: any = await api.get('/acceptor/current')
    if (res.ok) {
      setAcceptors(res.data)
      const currentAcceptorIndex = localStorage.getItem(`CURRENT_ACCEPTOR_INDEX_${auth.getLoggedInUser().login}`) || 0
      setAcceptorContext((state: any) => ({
        ...state,
        all: res.data,
        current: currentAcceptorIndex < res.data.length ? res.data[currentAcceptorIndex] : res.data[0],
        currentIndex: currentAcceptorIndex < res.data.length ? currentAcceptorIndex : 0,
      }))
      await setAcceptorCode(
        currentAcceptorIndex < res.data.length ? res.data[currentAcceptorIndex].code : res.data[0].code
      )
    }
    setInprogress(false)
  }
  const updateCurrentAcceptor = async () => {
    const scoreRes: any = await api.get(`/customer/scores/total/${acceptorContext.current.code}`)
    if (scoreRes.ok) setScoreContext((state: any) => ({ ...state, [acceptorContext.current.code]: scoreRes.data }))

    const notifRes: any = await api.get(`/user/notifications/badge`)
    if (notifRes.ok) setNotificationContext((state: any) => ({ ...state, badge: notifRes.data }))
  }

  useEffect(() => {
    initData()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (acceptorContext.current) {
      updateCurrentAcceptor()
    }
  }, [acceptorContext.current]) // eslint-disable-line

  return (
    <Suspense fallback={<IonSpinner color='primary' />}>
      {acceptors && (
        <div>
          <div className={'desktop-only'}>
            <nav id={'ayria-header'} className='navbar platform  navbar-light bg-white' style={{ padding: 0 }}>
              <div className={'container align-items-center justify-content-between position-relative'}>
                <div />

                <Link style={{ color: '#003899' }} to={'/panel'}>
                  اکوسیستم هوشمند پرداخت
                </Link>

                <ul className='nav  '>
                  <li className='nav-item'>
                    <ProfileDropDownAcceptor acceptors={acceptors} />
                  </li>

                  <li className='nav-item pt-2 '>
                    <span className='notification-item'>
                      {!isNaN(notificationContext?.badge) && (
                        <span className='badge badge-danger notification-badge'>{notificationContext?.badge}</span>
                      )}
                      {/*<IonIcon icon={personCircleOutline} />*/}

                      <img
                        alt=''
                        src={notification}
                        className={'notification-icon pointer'}
                        onClick={(e) => history.push('/notifications-acceptor')}
                      />
                    </span>
                  </li>
                </ul>

                {/*<div className={'center'}>*/}
                {/*<Link style={{color:'#003899'}} className={' center '} to={'/panel'}>*/}
                {/*  اکوسیستم هوشمند پرداخت*/}
                {/*</Link>*/}
                {/*<Link className={' pr-3 logo left '} to={'/panel'}>*/}
                {/*  <IonIcon src={'/assets/share/apg.svg'} style={{ fontSize: '50px' }} />*/}
                {/*</Link>*/}
                {/*<div className='vl left pr-2 pt-5'></div>
                  <div className='text-theme-platform left pt-3 pl-2 pointer'>{'راهنما'}</div>*/}
                {/*</div>*/}
              </div>
            </nav>
          </div>

          <div className={'mobile-only'}>
            <div className={'header-row-platform'}>
              <ul className='right nav'>
                <li className='nav-item'>
                  <ProfileDropDownAcceptor acceptors={acceptors}></ProfileDropDownAcceptor>
                </li>
                <li className='nav-item'></li>
                <li className={'pt-2'}>
                  <span className='notification-item'>
                    {!isNaN(notificationContext?.badge) && (
                      <span className='badge badge-danger notification-badge'>{notificationContext?.badge}</span>
                    )}
                    <img
                      alt=''
                      src={notification}
                      className={'notification-icon pointer'}
                      onClick={(e) => history.push('/notifications-acceptor')}
                      width='30'
                      height='35'
                    />
                  </span>
                </li>
                <li className={'pt-2'}>
                  <img alt='' src={inviteFriend} onClick={() => setShowInviteFriend(true)} width='30px' height='40px' />
                </li>
              </ul>
              <Link className={'logo left platform'} to={'/panel'}>
                <IonIcon src={'/assets/share/apg.svg'} style={{ fontSize: '50px', marginTop: '5px' }} />
              </Link>
            </div>
          </div>
          <PlatformModal
            showModal={showInviteFriend}
            toggle={setShowInviteFriend}
            title='دعوت از دوستان'
            content={
              <div className={'referralLinkHolder'}>
                {profileContext?.profile && (
                  <div title={`${t('profile.referralCode')} : ${profileContext?.profile.referralCode}`}>
                    <div className={'mb-2 text-justify'}>
                      شما با استفاده از این کد میتونید خیلی راحت هر کسی رو که بخواهید به جمع آیریا دعوت کنید تا هم
                      خودتون و هم کسی که دعوت میکنید کلی امتیاز بگیره ،پس همین الان بفرست برای همه
                    </div>
                    <ReferralLink profile={profileContext.profile} theme={'acceptor'} />
                  </div>
                )}
              </div>
            }
          />
        </div>
      )}
      {!acceptors && !inprogress && (
        <div className='alert alert-warning'>
          {
            'شما به صفحه مورد نظر دسترس ندارید، اگر به تازگی مالک فروشگاه شده اید؟ لطفا یکبار از اپلیکیشن خارج و دوباره وارد شوید.'
          }
          <IonRow>
            <IonCol>
              <Link to={'/panel'} onClick={() => window.location.reload()}>
                بارگذاری دوباره
              </Link>
            </IonCol>

            <IonCol>
              <Link to={'/tabs'}>{'برگشت به محیط کاربری'}</Link>
            </IonCol>
            <IonCol>
              <Link to={'/logout'}> خروج </Link>
            </IonCol>
          </IonRow>
        </div>
      )}
    </Suspense>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasSeenRegisterClub: state.user.hasSeenRegisterClub,
  }),
  mapDispatchToProps: {
    setAcceptorCode,
  },
  component: Header,
})
