import React, { useState, useContext } from 'react'
import { IonButton, IonFooter, IonToolbar, IonButtons, IonItem, IonLabel } from '@ionic/react'
import { IonInput, IonList, IonSpinner } from '@ionic/react'
import Timer from 'react-compound-timer'
import { useTranslation } from 'react-i18next'
import WalletDeposit from '../WalletDeposit/WalletDeposit'
import { WalletContext } from '../../structures/contexts/WalletContext'
import WalletTransferConfirm from './WalletTransferConfirm'
import { IPG_MIN_ACCEPTED_AMOUNT, Modal, ApiService, MobileSuggest, CurrencyIonInput } from '../../../ayria'
import { useToast, MobileInvite, NumberIonInput, WebOTP } from '../../../ayria'
import { TRANSFER_OPERATION_COST_VALUE } from '../../../ayria'

interface WalletTransferProps {
  amount: number
  onSuccess: (res: any) => void
  acceptorCode: string
}

const WalletTransfer: React.FC<WalletTransferProps> = ({ amount, onSuccess, acceptorCode }) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const [inprogress, setInprogress] = useState(false)
  const [inprogressFinal, setInprogressFinal] = useState(false)
  const [senderDescription, setSenderDescription] = useState()
  const [receiverDescription, setReceiverDescription] = useState()
  const [dynamicAmount, setDynamicAmount] = useState<any>(amount || '')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showWalletDepositModal, setShowWalletDepositModal] = useState(false)
  const [neededAmount, setNeededAmount] = useState(0)
  const [originalCommand, setOriginalCommand] = useState<string>()
  const [walletContext] = useContext<any>(WalletContext)
  const [mobileNumber, setMobileNumber] = useState('')
  const [oneTimeCode, setOneTimeCode] = useState()
  const [oneTimeCodeExpired, setOneTimeCodeExpired] = useState(true)
  const [showMobileInviteModal, setShowMobileInviteModal] = useState(false)
  const [otpInputReady, setOtpInputReady] = useState(false)
  const [receiver, setReceiver] = useState<any>()

  const handleTransferForReal = async () => {
    setInprogress(true)
    setInprogressFinal(true)
    const cmd = {
      amount: dynamicAmount,
      mobile: mobileNumber,
      senderDescription,
      receiverDescription,
      senderAcceptorCode: acceptorCode,
      oneTimeCode,
    }
    const res: any = await api.post('/wallet/transfer', cmd)
    if (res.ok) {
      toast.success(t('label.successfulOperation'))
      onSuccess(res.data)
    } else if (res.status === 417) {
      const debt = dynamicAmount - walletContext.balance
      setNeededAmount(debt < IPG_MIN_ACCEPTED_AMOUNT ? IPG_MIN_ACCEPTED_AMOUNT : debt)
      setOriginalCommand(JSON.stringify(cmd))
      setShowWalletDepositModal(true)
    } else {
      toast.error(res.data && res.data.title ? res.data.title : t('label.operationFailed', { status: res.status }))
    }
    setInprogress(false)
    setInprogressFinal(false)
  }
  const handleTransfer = () => {
    setShowConfirmModal(true)
  }

  const checkOneTimeCode = async () => {
    setInprogress(true)
    const res: any = await api.post('/wallet/transfer/checkOneTimeCode', {
      oneTimeCode,
      mobileNumber,
      amount: dynamicAmount,
    })
    if (res.ok) {
      handleTransfer()
    } else if (res.status === 404) {
      setShowMobileInviteModal(true)
    } else {
      toast.error('رمز یکبار مصرف اشتباه و یا نامتعبر است، لطفا دوباره تلاش کنید.')
    }
    setInprogress(false)
  }

  const requestOneTimeCode = async () => {
    if (oneTimeCodeExpired) {
      setOneTimeCodeExpired(false)
      setInprogress(true)
      const res: any = await api.post(`/wallet/transfer/requestOneTimeCode/${dynamicAmount}`, {})
      if (res.ok) {
        toast.success('رمز یکبار مصرف به شماره همراه شما پیامک شد')
      } else {
        toast.error(res)
        setOneTimeCodeExpired(true)
      }
      setInprogress(false)
    }
  }

  const onUserMobileChange = (user: any) => {
    if (user?.mobile) {
      setReceiver(user)
      setMobileNumber(user.mobile)
    }
  }

  return (
    <>
      {inprogressFinal && (
        <div className='text-center'>
          <IonSpinner name='crescent' color='dark' />
          {'لطفا منتظر بمانید...'}
        </div>
      )}
      {!inprogressFinal && (
        <div>
          <IonList className='ion-no-padding'>
            <IonItem>
              <IonLabel position='stacked' className='ion-text-wrap'>
                <span>
                  مبلغ مورد نظر شما برای انتقال موجودی با احتساب کارمزد :{' '}
                  {(+dynamicAmount + TRANSFER_OPERATION_COST_VALUE).toLocaleString(navigator.language)}{' '}
                  {t('label.riyal')}{' '}
                </span>
              </IonLabel>
              <CurrencyIonInput onValueChange={setDynamicAmount} defaultValue={dynamicAmount} disabled={inprogress} />
            </IonItem>
            {/*<IonItem>
              <IonLabel position='stacked'>{t('label.mobile') + ' دریافت کننده وجه'}</IonLabel>
              <MobileSuggest
                value={mobileNumber}
                onMobileChange={setMobileNumber}
                onUserChange={onUserMobileChange}
                ionInput={true}
              />
            </IonItem>*/}
            <div className='form-group'>
              <IonLabel position='stacked' style={{ fontSize: '12px' }} className='ion-padding-start'>
                {t('label.mobile') + ' دریافت کننده وجه'}
              </IonLabel>
              <MobileSuggest
                value={mobileNumber}
                onMobileChange={setMobileNumber}
                onUserChange={onUserMobileChange}
                showBorderBottomOnly={true}
                placeholder={'    شماره همراه'}
              />
            </div>
            {receiver && (
              <div>
                <div className='from-group'>
                  <label>
                    نام و نام خانوادگی: {receiver.firstName} {receiver.lastName}
                  </label>
                </div>
              </div>
            )}
            <IonItem>
              <NumberIonInput
                value={oneTimeCode}
                onValueChange={setOneTimeCode}
                placeholder={'رمز پیامک شده'}
                autocomplete='one-time-code'
                componentDidMount={() => setOtpInputReady(true)}
              />
              {otpInputReady && <WebOTP onReceive={(code: any) => setOneTimeCode(code)} />}
              <IonButton slot='start' onClick={requestOneTimeCode} disabled={!dynamicAmount}>
                {oneTimeCodeExpired ? (
                  'درخواست رمز'
                ) : (
                  <Timer
                    initialTime={120000}
                    direction='backward'
                    checkpoints={[{ time: 0, callback: () => setOneTimeCodeExpired(true) }]}>
                    <Timer.Seconds /> : <Timer.Minutes />
                  </Timer>
                )}
              </IonButton>
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>توضیحات مبدا</IonLabel>
              <IonInput onIonChange={(e: any) => setSenderDescription(e.detail.value)}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>توضیحات مقصد</IonLabel>
              <IonInput onIonChange={(e: any) => setReceiverDescription(e.detail.value)}></IonInput>
            </IonItem>
          </IonList>
          <IonFooter style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton
                  color='primary'
                  onClick={checkOneTimeCode}
                  disabled={inprogress || mobileNumber?.length !== 11 || !dynamicAmount || !oneTimeCode}>
                  {'بعدی'}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
          <Modal
            title={'افزایش موجودی'}
            showModal={showWalletDepositModal}
            toggle={setShowWalletDepositModal}
            onClosed={() => setShowWalletDepositModal(false)}
            content={
              <WalletDeposit
                amount={'' + neededAmount}
                originalCommand={originalCommand}
                originalCommandType={'WALLET_TRANSFER'}
                targetAmount={dynamicAmount}
              />
            }
          />
          <Modal
            title={'تایید انتقال'}
            showModal={showConfirmModal}
            toggle={setShowConfirmModal}
            onClosed={() => setShowConfirmModal(false)}
            content={
              <WalletTransferConfirm
                transfer={{
                  refType: 'TRANSFER',
                  amount: dynamicAmount,
                  mobileNumber,
                  senderDescription,
                  receiverDescription,
                }}
              />
            }
            actionButtons={
              <>
                <IonButton
                  color='primary'
                  onClick={() => {
                    setShowConfirmModal(false)
                    handleTransferForReal()
                  }}>
                  {'تایید'}
                </IonButton>
                <IonButton
                  color='dark'
                  className='ion-padding-start'
                  onClick={() => {
                    setShowConfirmModal(false)
                  }}>
                  {t('label.cancel')}
                </IonButton>
              </>
            }
          />
          <Modal
            title={'دعوت مخاطب'}
            showModal={showMobileInviteModal}
            toggle={setShowMobileInviteModal}
            onClosed={() => setShowMobileInviteModal(false)}
            content={
              <MobileInvite
                mobile={mobileNumber}
                onCancel={() => setShowMobileInviteModal(false)}
                onSuccess={() => setShowMobileInviteModal(false)}
              />
            }
          />
        </div>
      )}
    </>
  )
}

export default WalletTransfer
