import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IonContent, IonPage } from '@ionic/react'
import { IonRow, IonText, IonCol, IonIcon, IonButton, IonFooter, IonBadge, IonItem } from '@ionic/react'
import { addCircleSharp, addOutline, shareSocialOutline, removeCircleSharp } from 'ionicons/icons'
import { ApiService, CDN_BASE_URL, connect, hasFeature, Lightbox } from '../../ayria'
import { Header, setKalaList, addKala, removeKala } from '../../ayria'
import './KalaPage.scss'
import ImageGallery from 'react-image-gallery'

declare var window: any

interface StateProps {
  kalaList: any[]
}
interface OwnProps {}
interface DispatchProps {
  setKalaList: typeof setKalaList
  addKala: typeof addKala
  removeKala: typeof removeKala
}

interface KalaPageProps extends OwnProps, StateProps, DispatchProps {}

const KalaPage: React.FC<KalaPageProps> = ({ kalaList, setKalaList, addKala, removeKala }) => {
  const { acceptorReferralCode, kalaId } = useParams<any>()
  const api = new ApiService()
  const [kala, setKala] = useState<any>()
  const [imgs, setImgs] = useState<any>()
  const [inprogress, setInprogress] = useState(false)
  const [showLightBox, setShowLightBox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const showShoppingCart = () => hasFeature('SHOPPING_CART') && kalaList?.length > 0
  const history = useHistory()

  const getKala = async () => {
    setInprogress(true)
    const res: any = await api.get(`/public/kala/${acceptorReferralCode}/id/${kalaId}`)
    if (res.ok) {
      setKala(res.data)

      let a = []

      for (let i = 0; i < res.data.images.length; i++) {
        a.push({
          original: `${CDN_BASE_URL}/kala/${res.data.images[i]}`,
          thumbnail: `${CDN_BASE_URL}/kala/${res.data.images[i]}`,
        })
      }
      setImgs(a)
    }
    setInprogress(false)
  }

  const handleLightBoxClose = () => {
    setShowLightBox(false)
    setSelectedImage(null)
  }

  const formattedImages = kala?.images.map((element: string) => `${CDN_BASE_URL}/kala/${element}`)

  const add = async (kala: any) => {
    await addKala(kala)
  }
  const addKalaPrice = async (kala: any, kalaPrice: any) => {
    const compKala = { ...kala, id: `${kala.id}-${kalaPrice.id}` }
    await addKala(compKala)
  }
  const remove = async (kala: any) => {
    await removeKala(kala)
  }
  const removeKalaPrice = async (kala: any, kalaPrice: any) => {
    const compKala = { ...kala, id: `${kala.id}-${kalaPrice.id}` }
    await removeKala(compKala)
  }
  const count = (kala: any) => {
    const exist = kalaList.find((x) => x.id === kala?.id)
    if (exist && exist?.qty > 0) {
      return exist.qty
    } else return 0
  }
  const countKalaPrice = (kala: any, kalaPrice: any) => {
    const exist = kalaList.find((x) => x.id === `${kala.id}-${kalaPrice.id}`)
    if (exist && exist?.qty > 0) {
      return exist.qty
    } else return 0
  }

  useEffect(() => {
    getKala()
  }, [acceptorReferralCode, kalaId]) // eslint-disable-line

  return (
    <IonPage id='kala-page'>
      <IonContent fullscreen={true}>
        <Header title={kala?.acceptorName} titleLink={`/tabs/center/${acceptorReferralCode}`} />
        <div>
          <div className='top-section'>
            {!inprogress && imgs?.length > 0 && (
              <ImageGallery
                showNav={false}
                autoPlay={true}
                slideInterval={4000}
                disableSwipe={imgs.length < 1}
                showFullscreenButton={false}
                showBullets={false}
                showThumbnails={false}
                showPlayButton={false}
                showIndex={false}
                items={imgs}
                // additionalClass="originalClassImageGallery"
              />
            )}
          </div>
          {formattedImages?.length > 0 && (
            <Lightbox
              isOpen={showLightBox}
              firstImage={selectedImage}
              images={formattedImages}
              onClose={handleLightBoxClose}
            />
          )}
          <div className='ion-padding-horizontal'>
            <IonRow className='ion-align-items-center'>
              <IonCol size='6' className='ion-text-right '>
                <h2 className='item-name'>{kala?.name}</h2>
              </IonCol>
              <IonCol size='6' className='ion-text-left'>
                <IonIcon
                  icon={shareSocialOutline}
                  color='gray'
                  size='large'
                  onClick={() => {
                    if (navigator.share) {
                      navigator
                        .share({
                          title: 'اشتراک گذاری',
                          url: `/kala/${acceptorReferralCode}/${kalaId}`,
                        })
                        .then(() => {})
                    } else {
                    }
                  }}
                />
              </IonCol>
              <IonCol size='6' className='ion-text-left ion-margin-top'></IonCol>
            </IonRow>
            {kala?.acceptor?.onlineSell && (
              <>
                {!!kala?.price && (
                  <IonRow className='ion-align-items-center'>
                    <IonCol size='6' className='ion-text-right'>
                      <h4 className='price'>
                        <span>{kala?.price?.toLocaleString(navigator.language)} ریال </span>{' '}
                      </h4>
                    </IonCol>
                    <IonCol size='6' className='ion-text-left'>
                      {showShoppingCart() && kala && count(kala) > 0 ? (
                        <IonItem lines='none'>
                          <IonIcon size='large' color='primary' icon={addCircleSharp} onClick={() => add(kala)} />
                          <IonBadge color='light'>{count(kala)}</IonBadge>
                          <IonIcon size='large' color='primary' icon={removeCircleSharp} onClick={() => remove(kala)} />
                        </IonItem>
                      ) : (
                        <>
                          {kala.outOfStock && (
                            <img
                              src='/assets/images/out-of-stock.png'
                              width={70}
                              height={70}
                              style={{ position: 'absolute', top: -40, right: 70, width: '70px', height: '70px' }}
                              alt=''
                            />
                          )}
                          <IonButton
                            expand='block'
                            size='default'
                            onClick={() => add(kala)}
                            color='primary'
                            disabled={kala.outOfStock}>
                            <IonIcon icon={addOutline}></IonIcon>
                            {'افزودن'}
                          </IonButton>
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                )}
                {kala?.prices?.map((kp: any) => (
                  <IonRow key={kp.id}>
                    <IonCol size='5' className='ion-text-right'>
                      {countKalaPrice(kala, kp) > 0 ? (
                        <div style={{ paddingRight: '12px' }}>
                          <IonIcon
                            size='large'
                            color='primary'
                            icon={addCircleSharp}
                            onClick={() => addKalaPrice(kala, kp)}
                          />
                          <IonBadge color='light' className='ion-padding' style={{ paddingBottom: '10px' }}>
                            {countKalaPrice(kala, kp)}
                          </IonBadge>
                          <IonIcon
                            size='large'
                            color='primary'
                            icon={removeCircleSharp}
                            onClick={() => removeKalaPrice(kala, kp)}
                          />
                        </div>
                      ) : (
                        <IonItem lines='none' style={{ width: '130px' }}>
                          <IonButton
                            expand='block'
                            fill='outline'
                            onClick={() => addKalaPrice(kala, kp)}
                            color='primary'
                            disabled={kala.outOfStock}
                            style={{ minWidth: '90px', margin: '0' }}>
                            <IonIcon slot='start' icon={addOutline}></IonIcon>
                            {'افزودن'}
                          </IonButton>
                        </IonItem>
                      )}
                    </IonCol>

                    <IonCol size='7' className='ion-text-end'>
                      <div className='ion-text-end'>
                        <IonText className='float-right ion-padding-start' style={{ paddingTop: '10px' }}>
                          {kp?.name}
                        </IonText>
                        <IonText
                          className='float-left'
                          style={{ fontSize: '17px', fontWeight: 'bold', paddingTop: '10px' }}>
                          {kp?.price?.toLocaleString(navigator.language)} ریال{' '}
                        </IonText>
                      </div>
                    </IonCol>
                  </IonRow>
                ))}
              </>
            )}
          </div>
          {!!kala?.description && (
            <>
              <div className='seperator'>
                <hr />
              </div>
              <div className='description ion-padding'>
                <h6>شرح کالا</h6>
                <p style={{ textAlign: 'justify' }}>{kala?.description}</p>
              </div>
              <div className='seperator'>
                <hr />
              </div>
            </>
          )}
        </div>
      </IonContent>
      {kala?.acceptor?.onlineSell && (
        <IonFooter>
          <IonRow>
            <IonCol size='12'>
              <IonButton expand='full' onClick={() => history.push(`/shopping-cart${window.location.search}`)}>
                تکمیل و ادامه خرید
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      )}
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setKalaList,
    addKala,
    removeKala,
  },
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
  }),
  component: KalaPage,
})
