import React, { useState } from 'react'
import { add, settings, checkmark } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { IonContent, IonPage, IonButton, IonIcon, IonItem } from '@ionic/react'
import { useIonViewWillEnter, IonText, IonListHeader, IonLabel, IonItemSliding } from '@ionic/react'
import { IonItemOptions, IonItemOption } from '@ionic/react'
import { ApiService, connect, setHasSeenRegisterClub, useToast, User, Header } from '../../ayria'
import { isDesktop } from '../../utility'
import { setMyClubCode } from '../../data'

interface StateProps {
  loggedUser?: User
}
interface DispatchProps {
  setHasSeenRegisterClub: typeof setHasSeenRegisterClub
  setMyClubCode: typeof setMyClubCode
}
interface MyClubListTabProp extends StateProps, DispatchProps {}

const MyClubListTab: React.FC<MyClubListTabProp> = ({ setHasSeenRegisterClub, loggedUser, setMyClubCode }) => {
  const api = new ApiService()
  const [items, setItems] = useState([])
  const toast = useToast()
  const history = useHistory()
  const { acceptorCode } = useParams<any>()

  const getItems = async () => {
    const res: any = await api.myClubs(acceptorCode)
    if (res.ok) {
      setItems(res.data)
      if (res.data.length >= 1) {
        markNewFeatureRegisterClubAsSeen()
      }
    } else if (res.status === 403) {
      history.push('/logout')
    } else {
      toast.error(res)
    }
  }

  const handleRowClick = async (item: any) => {
    if (item.enabled) {
      await setMyClubCode(item.code)
      history.push(`/myclub/${item.code}`)
    }
  }

  const markNewFeatureRegisterClubAsSeen = async () => {
    await setHasSeenRegisterClub(true)
  }

  useIonViewWillEnter(() => {
    getItems()
  })

  return (
    <IonPage id='my-club-list-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          {loggedUser?.authorities?.includes('ROLE_ACCEPTOR') && (
            <IonButton routerLink={`/newclub/${acceptorCode}`}>
              <IonIcon slot='start' icon={add} />
              {'ثبت باشگاه'}
            </IonButton>
          )}
          <IonListHeader>
            <IonLabel>{'تعداد تراکنش های ماه جاری'}</IonLabel>
            <IonButton color='danger' disabled={true}>
              {'بزودی'}
            </IonButton>
          </IonListHeader>
          {items.map((c: any, index: number) => (
            <IonItemSliding key={c.code + ' ' + index}>
              <IonItem className='pointer' onClick={() => handleRowClick(c)}>
                <IonText slot='start'>{c.name}</IonText>
                <IonText slot='end' color='dark'>
                  {c.enabled && <IonIcon icon={checkmark} />}
                  {!c.enabled && <span>(در انتظار تایید)</span>}
                </IonText>
              </IonItem>
              <IonItemOptions slot='end' style={{ width: '40px' }}>
                <IonItemOption onClick={() => handleRowClick(c)}>
                  <IonIcon icon={settings} />
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          ))}
          {/*<IonCard>
            <IonCardContent>
              {'به زودی...'}
            </IonCardContent>
          </IonCard>*/}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
  }),
  mapDispatchToProps: {
    setHasSeenRegisterClub,
    setMyClubCode,
  },
  component: MyClubListTab,
})
