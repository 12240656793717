import React, { useState, useEffect, useContext } from 'react'
import { IonIcon, IonText, IonLabel, IonRow, IonCol } from '@ionic/react'
import { sync } from 'ionicons/icons'
import { AcceptorContext } from '../../structures/contexts/AcceptorContext'
import { connect } from '../../../data/connect'
import { ApiService, Cashdesk, CashdeskSelect } from '../../../ayria'

interface OwnProps {
  acceptorCode: string
  fromTime?: string
  toTime?: string
  switchable?: boolean
}
interface StateProps {
  hasWaiter: boolean
  currentCashdesk: Cashdesk
}
interface CashDeskDailyProps extends OwnProps, StateProps {}

const CashDeskDaily: React.FC<CashDeskDailyProps> = ({
  acceptorCode,
  fromTime,
  toTime,
  hasWaiter,
  currentCashdesk,
  switchable = false,
}) => {
  const api = new ApiService()
  const [totalWallet, setTotalWallet] = useState(0)
  const [totalCash, setTotalCash] = useState(0)
  const [totalPos, setTotalPos] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalTip, setTotalTip] = useState('')
  const [acceptorContext] = useContext<any>(AcceptorContext)
  const [inprogress, setInprogress] = useState(false)
  const [code, setCode] = useState('')
  const [cashDesk, setCashDesk] = useState(null)

  const getBalance = async () => {
    setInprogress(true)
    const timeParam = `${!!fromTime ? `&purchaseTime.greaterThanOrEqual=${fromTime}` : ''}${
      !!toTime ? `&purchaseTimeTo.lessThanOrEqual=${toTime}` : ''
    }`
    const cashdeskParam = cashDesk ? `&cashdesk.equals=${cashDesk}` : ''
    const resWallet: any = await api.get(
      `/secretary-and-acceptor/balance/today/${code}?paymentMethod.equals=WALLET${timeParam}${cashdeskParam}`
    )
    setTotalWallet(resWallet.data)
    const resCash: any = await api.get(
      `/secretary-and-acceptor/balance/today/${code}?paymentMethod.equals=CASH${timeParam}${cashdeskParam}`
    )
    setTotalCash(resCash.data)
    const resPos: any = await api.get(
      `/secretary-and-acceptor/balance/today/${code}?paymentMethod.equals=POS${timeParam}${cashdeskParam}`
    )
    setTotalPos(resPos.data)
    setTotal(resWallet.data + resCash.data + resPos.data)
    const res: any = await api.get(`/acceptor-roles/factors/${code}/today/TIP?${timeParam}${cashdeskParam}`)
    setTotalTip(res.ok ? res.data : '-')
    setInprogress(false)
  }

  const cashdeskIdentifier = () => {
    if (code === currentCashdesk?.acceptorCode) {
      return currentCashdesk?.identifier
    }
    return null
  }
  const cashdeskName = () => {
    if (code === currentCashdesk?.acceptorCode) {
      return currentCashdesk?.name
    }
    return null
  }

  useEffect(() => {
    if (acceptorCode) {
      setCode(acceptorCode)
    } else {
      setCode(acceptorContext.current.code)
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (code) {
      getBalance()
    }
  }, [code, fromTime, toTime, cashDesk]) //eslint-disable-line

  useEffect(() => {
    setCashDesk(currentCashdesk?.identifier)
  }, [currentCashdesk?.identifier]) //eslint-disable-line

  return (
    <div className='container px-0' id='cashDeskDaily'>
      <div className='p-4 customCardTitleSeparator d-flex flex-row align-items-center justify-content-between'>
        <span style={{ color: '#7B7B7B' }}>فروش روز</span>

        {!inprogress && (
          <IonIcon
            style={{ color: '#3a4cf0' }}
            icon={sync}
            onClick={(e) => getBalance()}
            className='m-0 p-0 pointer non-printable'
            title={'بروزرسانی'}
          />
        )}
      </div>

      <IonRow>
        <IonCol size={12}>
          {!switchable && cashdeskIdentifier() && (
            <IonLabel>
              <IonText color='tertiary' title={cashdeskName()} className='ion-padding-end'>
                {cashdeskIdentifier()}
              </IonText>
              {cashdeskName()}
            </IonLabel>
          )}
          {switchable && !!code && <CashdeskSelect acceptorCode={code} onChange={(value) => setCashDesk(value)} />}
        </IonCol>

        <div className='col-12 py-3 px-3 px-0 '>
          <div className='col-12  '>
            {hasWaiter && (
              <div className='row  mb-2'>
                <div className='col' style={{ color: '#7B7B7B' }}>
                  {'جمع انعام'}
                </div>
                <div className='col pl-3 text-right print-only'>
                  {(totalTip || 0).toLocaleString(navigator.language)}
                </div>
                <div className='col pl-3 text-left non-printable'>
                  {(totalTip || 0).toLocaleString(navigator.language)}
                </div>
              </div>
            )}
            <div className='row mb-2'>
              <div className='col' style={{ color: '#7B7B7B' }}>
                {'فروش کیف پول'}
              </div>
              <div className='col pl-3 text-right print-only'>
                {(totalWallet || 0).toLocaleString(navigator.language)}
              </div>
              <div className='col pl-3 text-left non-printable'>
                {(totalWallet || 0).toLocaleString(navigator.language)}
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col' style={{ color: '#7B7B7B' }}>
                {'فروش کارتخوان'}
              </div>
              <div className='col pl-3 text-right print-only'>{(totalPos || 0).toLocaleString(navigator.language)}</div>
              <div className='col pl-3 text-left non-printable'>
                {(totalPos || 0).toLocaleString(navigator.language)}
              </div>
            </div>
            <div className='row pb-2 customCardTitleSeparator'>
              <div className='col' style={{ color: '#7B7B7B' }}>
                {'فروش نقدی'}
              </div>
              <div className='col pl-3 text-right print-only'>
                {(totalCash || 0).toLocaleString(navigator.language)}
              </div>
              <div className='col pl-3 text-left non-printable'>
                {(totalCash || 0).toLocaleString(navigator.language)}
              </div>
            </div>

            <div className='row pt-1'>
              <div className='col'>{'فروش کل'}</div>
              <div className='col pl-3 text-right print-only'>{(total || 0).toLocaleString(navigator.language)}</div>
              <div className='col pl-3 text-left non-printable'>{(total || 0).toLocaleString(navigator.language)}</div>
            </div>
          </div>
        </div>
      </IonRow>
    </div>
  )

  // return (
  //   <div className='container' id='cashDeskDaily'>
  //
  //     <div className='p-4 customCardTitleSeparator d-flex flex-row align-items-center justify-content-between'>
  //       <span>فروش روز</span>
  //
  //       {!inprogress && (
  //         <IonIcon
  //           style={{color:'#3a4cf0'}}
  //           icon={sync}
  //           onClick={(e) => getBalance()}
  //           className='m-0 p-0 pointer non-printable'
  //           title={'بروزرسانی'}
  //         />
  //       )}
  //     </div>
  //
  //     <IonRow>
  //       <IonCol>
  //         <IonItem>
  //           {!switchable && cashdeskIdentifier() && (
  //             <IonLabel>
  //               <IonText color='tertiary' title={cashdeskName()} className='ion-padding-end'>
  //                 {cashdeskIdentifier()}
  //               </IonText>
  //               {cashdeskName()}
  //             </IonLabel>
  //           )}
  //           {switchable && !!code && <CashdeskSelect acceptorCode={code} onChange={(value) => setCashDesk(value)} />}
  //         </IonItem>
  //       </IonCol>
  //       <IonCol size={2} className='ion-text-end'>
  //         {!inprogress && (
  //           <IonIcon
  //             icon={sync}
  //             onClick={(e) => getBalance()}
  //             className='ion-left mb-2 ml-3 pointer non-printable'
  //             title={'بروزرسانی'}
  //             size='large'
  //           />
  //         )}
  //       </IonCol>
  //     </IonRow>
  //     {hasWaiter && (
  //       <div className='row mb-2'>
  //         <div className='col'>{'جمع انعام'}</div>
  //         <div className='col pl-3 text-right print-only'>{(totalTip || 0).toLocaleString(navigator.language)}</div>
  //         <div className='col pl-3 text-left non-printable'>{(totalTip || 0).toLocaleString(navigator.language)}</div>
  //       </div>
  //     )}
  //     <div className='row mb-2'>
  //       <div className='col'>{'فروش کیف پول'}</div>
  //       <div className='col pl-3 text-right print-only'>{(totalWallet || 0).toLocaleString(navigator.language)}</div>
  //       <div className='col pl-3 text-left non-printable'>{(totalWallet || 0).toLocaleString(navigator.language)}</div>
  //     </div>
  //     <div className='row mb-2'>
  //       <div className='col'>{'فروش کارتخوان'}</div>
  //       <div className='col pl-3 text-right print-only'>{(totalPos || 0).toLocaleString(navigator.language)}</div>
  //       <div className='col pl-3 text-left non-printable'>{(totalPos || 0).toLocaleString(navigator.language)}</div>
  //     </div>
  //     <div className='row mb-2'>
  //       <div className='col'>{'فروش نقدی'}</div>
  //       <div className='col pl-3 text-right print-only'>{(totalCash || 0).toLocaleString(navigator.language)}</div>
  //       <div className='col pl-3 text-left non-printable'>{(totalCash || 0).toLocaleString(navigator.language)}</div>
  //     </div>
  //     <hr />
  //     <div className='row'>
  //       <div className='col'>{'فروش کل'}</div>
  //       <div className='col pl-3 text-right print-only'>{(total || 0).toLocaleString(navigator.language)}</div>
  //       <div className='col pl-3 text-left non-printable'>{(total || 0).toLocaleString(navigator.language)}</div>
  //     </div>
  //   </div>
  // )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasWaiter: state.shop.hasWaiter,
    currentCashdesk: state.shop.currentCashdesk,
  }),
  component: CashDeskDaily,
})
