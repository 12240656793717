import React, { useState } from 'react'
import { IonButton } from '@ionic/react'
import AcceptorSelect from '../AcceptorSelect/AcceptorSelect'
import WalletPayerResponse from '../WalletPayerResponse/WalletPayerResponse'
import { useToast } from '../../../ayria'

const WalletPurchase = ({ onSelect, onCancel }) => {
  const toast = useToast()
  const [acceptor, setAcceptor] = useState()
  const [disabled, setDisabled] = useState(true)
  const handleOnSelect = (item) => {
    if (item.referralCode) {
      setAcceptor(item)
      setDisabled(false)
    } else {
      toast.error('فروشگاه فعال نیست')
    }
  }

  return (
    <div>
      <div className={'form-group'}>
        <label>{'به منظور پرداخت صورت حساب خود، لطفا نام فروشگاه مورد نظر را درج نمایید.'}</label>
        <AcceptorSelect onChange={(value) => setDisabled(true)} onSelect={(item) => handleOnSelect(item)} />
      </div>
      <IonButton disabled={disabled} onClick={(e) => onSelect(acceptor)}>
        شروع
      </IonButton>
      <br />
      <br />
      <WalletPayerResponse receiverType={'company'} />
    </div>
  )
}

export default WalletPurchase
