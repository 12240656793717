import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting'
import exportdata from 'highcharts/modules/export-data'
exporting(Highcharts)
exportdata(Highcharts)

const defaultOptions = {
  lang: {
    downloadCSV: 'دانلود CSV',
    downloadJPEG: 'دانلود JPEG',
    downloadPDF: 'دانلود PDF',
    downloadPNG: 'دانلود PNG',
    downloadSVG: 'دانلود SVG',
    downloadXLS: 'دانلود اکسل',
    exitFullscreen: 'خروج از تمام صفحه',
    noData: 'رکوردی وجود ندارد',
    printChart: 'پرینت',
    viewData: 'نمایش جدول',
    viewFullscreen: 'نمایش به صورت تمام صفحه'
  },
  chart: { type: 'line' },
  title: { text: '' },
  accessibility: {
    announceNewData: { enabled: true }
  },
  xAxis: { type: 'category' },
  yAxis: {
    title: { text: '', useHTML: true },
  },
  legend: { enabled: false },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS'
        ]
      }
    }
  },
  tooltip: { useHTML: true },
  labels: {
    useHTML: true
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: '',
      colorByPoint: true,
      useHTML: true,
    }
  ]
}

interface AyriachartProps {
  options: any;
}

const Ayriachart: React.FC<AyriachartProps> = ({ options }) => {
  const mergedOptions = {...defaultOptions, ...options}

  return <HighchartsReact highcharts={Highcharts} options={mergedOptions} />

}

export default Ayriachart
