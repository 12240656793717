import React, { useEffect, useState } from 'react'
import { IonItem, IonText, IonButton, IonLabel, IonTextarea } from '@ionic/react'
import { useToast, ApiService, Modal } from '../../ayria'
import WalletReceipt from '../../components/elements/WalletReceipt/WalletReceipt'

interface GroupPaymentRequestViewProps {
  requestId: number
  acceptorCode: string
}

const GroupPaymentRequestView: React.FC<GroupPaymentRequestViewProps> = ({ requestId, acceptorCode }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [showViewModal, setShowViewModal] = useState(false)
  const [walletEvent, setWalletEvent] = useState()

  const getItems = async () => {
    const res: any = await api.get(`/customer/group-transfers/instances/${acceptorCode}/${requestId}`)
    if (res.ok) {
      setItems(res.data)
    } else {
      toast.error(res)
    }
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <>
      {items?.walletEvents?.map((itm: any) => (
        <IonItem
          key={itm.balance + ' ' + itm.amount}
          onClick={() => {
            setWalletEvent(itm)
            setShowViewModal(true)
          }}>
          <IonLabel className='ion-text-wrap'>{itm.actionDescription}</IonLabel>
          <IonText className='ion-text-wrap' slot='end'>
            {itm.amount.toLocaleString(navigator.language)} {'ریال'}
          </IonText>
        </IonItem>
      ))}
      <IonItem>
        <IonLabel position='stacked'>{'توضیحات مبدا'}</IonLabel>
        <IonTextarea value={`${items?.walletEvents?.[0].description}`} disabled={true}></IonTextarea>
      </IonItem>
      <Modal
        showModal={showViewModal}
        toggle={setShowViewModal}
        title={'درخواست وجه'}
        onClosed={() => setShowViewModal(false)}
        content={<WalletReceipt walletEvent={walletEvent} />}
        actionButtons={<IonButton onClick={() => setShowViewModal(false)}>{'بستن'}</IonButton>}
      />
    </>
  )
}

export default GroupPaymentRequestView
