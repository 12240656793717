import React, { useState, useEffect } from 'react'
import { IonButton, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem } from '@ionic/react'
import { IonBadge, isPlatform, IonSpinner, IonLabel, IonRow, IonThumbnail } from '@ionic/react'
import { IonChip, IonItemDivider, IonText } from '@ionic/react'
import { addCircleSharp, addOutline, removeCircleSharp, cartOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { ApiService } from '../../../ayria'
import { connect, setKalaList, addKala, removeKala } from '../../../ayria'
import { KalaImage, useDebounce } from '../../../ayria'

declare var window: any
declare var document: any

interface StateProps {
  kalaList: any[]
}

interface OwnProps {
  merchant: any
  ionContentRef?: any
  scrollTop: number
}

interface DispatchProps {
  setKalaList: typeof setKalaList
  addKala: typeof addKala
  removeKala: typeof removeKala
}

interface MerchantProfileKalaProps extends OwnProps, StateProps, DispatchProps {}

const MerchantProfileKala: React.FC<MerchantProfileKalaProps> = ({
  merchant,
  kalaList,
  setKalaList,
  addKala,
  removeKala,
  ionContentRef,
  scrollTop,
}) => {
  const api = new ApiService()
  const history = useHistory()
  const [items, setItems] = useState<any>([])
  const [inprogress, setInprogress] = useState(false)
  const [orderedList, setOrderedList] = useState([])
  const [groups, setGroups] = useState([])
  const [groupIds, setGroupIds] = useState([])
  const [currentGroup, setCurrentGroup] = useState<string | null>(null)
  const [scrollPositions, setScrollPositions] = useState([])
  const [scrollNames, setScrollNames] = useState([])
  const dScrollTop = useDebounce(scrollTop, 100)
  const [showAcceptorName, setShowAcceptorName] = useState(false)

  const showShoppingCart = () => kalaList?.filter((k) => k.acceptorCode === +merchant.code).length > 0

  const getKalas = async () => {
    setInprogress(true)
    const res: any = await api.get(`/public/acceptors/kala/${merchant.code}`)
    if (res.ok) {
      setItems(res.data)
      const gNames = []
      const gIds = []
      res.data.map((item: any) => {
        if (!!item.groupId) {
          if (!gIds.includes(item.groupId)) {
            gIds.push(item.groupId)
            gNames.push(item.groupName)
          }
        }
        return item
      })
      setGroups(gNames)
      setGroupIds(gIds)
    }
    setInprogress(false)
  }

  const add = async (kala: any) => {
    await addKala(kala)
  }
  const addKalaPrice = async (kala: any, kalaPrice: any) => {
    const compKala = { ...kala, id: `${kala.id}-${kalaPrice.id}` }
    await addKala(compKala)
  }
  const remove = async (kala: any) => {
    await removeKala(kala)
  }
  const removeKalaPrice = async (kala: any, kalaPrice: any) => {
    const compKala = { ...kala, id: `${kala.id}-${kalaPrice.id}` }
    await removeKala(compKala)
  }
  const count = (kala: any) => {
    const exist = kalaList.find((x) => x.id === kala?.id)
    if (exist && exist?.qty > 0) {
      return exist.qty
    } else return 0
  }
  const countKalaPrice = (kala: any, kalaPrice: any) => {
    const exist = kalaList.find((x) => x.id === `${kala.id}-${kalaPrice.id}`)
    if (exist && exist?.qty > 0) {
      return exist.qty
    } else return 0
  }

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str
  }

  const initOrderedList = () => {
    const tmpList = []
    let counter = 0
    groupIds.map((id, idx) => {
      tmpList.push({ type: 'GROUP', id, name: groups[idx], idx: counter })
      counter++
      items
        .filter((itm: any) => itm.groupId === id)
        .map((itm: any) => {
          itm.type = 'KALA'
          tmpList.push(itm)
          counter++
          return itm
        })
      return id
    })
    setOrderedList(tmpList)
  }
  const hasNoGroup = () => {
    return items?.some((i: any) => !i.groupId)
  }

  const initScrollPositions = () => {
    const sPositions = []
    const sNames = []
    groups.map((g: string, i) => {
      const elem = document.getElementById(groupIds[i])
      if (!!elem) {
        sPositions.push(elem.offsetTop)
        sNames.push(groupIds[i])
      }
      return g
    })
    const elem = document.getElementById('others')
    if (!!elem) {
      sPositions.push(elem.offsetTop)
      sNames.push('others')
    }
    setScrollPositions(sPositions)
    setScrollNames(sNames)
  }

  const scrollToPoint = (elemId: string) => {
    setCurrentGroup(elemId)
    const elem = document.getElementById(elemId)
    if (!!elem) {
      ionContentRef?.current?.scrollToPoint(0, elem.offsetTop - 80, 300)
    }
  }

  useEffect(() => {
    if (!!groupIds && groupIds.length > 0) {
      initOrderedList()
      setTimeout(() => {
        initScrollPositions()
      }, 1000)
    }
  }, [groupIds]) // eslint-disable-line

  useEffect(() => {
    getKalas()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (scrollPositions.length > 0) {
      const closest = scrollPositions.reduce(function (prev, curr) {
        return Math.abs(curr - dScrollTop) < Math.abs(prev - dScrollTop) ? curr : prev
      })
      //console.log('scrollTop', dScrollTop, closest, scrollNames[scrollPositions.indexOf(closest)])
      setCurrentGroup(scrollNames[scrollPositions.indexOf(closest)])
      const elem = document.getElementById(`g_${scrollNames[scrollPositions.indexOf(closest)]}`)
      const hbar = document.getElementById('hscrollbar')
      hbar.scrollLeft += elem.offsetLeft
    }
  }, [dScrollTop]) // eslint-disable-line

  useEffect(() => {
    setShowAcceptorName(scrollTop > 240)
  }, [scrollTop])

  return (
    <div className='merchant-profile-kala'>
      {items.length > 0 && (
        <>
          <div id='hscrollbar'>
            {showAcceptorName && (
              <div
                style={{
                  backgroundColor: '#4e555a',
                  color: 'white',
                  width: '100%',
                }}
                className='ion-text-center'>
                {merchant?.acceptorName}
              </div>
            )}
            <div id='groups'>
              {groups.map((g, i) => (
                <IonChip
                  key={`group-${g}-${i}`}
                  color={currentGroup !== groupIds[i] ? 'light' : ''}
                  outline={false}
                  onClick={() => scrollToPoint(groupIds[i])}
                  id={`g_${groupIds[i]}`}>
                  <IonLabel color='dark'>{g}</IonLabel>
                </IonChip>
              ))}
              {hasNoGroup() && (
                <IonChip
                  key='others'
                  color={currentGroup !== 'others' ? 'light' : ''}
                  outline={false}
                  onClick={() => scrollToPoint('others')}
                  id='g_others'>
                  <IonLabel color='dark'>{'سایر'}</IonLabel>
                </IonChip>
              )}
            </div>
          </div>
        </>
      )}
      {orderedList.map((kala: any, i) => (
        <div key={`g_${kala.name}_${i}`} id={kala.id}>
          {kala.type === 'GROUP' && (
            <IonItemDivider name={kala.name}>
              <IonLabel>{kala.name}</IonLabel>
            </IonItemDivider>
          )}
          {kala.type === 'KALA' && (
            <IonItem key={kala.id} className='ion-no-padding ion-no-margin'>
              <IonGrid>
                <IonItem
                  className='ion-text-end'
                  onClick={() => history.push(`/kala/${merchant.referralCode}/${kala.id}${window.location.search}`)}>
                  <IonThumbnail style={{ width: '100px', height: '100px' }}>
                    <KalaImage kala={kala} width={'100px'} />
                  </IonThumbnail>
                  <IonLabel className='ion-text-wrap ion-padding-vertical ion-padding-start'>
                    <IonCardTitle>{kala.name}</IonCardTitle>
                    {!!kala.nameEn && <span>{kala.nameEn}</span>}
                    <p style={{ textAlign: 'justify' }}>{truncate(kala.description, 130)}</p>
                  </IonLabel>
                </IonItem>
                <>
                  {!!kala?.price && (
                    <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
                      {merchant.onlineSell && (
                        <IonCol size='6' className='ion-text-right'>
                          {count(kala) > 0 ? (
                            <div style={{ paddingRight: '12px' }}>
                              <IonIcon size='large' color='primary' icon={addCircleSharp} onClick={() => add(kala)} />
                              <IonBadge color='light' className='ion-padding' style={{ paddingBottom: '10px' }}>
                                {count(kala)}
                              </IonBadge>
                              <IonIcon
                                size='large'
                                color='primary'
                                icon={removeCircleSharp}
                                onClick={() => remove(kala)}
                              />
                            </div>
                          ) : (
                            <IonItem lines='none'>
                              <IonButton
                                expand='block'
                                fill='outline'
                                onClick={() => add(kala)}
                                color='primary'
                                disabled={kala.outOfStock}
                                style={{ minWidth: '90px', margin: '0' }}>
                                <IonIcon slot='start' icon={addOutline}></IonIcon>
                                {'افزودن'}
                              </IonButton>
                            </IonItem>
                          )}
                        </IonCol>
                      )}
                      <IonCol size={merchant.onlineSell ? '6' : '12'}>
                        <IonCardTitle className='ion-text-left'>
                          {kala?.price?.toLocaleString(navigator.language)} ریال{' '}
                        </IonCardTitle>
                      </IonCol>
                    </IonRow>
                  )}
                  {kala?.prices?.map((kp: any) => (
                    <IonRow key={kp.id}>
                      {merchant.onlineSell && (
                        <IonCol size='5' className='ion-text-right'>
                          {countKalaPrice(kala, kp) > 0 ? (
                            <div style={{ paddingRight: '12px' }}>
                              <IonIcon
                                size='large'
                                color='primary'
                                icon={addCircleSharp}
                                onClick={() => addKalaPrice(kala, kp)}
                              />
                              <IonBadge color='light' className='ion-padding' style={{ paddingBottom: '10px' }}>
                                {countKalaPrice(kala, kp)}
                              </IonBadge>
                              <IonIcon
                                size='large'
                                color='primary'
                                icon={removeCircleSharp}
                                onClick={() => removeKalaPrice(kala, kp)}
                              />
                            </div>
                          ) : (
                            <IonItem lines='none' style={{ width: '130px' }}>
                              <IonButton
                                expand='block'
                                fill='outline'
                                onClick={() => addKalaPrice(kala, kp)}
                                color='primary'
                                disabled={kala.outOfStock}
                                style={{ minWidth: '90px', margin: '0' }}>
                                <IonIcon slot='start' icon={addOutline}></IonIcon>
                                {'افزودن'}
                              </IonButton>
                            </IonItem>
                          )}
                        </IonCol>
                      )}
                      <IonCol size={merchant.onlineSell ? '7' : '12'} className='ion-text-end'>
                        <div className='ion-text-end'>
                          <IonText className='float-right ion-text-wrap' style={{ paddingTop: '10px' }}>
                            {kp?.name}
                          </IonText>
                          <IonText
                            className='float-left'
                            style={{ fontSize: '17px', fontWeight: 'bold', paddingTop: '10px' }}>
                            {kp?.price?.toLocaleString(navigator.language)} ریال{' '}
                          </IonText>
                        </div>
                      </IonCol>
                    </IonRow>
                  ))}
                </>
              </IonGrid>
            </IonItem>
          )}
        </div>
      ))}
      {hasNoGroup() && (
        <IonItemDivider name='others' key={'g-others'} id='others'>
          <IonLabel>{'سایر'}</IonLabel>
        </IonItemDivider>
      )}
      {hasNoGroup() &&
        items
          .filter((i: any) => !i.groupId)
          .map((kala: any) => (
            <IonItem key={kala.id} className='ion-no-padding ion-no-margin'>
              <IonGrid>
                <IonItem
                  lines='none'
                  onClick={() => history.push(`/kala/${merchant.referralCode}/${kala.id}${window.location.search}`)}>
                  <IonThumbnail style={{ width: '100px', height: '100px' }}>
                    <KalaImage kala={kala} width={'100px'} />
                  </IonThumbnail>
                  <IonLabel className='ion-text-wrap ion-padding'>
                    <IonCardTitle>{kala.name}</IonCardTitle>
                    <p style={{ textAlign: 'justify' }}>{truncate(kala.description, 110)}</p>
                  </IonLabel>
                </IonItem>
                {merchant.onlineSell && !!kala?.price && (
                  <IonRow className='ion-no-margin ion-no-padding ion-align-items-center'>
                    <IonCol size='6' className='ion-text-right'>
                      {showShoppingCart() && count(kala) > 0 ? (
                        <IonItem lines='none' style={{ padding: '0' }}>
                          <IonIcon size='large' color='primary' icon={addCircleSharp} onClick={() => add(kala)} />
                          <IonBadge color='light'>{count(kala)}</IonBadge>
                          <IonIcon size='large' color='primary' icon={removeCircleSharp} onClick={() => remove(kala)} />
                        </IonItem>
                      ) : (
                        <IonItem lines='none'>
                          <IonButton
                            expand='block'
                            fill='outline'
                            onClick={() => add(kala)}
                            color='primary'
                            disabled={kala.outOfStock}
                            style={{ minWidth: '90px', margin: '0' }}>
                            <IonIcon slot='start' icon={addOutline}></IonIcon>
                            {'افزودن'}
                          </IonButton>
                        </IonItem>
                      )}
                    </IonCol>

                    <IonCol size='6'>
                      <IonCardTitle className='ion-text-left'>
                        {kala?.price?.toLocaleString(navigator.language)} ریال{' '}
                      </IonCardTitle>
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </IonItem>
          ))}
      {items.length === 0 && !inprogress && (
        <div className='text-center ion-padding-top'>
          <div className='alert alert-dark'>{'در حال حاضر کالا و یا خدماتی تعریف نشده است.'}</div>
        </div>
      )}
      {items.length === 0 && inprogress && <IonSpinner name='crescent' color='dark' />}
      <div>
        <br />
      </div>
      {showShoppingCart() && (
        <div
          className='btn-group-fab'
          style={{ right: 'auto', left: '20px', bottom: isPlatform('ios') ? '50px' : '0px', width: '90%' }}>
          <div>
            <IonButton
              color='shopping'
              expand='block'
              onClick={() => history.push(`/shopping-cart${window.location.search}`)}>
              {'ادامه فرآیند خرید'}
              <IonIcon icon={cartOutline} />
              <IonBadge color='danger'>
                {kalaList
                  .filter((k) => k.acceptorCode === +merchant.code)
                  .reduce((a: number, b: any) => +a + +b.qty, 0)}
              </IonBadge>
            </IonButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapDispatchToProps: {
    setKalaList,
    addKala,
    removeKala,
  },
  mapStateToProps: (state) => ({
    kalaList: state.user.kalaList,
  }),
  component: MerchantProfileKala,
})
