import React, { useState, useEffect } from 'react'
import './DepartmentSelector.scss'

const DepartmentSelector: React.FC = ({ onSelectDept }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [deps, setDeps] = useState<any>([])

  const tapSlideIndex = (id: number) => {
    setSlideIndex(id)
    onSelectDept(id)
  }

  useEffect(() => {
    setDeps([
      'ALL',
      'RESTAURANT',
      'STORE',
      'CLOTHING',
      'CONSULTING',
      'ART',
      'BEAUTY',
      'CULTURE',
      'DIGITAL',
      'FORMALITY',
      'HEALTH',
      'HOME_APPLIANCE',
      'JEWELLERY',
      'SCIENCE',
      'SPORT',
      'TRAVEL',
    ])
  }, []) // eslint-disable-line

  return (
    <div className='scrollMenu p-0 m-2 pb-3'>
      {deps.map((dep: string, idx: number) => (
        <>
          <img
            alt=''
            data-src={`/assets/images/deps/DEP_${dep}.png`}
            className={'dep-logo lazyload ml-4'}
            hidden={idx === slideIndex}
            width='58px'
            height='58px'
            onClick={() => {
              tapSlideIndex(idx)
            }}
          />
          <img
            alt=''
            data-src={`/assets/images/deps/DEP_${dep}_T.png`}
            className={'dep-logo lazyload ml-4'}
            hidden={idx !== slideIndex}
            width='58px'
            height='58px'
          />
        </>
      ))}
    </div>
  )
}

export default DepartmentSelector
