import React, { useEffect, useState } from 'react'
import { IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner, IonItem, IonThumbnail } from '@ionic/react'
import { IonChip, IonLabel, IonIcon, IonButton, IonPage, IonText } from '@ionic/react'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, useIonViewWillEnter } from '@ionic/react'
import { people, storefront } from 'ionicons/icons'
import AcceptorList from '../home/AcceptorList'
import { ApiService, useToast, CDN_BASE_URL, loadMoreDataWhenScrolled } from '../../ayria'
import { ScrollContent, Header, User, connect, Modal, setLoggedUser } from '../../ayria'
import { setHasSeenClubList, parseHeaderForLinks } from '../../ayria'
import { isDesktop } from '../../utility'

interface StateProps {
  loggedUser?: User
}
interface DispatchProps {
  setLoggedUser: typeof setLoggedUser
  setHasSeenClubList: typeof setHasSeenClubList
}
interface ClubListProps extends StateProps, DispatchProps {}

export const ClubList: React.FC<ClubListProps> = ({ loggedUser, setLoggedUser, setHasSeenClubList }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({ itemsPerPage: 10, sort: 'createdDate', order: 'desc', activePage: 1 })
  const [fetch, setFetch] = useState(true)
  const [inprogress, setInprogress] = useState(false)
  const [joinInprogress, setJoinInprogress] = useState(false)
  const [links, setLinks] = useState<any>({})
  const [total, setTotal] = useState(1)
  const [joinedClubs, setJoinedClubs] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState()

  const getItems = async (page: number, size: number) => {
    setInprogress(true)
    setFetch(false)
    const res: any = await api.get(`/user/club/clubs?page=${page}&size=${size}`)
    if (res.ok) {
      setLinks(parseHeaderForLinks(res.headers.link))
      setItems(loadMoreDataWhenScrolled(items, res.data))
      setTotal(res.headers['x-total-count'])
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const getJoinedClubs = async () => {
    const res: any = await api.get(`/user/club/joined`)
    if (res.ok) {
      setJoinedClubs(res.data)
    }
  }
  const join = async (club: any) => {
    setJoinInprogress(true)
    const res: any = await api.userClubJoin(club.code)
    if (res.ok) {
      setShowModal(false)
      if (res.status === 201) {
        toast.success(`شما با موفقیت عضو ${club.name} شدید`)
      } else {
        toast.success(`${club.name} باشگاه منتخب شما شد`)
      }
      const res2: any = await api.get('/account')
      if (res2.ok) {
        await setLoggedUser(res2.data)
      }
      await getJoinedClubs()
    } else {
      toast.error(res)
    }
    setJoinInprogress(false)
  }

  const handleLoadMore = () => {
    if (!fetch) {
      setPagination({ ...pagination, activePage: pagination.activePage + 1 })
    }
  }
  const isCurrentClub: boolean = (code: number) => {
    return loggedUser?.clubCode === code
  }
  const isMember: boolean = (code: number) => {
    return joinedClubs.find((c) => c.clubCode === code)
  }

  useIonViewWillEnter(() => {
    setHasSeenClubList(true)
  })

  useEffect(() => {
    setFetch(true)
  }, [pagination.activePage]) // eslint-disable-line

  useEffect(() => {
    if (fetch && items?.length < total) {
      getItems(pagination.activePage - 1, pagination.itemsPerPage)
    }
  }, [fetch]) // eslint-disable-line

  useEffect(() => {
    getJoinedClubs()
  }, []) // eslint-disable-line

  return (
    <IonPage id='online-order-thread-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <ScrollContent>
        <Header showBackButton={false} />
        <div className='ion-padding' style={{ paddingLeft: 0, paddingRight: 0 }}>
          {items.map((itm: any) => (
            <IonItem
              key={itm.code}
              onClick={() => {
                setItem(itm)
                setShowModal(true)
              }}>
              <IonThumbnail slot='start' color={isCurrentClub(itm.code) ? 'success' : ''}>
                {!!itm?.logoPath && <img src={`${CDN_BASE_URL}/${itm.logoPath}`} alt={''} crossOrigin='anonymous' />}
              </IonThumbnail>
              <IonLabel>
                {itm.name}
                <p>
                  {itm.description}
                  <br />
                  <span className='ion-text-end' style={{ display: 'block' }}>
                    <IonText className='ion-text-end'>
                      <IonButton
                        fill='clear'
                        size='small'
                        color={isMember(itm.code) ? (isCurrentClub(itm.code) ? 'success' : 'primary') : 'dark'}>
                        <IonIcon icon={storefront} slot='end' />
                        {itm.acceptors}
                      </IonButton>
                      <IonButton
                        fill='clear'
                        size='small'
                        color={isMember(itm.code) ? (isCurrentClub(itm.code) ? 'success' : 'primary') : 'dark'}>
                        <IonIcon icon={people} slot='end' />
                        {itm.members}
                      </IonButton>
                    </IonText>
                  </span>
                </p>
              </IonLabel>
            </IonItem>
          ))}
          {!!inprogress && <IonSpinner name='crescent' color='dark' />}
          {items.length === 0 && !inprogress && (
            <div className='ion-margin-top'>
              <IonChip style={{ width: '100%' }}>{'رکوردی وجود ندارد'}</IonChip>
            </div>
          )}
          <IonInfiniteScroll
            threshold='100px'
            disabled={pagination.activePage - 1 >= links?.last || fetch}
            onIonInfinite={() => handleLoadMore()}>
            <IonInfiniteScrollContent loadingText={'باشگاه‌های بیشتر'}></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </div>
        <Modal
          showModal={showModal}
          toggle={setShowModal}
          onClosed={() => setShowModal(false)}
          title={`${item?.name}`}
          contentUsePadding={false}
          content={
            <>
              <IonCard>
                {(!!item?.bannerPath || !!item?.logoPath || !!item?.smallLogoPath) && (
                  <img
                    src={`${CDN_BASE_URL}/${
                      !!item?.bannerPath
                        ? item?.bannerPath
                        : !!item?.logoPath
                        ? item?.logoPath
                        : !!item?.smallLogoPath
                        ? item?.smallLogoPath
                        : ''
                    }`}
                    alt=''
                    crossOrigin='anonymous'
                  />
                )}
                <IonCardHeader>
                  <IonCardTitle>{item?.name}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {item?.description}
                  <IonItem lines='none'>
                    <IonButton
                      fill='clear'
                      size='small'
                      color={isMember(item?.code) ? (isCurrentClub(item?.code) ? 'success' : 'primary') : 'dark'}
                      slot='end'>
                      <IonIcon icon={storefront} slot='end' />
                      {item?.acceptors}
                    </IonButton>
                    <IonButton
                      fill='clear'
                      size='small'
                      color={isMember(item?.code) ? (isCurrentClub(item?.code) ? 'success' : 'primary') : 'dark'}
                      slot='end'>
                      <IonIcon icon={people} slot='end' />
                      {item?.members}
                    </IonButton>
                  </IonItem>
                </IonCardContent>
              </IonCard>
              <AcceptorList savedClub={item} rowClicked={() => setShowModal(false)} hideDepartments={true} />
            </>
          }
          actionButtons={
            <>
              {!isMember(item?.code) && (
                <IonButton onClick={() => join(item)} disabled={joinInprogress} color='primary'>
                  {joinInprogress && <IonSpinner slot='start' />} {'عضویت'}
                </IonButton>
              )}
              {isMember(item?.code) && !isCurrentClub(item?.code) && (
                <IonButton onClick={() => join(item)} disabled={joinInprogress} color='primary'>
                  {joinInprogress && <IonSpinner slot='start' />} {'باشگاه منتخب'}
                </IonButton>
              )}
              <IonButton color='dark' onClick={() => setShowModal(false)}>
                {'بستن'}
              </IonButton>
            </>
          }
        />
      </ScrollContent>
    </IonPage>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    loggedUser: state.user.loggedUser,
  }),
  mapDispatchToProps: {
    setLoggedUser,
    setHasSeenClubList,
  },
  component: ClubList,
})
