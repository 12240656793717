import React from 'react'
import CurrencyIonInput from '../CurrencyInput/CurrencyIonInput'

const NumberIonInput = ({ onValueChange, componentDidMount = () => {}, ...inputProps }) => {
  return (
    <CurrencyIonInput
      maskOptions={{ includeThousandsSeparator: false, allowLeadingZeroes: true, allowNegative: false }}
      placeholder={'0'}
      componentDidMount={componentDidMount}
      onValueChange={onValueChange}
      {...inputProps}
    />
  )
}

export default NumberIonInput
