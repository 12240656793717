import React from 'react'
import { IonContent, IonLabel, IonPage } from '@ionic/react'
import { Header } from '../ayria'
import VirtualShopQRCode from '../components/units/VirtualShopQRCode'
import { useParams } from 'react-router'
import { isDesktop } from '../utility'

interface VirtualShopQrPageProps {}

const VirtualShopQrPage: React.FC<VirtualShopQrPageProps> = () => {
  const { acceptorReferralCode } = useParams<any>()

  return (
    <IonPage id='virtualshopqr-tab-page' className={isDesktop() ? 'marginHorizontalAuto' : ''}>
      <IonContent>
        <Header />
        <hr />
        <div>
          <IonLabel className=' ion-text-center ion-text-justify'>
            <p className='ion-margin ion-padding'>
              با اسکن این QR CODE افراد می توانند لیست کالا و خدمات شما را مشاهده نمایند . چنانچه سرویس فروش آنلاین شما
              فعال باشد از همینجا امکان فروش کالا و خدمات را نیز خواهید داشت.
            </p>
          </IonLabel>
        </div>
        <div className='ion-padding ion-text-center'>
          <br />
          <VirtualShopQRCode acceptorReferralCode={acceptorReferralCode} showLink={true} />
        </div>
      </IonContent>
    </IonPage>
  )
}
export default VirtualShopQrPage
