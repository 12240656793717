import React, { useState, useEffect } from 'react'
import { IonList, IonItem, IonLabel, IonButton, IonIcon } from '@ionic/react'
import { IonChip, IonSpinner } from '@ionic/react'
import { arrowUp, arrowDown } from 'ionicons/icons'
import { ApiService, useToast, API_BASE_URL } from '../../ayria'
import './WalletThread.scss'

// const mock = {
//   date: 'تارییییخ',
//   gender: 'FEMALE',
//   name: 'طاهاااا',
//   actionType: 'DEPOSIT',
//   amount: 300,
// }
const WalletThread = () => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [inprogress, setInprogress] = useState(false)
  const getItems = async () => {
    setInprogress(true)
    const res: any = await api.userWalletThread()
    if (res.ok) {
      setItems(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  useEffect(() => {
    getItems()
  }, []) // eslint-disable-line

  return (
    <>
      <div>
        {items.map((item: any, ind: number) => (
          <IonItem key={ind} onClick={() => toast.warning('سابقه تراکنش ها به زودی اضافه خواهد شد')}>
            <div slot='start'>
              <img
                height='70'
                width='70'
                src={
                  item.type === 'ACCEPTOR'
                    ? `${API_BASE_URL}/public/acceptors/logo/${item.code}`
                    : item.gender === 'FEMALE'
                    ? `/assets/images/profile/female.jpeg`
                    : `/assets/images/profile/male.jpeg`
                }
                alt='icon'
              />
            </div>
            <IonLabel className='p-0'>
              <div className='p-0 col-12 d-flex flex-row align-items-center justify-content-between'>
                <span>{item.name.slice(0, 15) + (item.name.length > 15 ? '...' : '')}</span>

                <p className='dir-ltr text-left'>
                  <span className='float-left text-left'>
                    <span style={{ fontSize: 11 }} className='px-2'>
                      {item.date}
                    </span>
                    <br />
                    <IonButton
                      className='p-0 text-left'
                      fill='clear'
                      color={item.actionType === 'DEPOSIT' ? 'success' : 'dark'}
                      size='small'>
                      <span className='float-left'>{'ریال'}</span>
                      {item.amount?.toLocaleString(navigator.language)}
                      <IonIcon
                        // className="p-0 m-0"
                        slot='start'
                        icon={item.actionType === 'DEPOSIT' ? arrowDown : arrowUp}
                        color={item.actionType === 'DEPOSIT' ? 'success' : 'dark'}
                      />
                    </IonButton>
                  </span>
                </p>
              </div>
            </IonLabel>
          </IonItem>
        ))}
      </div>
      {!!inprogress && <IonSpinner name='crescent' color='dark' />}
      {items.length === 0 && !inprogress && <IonChip style={{ width: '100%' }}>{'رکوردی وجود ندارد'}</IonChip>}
    </>
  )
}

export default WalletThread
