import React, { FC, useEffect, useState } from 'react'
import { ApiService } from '../../ayria'
import { toGregorian } from '../../utility/calendar'
import { AyriaPagination, getSortState, isDesktop, isMobile, ItemCount, ITEMS_PER_PAGE, useToast } from '../../utility'
import DatePicker from 'react-modern-calendar-datepicker'
import { IonCol, IonRow } from '@ionic/react'
import VoucherBalance from './VoucherBalance'

interface OwnProps {
  code?: string
  hideBalance?: boolean
  type?: string
}
interface VoucherEventsProps extends OwnProps {}

const VoucherEvents: FC<VoucherEventsProps> = ({ code, hideBalance = false, type = 'user' }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState<any>(getSortState(window.location, ITEMS_PER_PAGE, 'id'))
  const [totalItems, setTotalItems] = useState<any>(0)
  const [fromDate, setFromDate] = useState<any>(null)
  const [toDate, setToDate] = useState<any>(null)

  const fetchItems = async (page: number, size: number, sort: string) => {
    const res: any = await api.get(
      `/user/voucher/${!!code ? code : '0'}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${
        fromDate ? `&fromDate=${toGregorian(fromDate)}` : ''
      }${toDate ? `&toDate=${toGregorian(toDate)}` : ''}`
    )
    if (res.ok) {
      setItems(res.data)
      setTotalItems(res.headers['x-total-count'])
    } else {
      toast.error(res)
    }
  }

  const getAllItems = () =>
    fetchItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  const sortEvents = () => getAllItems()

  useEffect(() => {
    pagination.sort = 'createdDate'
    pagination.order = 'desc'
    getAllItems()
  }, []) // eslint-disable-line

  useEffect(() => {
    sortEvents()
    // eslint-disable-next-line
  }, [pagination.activePage, pagination.order, pagination.sort, fromDate, toDate, code])

  const handlePagination = (currentPage: number) => {
    setPagination({
      ...pagination,
      activePage: currentPage,
    })
  }

  return (
    <div>
      {!hideBalance && (
        <>
          <VoucherBalance code={code} type={type} />
          <br />
        </>
      )}
      <div className='row'>
        <div className='form-group col-6 text-left'>
          <DatePicker
            value={fromDate}
            onChange={setFromDate}
            inputPlaceholder='از تاریخ'
            shouldHighlightWeekends
            locale='fa'
            calendarPopperPosition={'bottom'}
          />
        </div>
        <div className='form-group col-6'>
          <DatePicker
            value={toDate}
            onChange={setToDate}
            inputPlaceholder='تا تاریخ'
            shouldHighlightWeekends
            locale='fa'
            calendarPopperPosition={'bottom'}
          />
        </div>
      </div>
      <div>
        {isDesktop() && (
          <IonRow>
            <IonCol>
              <b>{'توضیحات'}</b>
            </IonCol>
            <IonCol size='2'>
              <b>{'تاریخ'}</b>
            </IonCol>
            <IonCol className='ion-text-end' size='1'>
              <b>{'واریز'}</b>
            </IonCol>
            <IonCol className='ion-text-end' size='2'>
              <b>{'برداشت'}</b>
            </IonCol>
            <IonCol className='ion-text-end' size='1'>
              <b>{'مانده'}</b>
            </IonCol>
          </IonRow>
        )}
        {items.map((itm: any, index: number) => {
          return (
            <div key={itm.createdDate + '-' + index}>
              {isMobile() && (
                <div className='flex-container border-bottom pointer'>
                  <span className='flex-3'>
                    <p className='ellipsis m-0'>
                      {itm.sourceDescription ? itm.sourceDescription : itm.source.toLowerCase()}
                      <br />
                      {itm.createdDate}
                    </p>
                  </span>
                  <span className='flex-1 text-left'>
                    {itm.amount}
                    {itm.type === 'WITHDRAW' && <span className={'text-danger'}>-</span>}
                  </span>
                </div>
              )}
              {isDesktop() && (
                <IonRow key={itm.createdDate + '-' + index} style={{ borderBottom: '0.5px solid #cccccc' }}>
                  <IonCol>{itm.sourceDescription ? itm.sourceDescription : itm.source.toLowerCase()}</IonCol>
                  <IonCol size='2'>{itm.createdDatePersian}</IonCol>
                  <IonCol size='1' className='ion-text-end text-success'>
                    {itm.voucherType === 'DEPOSIT' ? itm.amount.toLocaleString(navigator.language) : ''}{' '}
                  </IonCol>
                  <IonCol size='2' className='ion-text-end'>
                    {itm.voucherType === 'WITHDRAW' ? (
                      <>
                        {itm.amount.toLocaleString(navigator.language)}
                        <span className='text-danger'>-</span>
                      </>
                    ) : (
                      ''
                    )}{' '}
                  </IonCol>
                  <IonCol size='1' className='ion-text-end'>
                    {itm.balance.toLocaleString(navigator.language)}
                  </IonCol>
                </IonRow>
              )}
            </div>
          )
        })}
      </div>
      <div className='row text-center'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={totalItems}
          />
        </div>
      </div>
    </div>
  )
}

export default VoucherEvents
