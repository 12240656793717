import React, { useState, useEffect } from 'react'
import { IonList, IonItem, IonItemDivider, IonButton, IonIcon } from '@ionic/react'
import { IonText, IonInput, IonRow, IonCol } from '@ionic/react'
import { copy } from 'ionicons/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ApiService, useToast, AyriaPagination, ItemCount } from '../../ayria'
import { MobileIonInput } from '../../ayria'
import './MyClub.scss'
import ClubMemberCard from './ClubMemberCard'
import { isDesktop } from '../../utility'
import { DASHBOARD_URL } from '../../config'

interface OwnProps {
  code: string
}
interface MyClubMembersProps extends OwnProps {}

const MyClubMembers: React.FC<MyClubMembersProps> = ({ code }) => {
  const api = new ApiService()
  const toast = useToast()
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    itemsPerPage: 5,
    sort: 'clubNumber',
    order: 'desc',
    activePage: 1,
  })
  const [total, setTotal] = useState(0)
  const [mobile, setMobile] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()

  const getItems = async (page: number, size: number, sort: string) => {
    const res: any = await api.userClubGetMembers(
      code,
      `?page=${page}&size=${size}&sort=${sort}${!!mobile ? `&mobile.contains=${mobile}` : ''}${
        !!firstName ? `&firstName.contains=${firstName}` : ''
      }${!!lastName ? `&lastName.contains=${lastName}` : ''}`
    )
    if (res.ok) {
      setItems(res.data)
      setTotal(res.headers['x-total-count'])
    } else {
      toast.error(res)
    }
  }
  const sortItems = async () => {
    getItems(pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`)
  }

  const handlePagination = (currentPage: number) => {
    setPagination({ ...pagination, activePage: currentPage })
  }

  useEffect(() => {
    sortItems()
  }, [pagination.activePage, pagination.order, pagination.sort, mobile, firstName, lastName]) // eslint-disable-line

  return (
    <div id='my-club-members'>
      <IonList>
        <IonItemDivider>
          {'اعضای باشگاه شما'}{' '}
          <IonText className='ion-padding-start' color='success'>
            {total}
          </IonText>
          <CopyToClipboard text={`${DASHBOARD_URL}/s/${code}`} onCopy={() => toast.success('کپی شد!')}>
            <IonButton slot='end'>
              <IonIcon slot='start' icon={copy} />
              {'دعوت از عضو جدید'}
            </IonButton>
          </CopyToClipboard>
        </IonItemDivider>
        <IonItem>
          <MobileIonInput
            value={mobile}
            onValueChange={(value: any) => setMobile(value)}
            debounce={1000}
            placeholder={'جستجو با شماره همراه عضو'}
          />
        </IonItem>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonInput
                value={firstName}
                onIonChange={(e: any) => setFirstName(e.detail.value)}
                placeholder={'نام'}
                debounce={1000}
              />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonInput
                value={lastName}
                onIonChange={(e: any) => setLastName(e.detail.value)}
                placeholder={'نام خانوادگی'}
                debounce={1000}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        {items.map((cm: any, index: number) => (
          <div key={cm.clubNumber + ' ' + index}>
            <ClubMemberCard item={cm} />
          </div>
        ))}
      </IonList>
      <div className='row text-center ion-padding'>
        <div className='col'>
          <ItemCount page={pagination.activePage} total={total} itemsPerPage={pagination.itemsPerPage} />
        </div>
      </div>
      <div className='row text-center'>
        <div className='col'>
          <AyriaPagination
            activePage={pagination.activePage}
            onSelect={handlePagination}
            maxButtons={isDesktop() ? 5 : 3}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={total}
          />
        </div>
      </div>
    </div>
  )
}

export default MyClubMembers
