import React, { useState, useEffect, useContext, useRef } from 'react'
import { IonPopover, IonButton, IonIcon } from '@ionic/react'
import { IonList, IonItem, IonItemDivider } from '@ionic/react'
import { personCircleOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Auth from '../../../utility/auth/Auth'
import './profile-drop-down.css'
import { IonBadge } from '@ionic/react'
import { AcceptorContext, connect, setAcceptorCode } from '../../../ayria'
import { setLastAyriaPayment } from '../../../ayria'

interface OwnProps {
  acceptors?: any[]
}
interface StateProps {
  hasSeenRegisterClub?: boolean
}
interface DispatchProps {
  setAcceptorCode: typeof setAcceptorCode
  setLastAyriaPayment: typeof setLastAyriaPayment
}
interface ProfileDropDownAcceptorProps extends StateProps, OwnProps, DispatchProps {}

const ProfileDropDownAcceptor: React.FC<ProfileDropDownAcceptorProps> = ({
  acceptors,
  hasSeenRegisterClub,
  setAcceptorCode,
  setLastAyriaPayment,
}) => {
  const auth = new Auth()
  const history = useHistory()
  const { t } = useTranslation()
  const [acceptorContext, setAcceptorContext] = useContext<any>(AcceptorContext)
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined })
  const popoverRef = useRef<any>()

  const handleLogOff = async (event: any) => {
    event.preventDefault()
    const res = await auth.logout()
    if (res) {
      setAcceptorContext((state: any) => ({}))
      setShowPopover({ showPopover: false, event })
      popoverRef?.current?.dismiss()
      history.push('/login-acceptor')
    }
  }
  const changeCurrentAcceptor = async (acceptor: any, index: any) => {
    setAcceptorContext((state: any) => ({ ...state, currentIndex: index, current: acceptorContext.all[index] }))
    localStorage.setItem(`CURRENT_ACCEPTOR_INDEX_${auth.getLoggedInUser().login}`, index)
    await setAcceptorCode(acceptorContext.all[index].code)
    await setLastAyriaPayment(undefined)
    setTimeout(() => {
      window.location.reload()
    }, 300)
  }

  useEffect(() => {
    document.title = t('label.platform')
  }, []) // eslint-disable-line

  return (
    <>
      {/*{' '}*/}
      {/*{acceptorContext.current && (*/}
      {/*  <div*/}
      {/*    className={'img-circle pointer profile-image right ' + (isMobile() ? ' pt-2' : 'pt-1')}*/}
      {/*    onClick={() => history.push('/panel')}>*/}
      {/*    <img*/}
      {/*      alt=''*/}
      {/*      src={`${API_BASE_URL}/public/acceptors/logo/${acceptorContext.current.code}`}*/}
      {/*      style={{ width: '40px' }}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      <IonPopover
        ref={popoverRef}
        className='my-custom-class'
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
        <IonList>
          <IonItem button onClick={() => history.push(`/myclubs/${acceptorContext?.current?.referralCode}`)}>
            {'باشگاه من'}
            {!hasSeenRegisterClub && (
              <IonBadge color='danger' slot='end'>
                {'جدید'}
              </IonBadge>
            )}
          </IonItem>
          {acceptorContext.current && (
            <div>
              <IonItemDivider>{'فروشگاه : '}</IonItemDivider>
              <IonItem className='text-success acceptor-item'>{acceptorContext.current.acceptorName}</IonItem>
              {acceptorContext.all.map(
                (acceptor: any, index: any) =>
                  acceptorContext.current.id !== acceptor.id && (
                    <IonItem
                      className='acceptor-item'
                      button
                      lines='none'
                      onClick={(e) => changeCurrentAcceptor(acceptor, index)}
                      key={acceptor.id}>
                      {acceptor.acceptorName}
                    </IonItem>
                  )
              )}
            </div>
          )}
          <IonItemDivider />
          {auth.getLoggedInUser()?.authorities?.includes('ROLE_ACCEPTOR_SECRETARY') && (
            <>
              <IonItem button href={`/secretary-sell/${acceptorContext?.current?.code}`} className='text-info'>
                {'صندوق فروشگاه'}
              </IonItem>
            </>
          )}
          {auth.getLoggedInUser()?.authorities?.includes('ROLE_DELIVERY') && (
            <>
              <IonItem button href={`/delivery/${acceptorContext?.current?.code}`} className='text-info'>
                {'پیک فروشگاه'}
              </IonItem>
            </>
          )}
          <IonItem button href={'/dashboard'} className='text-info'>
            {'بازگشت به کاربری شخصی'}
          </IonItem>
          <IonItem button className={'text-danger'} onClick={handleLogOff} lines='none'>
            {t('profileDropdown.logoff')}
          </IonItem>
          <IonItem lines='none'>{`نسخه ${process.env.REACT_APP_VERSION}`}</IonItem>
        </IonList>
      </IonPopover>

      <IonButton
        fill='clear'
        className='mt-2'
        onClick={(e: any) => {
          e.persist()
          setShowPopover({ showPopover: true, event: e })
        }}>
        <IonIcon icon={personCircleOutline} />
        {/*<p className='ellipsis m-0' style={{ maxWidth: isDesktop() ? '150px' : '70px' }}>*/}
        {/*  {acceptorContext.current && acceptorContext.current.acceptorName}*/}
        {/*</p>*/}
        {/*{!popoverState.showPopover && <AyriaIcon slot='end' icon={caretBack} />}*/}
        {/*{popoverState.showPopover && <AyriaIcon slot='end' icon={caretDown} />}*/}
      </IonButton>
    </>
  )
}

ProfileDropDownAcceptor.propTypes = { acceptors: PropTypes.array }

ProfileDropDownAcceptor.defaultProps = { acceptors: [] }

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasSeenRegisterClub: state.user.hasSeenRegisterClub,
  }),
  mapDispatchToProps: {
    setAcceptorCode,
    setLastAyriaPayment,
  },
  component: ProfileDropDownAcceptor,
})
