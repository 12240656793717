import React, { useEffect, useState } from 'react'
import Autocomplete from 'react-autocomplete'
import { ApiService, useDebounce } from '../../../ayria'
import '../../../App-deprecated.scss'

const AcceptorSelect = ({ onChange, onSelect }) => {
  const api = new ApiService()
  const [value, setValue] = useState()
  const debouncedSearchTerm = useDebounce(value, 500)
  const [merchants, setMerchants] = useState([])

  const search = async (searchTerm) => {
    const res = await api.get(`/user/acceptor/search?status.equals=ACTIVE&acceptorName.contains=${debouncedSearchTerm}`)
    if (res.ok) {
      setMerchants(res.data)
    }
  }

  const handleOnChange = (event) => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  const handleOnSelect = (value, item) => {
    setValue(item.acceptorName)
    onSelect(item)
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      search(debouncedSearchTerm)
    } else {
      setMerchants([])
    }
  }, [debouncedSearchTerm]) // eslint-disable-line

  return (
    <div style={{ zIndex: 999, position: 'relative' }}>
      <Autocomplete
        getItemValue={(item) => item.acceptorName}
        wrapperStyle={{ display: 'inline' }}
        items={merchants}
        value={value}
        renderItem={(item, isHighlighted) => (
          <div className={'pr-3'} style={{ background: isHighlighted ? 'lightgray' : 'white' }} key={item.id}>
            {item.acceptorName}
          </div>
        )}
        onChange={handleOnChange}
        onSelect={(value, item) => handleOnSelect(value, item)}
        menuStyle={{
          borderRadius: '3px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '2px 0',
          fontSize: '90%',
          position: 'fixed',
          overflow: 'auto',
          maxHeight: '50%',
          zIndex: '999 !important',
        }}
        inputProps={{ className: 'border-bottom' }}
      />
    </div>
  )
}

export default AcceptorSelect
