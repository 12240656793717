import React, { useState, useEffect } from 'react'
import { IonItem, IonChip, IonPage, IonContent, IonItemDivider, IonLabel, IonButton } from '@ionic/react'
import { IonIcon } from '@ionic/react'
import { add, time, calendarClear } from 'ionicons/icons'
import { useParams } from 'react-router'
import { ApiService, useToast, Header, Modal } from '../../ayria'
import GroupTransferInstanceForm from './GroupTransferInstanceForm'
import GroupTransferInstanceView from './GroupTransferInstanceView'

const GroupTransferInstanceList = () => {
  const api = new ApiService()
  const toast = useToast()
  const { acceptorCode } = useParams<any>()
  const [inprogress, setInprogress] = useState(false)
  const [hInprogress, setHInprogress] = useState(false)
  const [items, setItems] = useState([])
  const [histories, setHistories] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [request, setRequest] = useState()
  const [templateId, setTemplateId] = useState()

  const getItems = async () => {
    setInprogress(true)
    const res: any = await api.get(`/customer/group-transfers/templates/${acceptorCode}?sort=createdDate,desc`)
    if (res.ok) {
      setItems(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const getHistories = async () => {
    setHInprogress(true)
    const res: any = await api.get(`/customer/group-transfers/instances/${acceptorCode}?sort=createdDate,desc`)
    if (res.ok) {
      setHistories(res.data)
    } else {
      toast.error(res)
    }
    setHInprogress(false)
  }

  const onFormSuccess = async () => {
    setShowAddModal(false)
    await getItems()
    await getHistories()
  }

  useEffect(() => {
    getItems()
    getHistories()
  }, []) // eslint-disable-line

  return (
    <IonPage>
      <IonContent>
        <Header />
        <div className='ion-padding'>
          <IonItemDivider>
            <IonLabel>{'لیست انتقال گروهی'}</IonLabel>
            <IonButton
              slot='end'
              color={items.length === 0 ? 'primary' : 'medium'}
              onClick={() => {
                setTemplateId(undefined)
                setShowAddModal(true)
              }}>
              <IonIcon icon={add} slot='start' />
              {'ایجاد گروه جدید'}
            </IonButton>
          </IonItemDivider>
          {items.map((itm: any) => (
            <IonItem key={itm.id}>
              <IonLabel>{itm.name}</IonLabel>
              <IonButton
                slot='end'
                color='primary'
                onClick={() => {
                  setTemplateId(itm.id)
                  setShowAddModal(true)
                }}>
                <IonIcon icon={add} slot='start' />
                {'تکرار'}
              </IonButton>
            </IonItem>
          ))}
          {items.length === 0 && !inprogress && <IonChip>{'رکوردی وجود ندارد'}</IonChip>}
          <br />
          <br />
          <IonItemDivider className='ion-margin-top'>
            <IonLabel>{'تاریخچه انتقال گروهی'}</IonLabel>
          </IonItemDivider>
          {histories.map((itm: any) => (
            <IonItem
              onClick={() => {
                setRequest(itm)
                setShowViewModal(true)
              }}
              key={itm.id}>
              <IonLabel>
                {itm.name}
                <p>
                  <IonIcon icon={time} />
                  {itm.createdTimePersian}
                  <IonIcon icon={calendarClear} className='ion-padding-start' />
                  {itm.createdDatePersian.replaceAll('-', '/')}
                </p>
              </IonLabel>
            </IonItem>
          ))}
          {histories.length === 0 && !hInprogress && <IonChip>{'رکوردی وجود ندارد'}</IonChip>}
        </div>
        <Modal
          showModal={showAddModal}
          toggle={setShowAddModal}
          title={'ایجاد انتقال گروهی'}
          onClosed={() => setShowAddModal(false)}
          content={
            <GroupTransferInstanceForm templateId={templateId} onSuccess={onFormSuccess} acceptorCode={acceptorCode} />
          }
        />
        <Modal
          showModal={showViewModal}
          toggle={setShowViewModal}
          title={`${request?.name} ${request?.createdDatePersian?.replaceAll('-', '/')}`}
          onClosed={() => setShowViewModal(false)}
          content={<GroupTransferInstanceView requestId={request?.id} acceptorCode={acceptorCode} />}
          actionButtons={<IonButton onClick={() => setShowViewModal(false)}>{'بستن'}</IonButton>}
        />
      </IonContent>
    </IonPage>
  )
}

export default GroupTransferInstanceList
