import React, { useState, useEffect } from 'react'
import { IonButton, IonIcon, IonRow, IonCol, IonFabButton, IonInput, IonLabel, IonItem, IonText } from '@ionic/react'
import { IonCheckbox, IonSpinner, IonTextarea, IonGrid, IonAlert } from '@ionic/react'
import { chevronDown, chevronBack, create, add, remove, print, shareSocial, copy, cart } from 'ionicons/icons'
import { bicycle, home, wallet, cash, card } from 'ionicons/icons'
import { Share } from '@capacitor/share'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { AyriaPaymentQRCode, AcceptorLogo } from '../../units'
import useInterval from '../../../utility/useInterval'
import { ApiService, AcceptorAttachDelivery, AcceptorAttachWaiter, KalaSuggest } from '../../../ayria'
import { useToast, Modal, connect, AyriaIcon, EventEmitter } from '../../../ayria'
import { CurrencyInput, NumberInput, User, convertToEnglish, isDesktop } from '../../../ayria'
import AyriaPaymentPrint from './AyriaPaymentPrint'
import AyriaPaymentKitchenPrint from './AyriaPaymentKitchenPrint'
import './AyriaPayment.scss'
import PinButton from '../../../pages/shared/map/PinButton'
import PayerName from './PayerName'

interface OwnProps {
  payment: any
  acceptorCode?: string
  onSuccess: (res: boolean) => void
  showCashierActions?: boolean
  showDeliveryActions?: boolean
  autoConfirm?: string
  autoPrint?: string
}

interface StateProps {
  hasDeliveryMan: boolean
  hasWaiter: boolean
  sendDeliveryStatus: boolean
  specifyWaiterStatus: boolean
  loggedUser?: User
}

interface AyriaPaymentProps extends OwnProps, StateProps {}

const AyriaPayment: React.FC<AyriaPaymentProps> = ({
  payment,
  acceptorCode = '',
  onSuccess,
  showCashierActions = false,
  showDeliveryActions = false,
  autoConfirm,
  hasDeliveryMan,
  hasWaiter,
  sendDeliveryStatus,
  specifyWaiterStatus,
  loggedUser,
  autoPrint,
}) => {
  const api = new ApiService()
  const { t } = useTranslation()
  const toast = useToast()
  const [cancelDescription, setCancelDescription] = useState('')
  const [showCancelConfirmModal, setShowCancleConfigmModal] = useState(false)
  const [showAttachDeliveryModal, setShowAttachDeliveryModal] = useState(false)
  const [showAttachWaiterModal, setShowAttachWaiterModal] = useState(false)
  const [inprogress, setInprogress] = useState(false)
  const [cashierUpdateInprogress, setCashierUpdateInprogress] = useState(false)
  const [orderDoneUpdateInprogress, setOrderDoneUpdateInprogress] = useState(false)
  const [item, setItem] = useState(payment)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [callbackFunction, setCallbackFunction] = useState('')
  const [confirmationText, setConfirmationText] = useState('')
  const [isAmountDetailOpen, setIsAmountDetailOpen] = useState(true)
  const [isAmountEditOpen, setIsAmountEditOpen] = useState(false)
  const [show] = useState(!autoConfirm)
  const [currentKala, setCurrentKala] = useState<any>()
  const [kalaPrice, setKalaPrice] = useState<any>()
  const [kalaCount, setKalaCount] = useState<any>(1)
  const [kalas, setKalas] = useState<any>([])
  const [kalasPrice, setKalasPrice] = useState(0)
  const [kalaReset, setKalaReset] = useState(false)
  const [description, setDescription] = useState(payment.description)
  const [showPickupNotifyModal, setShowPickupNotifyModal] = useState(false)
  const [printType, setPrintType] = useState<any>()
  const [printConfirm, setPrintConfirm] = useState(false)

  const showConfirm = (callback: string) => {
    setCallbackFunction(callback)
    if (callback === 'deliver') {
      setConfirmationText('آیا می‌خواهید گزینه تحویل را ادامه دهید؟')
    } else if (callback === 'cash' || callback === 'cashCenter') {
      setConfirmationText('آیا می‌خواهید گزینه پرداخت نقدی را ادامه دهید؟')
    } else if (callback === 'pos' || callback === 'posCenter') {
      setConfirmationText('آیا می‌خواهید گزینه پرداخت با دستگاه کارتخوان را ادامه دهید؟')
    } else if (callback === 'onMove') {
      setConfirmationText('آیا می‌خواهید سفر خود برای تحویل کالا را شروع کنید؟')
    }
    setShowConfirmModal(true)
  }

  const cancelForReal = async () => {
    setInprogress(true)
    const res: any = await api.post('/user/ayria-payments/cancel', {
      trackingNumber: payment.trackingNumber,
      cancelDescription,
    })
    if (res.ok) {
      toast.success(t('label.successfulOperation'))
      setShowCancleConfigmModal(false)
      onSuccess(true)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const markAsDeliveredForReal = async (paymentMethod: string) => {
    setInprogress(true)
    const res: any = await api.post(
      `/delivery-acceptor/deliver/${acceptorCode}/${paymentMethod}/${payment.trackingNumber}`,
      {}
    )
    if (res.ok) {
      toast.success(t('label.successfulOperation'))
      setItem(res.data)
      onSuccess(true)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const markAsOnMoveForReal = async () => {
    setInprogress(true)
    const res: any = await api.put(`/delivery-acceptor/onMove/${acceptorCode}/${payment.trackingNumber}`, {})
    if (res.ok) {
      toast.success('وضعیت کالا به درحال ارسال تغییر کرد')
      setItem(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const markAsPaidForReal = async (paymentMethod: string) => {
    setInprogress(true)
    const res: any = await api.post(
      `/secretary-and-acceptor/paid/${acceptorCode}/${paymentMethod}/${payment.trackingNumber}`,
      {}
    )
    if (res.ok) {
      if (!autoConfirm) {
        toast.success(t('label.successfulOperation'))
      }
      setItem(res.data)
      if (sendDeliveryStatus && !isDesktop()) {
        setShowAttachDeliveryModal(true)
      } else if (specifyWaiterStatus) {
        setShowAttachWaiterModal(true)
      } else {
        onSuccess(true)
      }
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }
  const getItem = async () => {
    const res: any = await api.get(`/acceptor-roles/${acceptorCode}/ayria-payment/${payment.trackingNumber}`)
    setItem(res.ok ? res.data : payment)
  }
  const updateAyriaPayment = async () => {
    const res: any = await api.post(`/acceptor-roles/${acceptorCode}/ayria-payment/update`, {
      kalas,
      referenceCode: payment.referenceCode,
      acceptorCode,
      description,
    })
    if (res.ok) {
      setItem(res.data)
      setIsAmountEditOpen(false)
    } else {
      toast.error(res)
    }
  }
  const setMeAsCashier = async () => {
    setCashierUpdateInprogress(true)
    const res: any = await api.acceptorRolesAyriaPaymentUpdateCashier(acceptorCode, payment.trackingNumber)
    if (res.ok) {
      setItem(res.data)
    } else {
      toast.error(res)
    }
    setCashierUpdateInprogress(false)
  }
  const updateOrderDoneStatus = async (orderDone: any) => {
    setOrderDoneUpdateInprogress(true)
    const res: any = await api.acceptorRolesAyriaPaymentUpdateOrderDone(
      acceptorCode,
      payment.trackingNumber,
      JSON.stringify(orderDone)
    )
    if (res.ok) {
      setItem(res.data)
      EventEmitter.dispatch('ONLINE_ORDER_COUNT_NEED_UPDATE', {})
    } else {
      toast.error(res)
    }
    setOrderDoneUpdateInprogress(false)
  }
  const sendPickupNotify = async () => {
    setShowPickupNotifyModal(false)
    const res: any = await api.post(`/acceptor-roles/pickup-notify/${acceptorCode}/${payment.trackingNumber}`, {})
    if (res.ok) {
      setItem(res.data)
      toast.success('پیامک دریافت حضوری با موفقیت ارسال شد')
    } else {
      toast.error(res)
    }
  }

  const hasKala = () => {
    if (item?.kalas && item?.kalas?.length > 0) {
      return true
    }
    return false
  }

  const handleAmountClick = () => {
    if (hasKala()) {
      setIsAmountDetailOpen(!isAmountDetailOpen)
    } else if (acceptorCode && item.trackingNumber) {
      setIsAmountEditOpen(!isAmountEditOpen)
    }
  }
  const handleKalaOnBlur = (kala: any) => {
    if (currentKala === undefined) {
      setCurrentKala(kala)
    }
  }
  const addKala = () => {
    if (kalas.filter((item: any) => item.kala.id === currentKala.id).length === 0) {
      kalas.push({ kala: currentKala, count: kalaCount, price: kalaPrice })
      setKalasPrice(kalas.reduce((a: number, b: any) => +a + (b.kala.price ? +b.kala.price : b.price) * b.count, 0))
    }
    setKalas(kalas)
    setCurrentKala(undefined)
    setKalaCount(1)
    setKalaPrice(undefined)
    setKalaReset(!kalaReset)
  }
  const removeKala = (kala: any) => {
    setKalas(kalas.filter((item: any) => item.kala.id !== kala.id))
    setKalasPrice(
      kalas
        .filter((item: any) => item.kala.id !== kala.id)
        .reduce((a: number, b: any) => +a + (b.kala.price ? +b.kala.price : b.price) * b.count, 0)
    )
  }
  const socialShareAyriaPayment = async (item: any) => {
    try {
      await Share.share({
        text: item.paymentText,
        title: !!acceptorCode
          ? item.onlineOrder
            ? `سفارش ${item.trackingNumber}`
            : `صورتحساب ${item.trackingNumber}`
          : `درخواست وجه ${item.trackingNumber}`,
        url: item.paymentUrl,
        dialogTitle: !!acceptorCode
          ? item.onlineOrder
            ? `سفارش ${item.trackingNumber}`
            : `صورتحساب ${item.trackingNumber}`
          : `درخواست وجه ${item.trackingNumber}`,
      })
    } catch (error: any) {
      toast.error(error)
    }
  }

  const funcMap = {} as any
  const executeCallback = () => {
    funcMap[callbackFunction]()
    setShowConfirmModal(false)
  }
  funcMap.method = function (name: string, cb: () => void) {
    this[name] = cb
  }
  funcMap.method('deliver', () => {
    markAsDeliveredForReal('WALLET')
  })
  funcMap.method('cash', () => {
    markAsDeliveredForReal('CASH')
  })
  funcMap.method('pos', () => {
    markAsDeliveredForReal('POS')
  })
  funcMap.method('cashCenter', () => {
    markAsPaidForReal('CASH')
  })
  funcMap.method('posCenter', () => {
    markAsPaidForReal('POS')
  })
  funcMap.method('onMove', () => {
    markAsOnMoveForReal()
  })

  const hasStatus = (item: any) => {
    return (
      (item.paid && !item.refunded) ||
      (item.paid && item.refunded) ||
      item.canceled ||
      (item.deliverable && !item.delivered && !item.canceled) ||
      (item.deliverable && item.delivered)
    )
  }

  useInterval(() => {
    if (showDeliveryActions) {
      getItem()
    }
  }, 5000)

  useEffect(() => {
    if (autoConfirm) {
      if (autoConfirm !== 'WALLET') {
        markAsPaidForReal(autoConfirm)
      } else {
        if (sendDeliveryStatus && !isDesktop()) {
          setShowAttachDeliveryModal(true)
        } else if (specifyWaiterStatus) {
          setShowAttachWaiterModal(true)
        } else {
          setTimeout(() => {
            onSuccess(true)
          }, 300)
        }
      }
    }
    if (acceptorCode) {
      getItem()
    }
    if (autoPrint) {
      setPrintType(autoPrint)
      setTimeout(() => {
        onSuccess(true)
      }, 1000)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!!printType) {
      setTimeout(() => {
        window.print()
        setPrintType(undefined)
      }, 300)
    }
  }, [printType])

  return (
    <>
      <div id='ayriaPayment' className='printable print-only ion-text-start w-6cm'>
        <IonGrid className='w-6cm ion-no-margin' style={{ marginTop: '-10px' }}>
          {(printType === 'advance' || printType === 'both') && <AyriaPaymentPrint item={item} />}
          {printType === 'both' && <div className='page-break'></div>}
          {(printType === 'simple' || printType === 'both') && <AyriaPaymentKitchenPrint item={item} />}
        </IonGrid>
      </div>
      {show && (
        <div id='ayriaPayment' className='none-printable'>
          {item.payeeType === 'ACCEPTOR' && (
            <div>
              <div className='text-center'>
                <AcceptorLogo referralCode={item.payeeReferralCode} width='100px' />
              </div>
              {!item.paid && !autoConfirm && <AyriaPaymentQRCode ayriaPayment={item} />}
            </div>
          )}
          <table className='table'>
            <thead></thead>
            <tbody>
              <tr className='print-only'>
                <td style={{ minWidth: '150px' }}>{'شماره پیگیری'}</td>
                <td style={{ minWidth: '150px' }}>{item.trackingNumber}</td>
              </tr>
              <tr>
                <td>{t('label.mobile')}</td>
                <td>
                  <CopyToClipboard text={item.payerMobile} onCopy={() => toast.info('شماره همراه کپی شد')}>
                    <span className='pointer' dir='ltr'>
                      {item.payerMobile}
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
              {item.payerName && item.payeeType === 'ACCEPTOR' && (
                <tr>
                  <td>{'نام'}</td>
                  <td>
                    <CopyToClipboard text={item.payerName} onCopy={() => toast.info('نام کپی شد')}>
                      <span className='pointer' dir='ltr'>
                        <PayerName item={item} />
                      </span>
                    </CopyToClipboard>
                  </td>
                </tr>
              )}
              {(item.deliveryAddress || item.payerAddress) && item.payeeType === 'ACCEPTOR' && (
                <tr>
                  <td>
                    {'آدرس'}{' '}
                    {item?.shippingGeo && (
                      <PinButton latLng={item?.shippingGeo.substring(6, item?.shippingGeo.length - 1)} divider=' ' />
                    )}
                  </td>
                  <td>
                    <CopyToClipboard
                      text={convertToEnglish(item.deliveryAddress || item.payerAddress)}
                      onCopy={() => toast.info('آدرس کپی شد')}>
                      <span className='pointer'>{item.deliveryAddress || item.payerAddress}</span>
                    </CopyToClipboard>
                  </td>
                </tr>
              )}
              <tr className={hasKala() ? 'pointer' : ''} onClick={handleAmountClick}>
                <td>{t('walletRequest.amount')}</td>
                <td>
                  {(item.unitedAmount || payment.unitedAmount).toLocaleString(navigator.language)} {t('label.riyal')}
                  {hasKala() ? (
                    <IonIcon
                      slot='end'
                      icon={isAmountDetailOpen ? chevronDown : chevronBack}
                      className='left non-printable'
                    />
                  ) : null}
                  {!hasKala() && acceptorCode && item.trackingNumber && (
                    <IonIcon slot='end' icon={create} className='left non-printable' />
                  )}
                </td>
              </tr>
              {hasKala() &&
                isAmountDetailOpen &&
                item.kalas.map((kala: any) => {
                  return (
                    <tr key={kala.name}>
                      <td className='text-center'>
                        <CopyToClipboard text={kala.name} onCopy={() => toast.info(`${kala.name} کپی شد`)}>
                          <span className='pointer'>
                            {kala.name} ({kala.count})
                          </span>
                        </CopyToClipboard>
                        {!!kala.description && (
                          <IonText className='ion-padding-start ion-text-wrap' color='medium'>
                            {kala.description}
                          </IonText>
                        )}
                      </td>
                      <td className='text-right'>
                        {kala.totalPrice.toLocaleString(navigator.language)}
                        {' ریال'}
                      </td>
                    </tr>
                  )
                })}
              {!hasKala() && acceptorCode && isAmountEditOpen && (
                <tr>
                  <td colSpan={2}>
                    <IonRow>
                      <IonCol size='2' className='ion-no-padding non-printable'>
                        <IonFabButton
                          onClick={addKala}
                          size='small'
                          disabled={!currentKala?.name || (!currentKala?.price && !kalaPrice)}>
                          <IonIcon icon={add} />
                        </IonFabButton>
                      </IonCol>
                      <IonCol className='non-printable'>
                        <KalaSuggest
                          onSelect={(kala: any) => setCurrentKala(kala)}
                          onChange={(value: string) => setCurrentKala(undefined)}
                          onBlur={handleKalaOnBlur}
                          acceptorCode={acceptorCode}
                          reset={kalaReset}
                        />
                      </IonCol>
                      {currentKala?.name && (
                        <IonCol>
                          {!currentKala?.price && (
                            <CurrencyInput onValueChange={setKalaPrice} defaultValue={currentKala?.price} />
                          )}
                          {currentKala?.price && <span>{currentKala?.price.toLocaleString(navigator.language)}</span>}
                        </IonCol>
                      )}
                      {currentKala?.name && (
                        <IonCol>
                          <NumberInput placeholder={'تعداد'} onValueChange={setKalaCount} defaultValue={kalaCount} />
                        </IonCol>
                      )}
                    </IonRow>
                    {kalas.map((row: any, index: number) => {
                      return (
                        <IonRow key={row.kala.id}>
                          <IonCol size='2' className='ion-no-padding'>
                            <IonFabButton color='danger' size='small' onClick={() => removeKala(row.kala)}>
                              <IonIcon icon={remove} className='non-printable' />
                            </IonFabButton>
                          </IonCol>
                          <IonCol>{row.kala.name}</IonCol>
                          <IonCol>
                            {(row.kala.price ? row.kala.price : row.price).toLocaleString(navigator.language)}
                          </IonCol>
                          <IonCol>{row.count}</IonCol>
                        </IonRow>
                      )
                    })}
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel position='stacked'>{'توضیحات'}</IonLabel>
                          <IonInput value={description} onIonChange={(e: any) => setDescription(e.detail.value)} />
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonButton
                          onClick={updateAyriaPayment}
                          disabled={item.amount !== kalasPrice}
                          className='non-printable'>
                          {'بروز رسانی'}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </td>
                </tr>
              )}
              {item.shippingFee > 0 && (
                <tr>
                  <td>{'هزینه پیک'}</td>
                  <td>
                    {item.shippingFee.toLocaleString(navigator.language)} {'ریال'}
                  </td>
                </tr>
              )}
              {item.tax > 0 && (
                <tr>
                  <td>{'مالیات بر ارزش افزوده'}</td>
                  <td>
                    {item.tax.toLocaleString(navigator.language)} {'ریال'}
                  </td>
                </tr>
              )}
              {item.tip > 0 && (
                <tr>
                  <td>{'انعام'}</td>
                  <td>
                    {item.tip.toLocaleString(navigator.language)} {'ریال'} {!!item?.waiter ? `(${item.waiter})` : ''}
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <strong>{'جمع پرداختی'}</strong>
                </td>
                <td>
                  <strong>
                    {item.customerAmountActual.toLocaleString(navigator.language)} {'ریال'}
                  </strong>
                </td>
              </tr>
              {description && !isAmountEditOpen && (
                <tr>
                  <td>{t('label.description')}</td>
                  <td>
                    <CopyToClipboard text={item.description} onCopy={() => toast.info('توضیحات کپی شد')}>
                      <span className='pointer'>{item.description}</span>
                    </CopyToClipboard>
                  </td>
                </tr>
              )}
              <tr>
                <td>{t('walletRequest.createdDate')}</td>
                <td>
                  {item.onlineOrder && (
                    <IonIcon icon={cart} color={!!item.orderDone ? 'dark' : !!item.cashier ? 'warning' : 'danger'} />
                  )}
                  {item.createdTime} {item.createdDate}
                </td>
              </tr>
              {item.receiveDate && (
                <tr>
                  <td>{'زمان تحویل'}</td>
                  <td>
                    {item.receiveType === 'PICKUP' && (
                      <img src='/assets/images/pickup.png' alt='' width='25' height='25' />
                    )}
                    {!!item.pickupNotify && <>{item.receiveDate}</>}
                    <span className='text-bold' style={{ paddingLeft: '5px' }}>
                      {item.receiveTime}
                    </span>
                    {!item.pickupNotify && (
                      <IonButton onClick={() => setShowPickupNotifyModal(true)}>{'پیامک'}</IonButton>
                    )}
                  </td>
                </tr>
              )}
              {item.tableIdentifier && (
                <tr>
                  <td>{'میز'}</td>
                  <td>
                    {item.receiveType === 'TABLES' && (
                      <img src='/assets/images/tables.png' alt='' width='25' height='25' />
                    )}
                    <IonButton color='dark' fill='outline' size='small'>
                      {item.tableIdentifier}
                    </IonButton>
                  </td>
                </tr>
              )}
              {hasStatus(item) && (
                <tr>
                  <td>{t('walletRequest.status')}</td>
                  <td>
                    {item.paid && !item.refunded && <span className='p-1 text-success'>{t('walletRequest.paid')}</span>}
                    {item.paid && item.refunded && (
                      <span className='p-1 text-danger'>{t('walletRequest.refunded')}</span>
                    )}
                    {item.canceled && <span className='p-1'>{t('walletRequest.canceled')}</span>}
                    {item.deliverable && !item.delivered && !item.canceled && (
                      <span className='p-1'>
                        <AyriaIcon icon={bicycle} title={t('walletRequest.deliverable')} />
                      </span>
                    )}
                    {item.deliverable && item.delivered && (
                      <span className='p-1'>
                        <AyriaIcon icon={home} title={t('walletRequest.delivered')} />
                      </span>
                    )}
                  </td>
                </tr>
              )}
              {item.paid && (
                <tr>
                  <td>نحوه پرداخت</td>
                  <td>
                    {item.paymentMethod === 'WALLET' && <AyriaIcon icon={wallet} title={'کیف پول'} />}
                    {item.paymentMethod === 'CASH' && <AyriaIcon icon={cash} title={'نقد'} />}
                    {item.paymentMethod === 'POS' && <AyriaIcon icon={card} title={'کارتخوان'} />}
                  </td>
                </tr>
              )}
              {showCashierActions &&
                (item.cashier === loggedUser?.login || loggedUser?.authorities?.includes('ROLE_ACCEPTOR')) &&
                item.onlineOrder && (
                  <tr>
                    <td colSpan={2} style={{ padding: 0 }}>
                      <IonItem lines='none'>
                        {!orderDoneUpdateInprogress && (
                          <IonCheckbox
                            checked={item.orderDone}
                            slot='start'
                            onIonChange={(e) => updateOrderDoneStatus(e.detail.checked)}></IonCheckbox>
                        )}
                        {orderDoneUpdateInprogress && <IonSpinner name='crescent' color='dark' />}
                        <IonLabel>{'به سفارش رسیدگی شد'}</IonLabel>
                      </IonItem>
                    </td>
                  </tr>
                )}
              {showCashierActions && item.cashier && (
                <tr>
                  <td>{'صندوقدار'}</td>
                  <td>{item.cashierName}</td>
                </tr>
              )}
              {item.canceled && item.cancelDescription && (
                <tr>
                  <td>{'توضیحات لغو'}</td>
                  <td>{item.cancelDescription}</td>
                </tr>
              )}
              {item.payeeType === 'ACCEPTOR' && item.deliveryMan && (
                <tr>
                  <td>{'پیک'}</td>
                  <td>{item.deliveryMan}</td>
                </tr>
              )}
              {showCashierActions && !item.canceled && !item.paid && (
                <tr className='non-printable'>
                  <td colSpan={2}>
                    {'تغییر نحوه پرداخت به: '}
                    <IonButton onClick={() => showConfirm('cashCenter')}>{'نقد'}</IonButton>
                    <IonButton onClick={() => showConfirm('posCenter')}>{'کارت خوان'}</IonButton>
                  </td>
                </tr>
              )}
              {showCashierActions && item.trackingNumber && (
                <tr className='non-printable'>
                  <td className='text-center' colSpan={2}>
                    {!item.cashier && item.onlineOrder && (
                      <IonButton disabled={cashierUpdateInprogress} color='warning' onClick={setMeAsCashier}>
                        {'مسئول سفارش آنلاینم'}
                      </IonButton>
                    )}
                    {!item.delivered &&
                      !item.refunded &&
                      !item.canceled &&
                      item.payeeType === 'ACCEPTOR' &&
                      item.receiveType !== 'PICKUP' &&
                      item.receiveType !== 'TABLES' &&
                      hasDeliveryMan && (
                        <span>
                          <IonButton onClick={() => setShowAttachDeliveryModal(true)} className='non-printable'>
                            {'ارسال به پیک'}
                          </IonButton>
                        </span>
                      )}
                    {!item.waiter &&
                      !item.canceled &&
                      item.payeeType === 'ACCEPTOR' &&
                      hasWaiter &&
                      item.receiveType !== 'PICKUP' &&
                      item.receiveType !== 'TABLES' && (
                        <span>
                          <IonButton
                            color={item.tip && !item.waiter ? 'warning' : 'primary'}
                            onClick={() => setShowAttachWaiterModal(true)}>
                            {'انتخاب پیشخدمت'}
                          </IonButton>
                        </span>
                      )}
                  </td>
                </tr>
              )}
              {showDeliveryActions && !item.delivered && (
                <tr className='non-printable'>
                  <td className='text-center' colSpan={2}>
                    <div className='flex-container' style={{ justifyContent: 'space-evenly' }}>
                      {!item.delivered && item.shippingStatus !== 'ON_MOVE' && item.shippingStatus !== 'DELIVERED' && (
                        <IonButton
                          disabled={'REFUNDED' === item.shippingStatus || inprogress}
                          className='non-printable'
                          onClick={() => showConfirm('onMove')}>
                          {'آغاز تحویل کالا'}
                        </IonButton>
                      )}
                      {!item.delivered && ('ON_MOVE' === item.shippingStatus || 'DELIVERED' === item.shippingStatus) && (
                        <IonButton
                          disabled={!item.paid || 'REFUNDED' === item.shippingStatus || inprogress}
                          className='non-printable'
                          onClick={() => showConfirm('deliver')}>
                          {'تحویل'}
                        </IonButton>
                      )}
                      {!item.delivered && !item.paid && (
                        <IonButton
                          disabled={item.paid || 'REFUNDED' === item.shippingStatus || inprogress}
                          className='non-printable'
                          onClick={() => showConfirm('cash')}>
                          {'نقدی'}
                        </IonButton>
                      )}
                      {!item.delivered && !item.paid && (
                        <IonButton
                          disabled={item.paid || 'REFUNDED' === item.shippingStatus || inprogress}
                          className='non-printable'
                          onClick={() => showConfirm('pos')}>
                          {'کارتخوان'}
                        </IonButton>
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {(showCashierActions || item.payeeType === 'USER') && !item.canceled && !item.paid && (
                <tr className='non-printable'>
                  <td colSpan={2} className='text-center'>
                    <IonButton color='danger' onClick={() => setShowCancleConfigmModal(true)} className='non-printable'>
                      {item.payeeType === 'USER' ? t('wallet.doCancelRequest') : t('wallet.doCancel')}
                    </IonButton>
                  </td>
                </tr>
              )}
              <tr className='non-printable'>
                <td colSpan={2} className='text-center'>
                  {item?.department === 'RESTAURANT' && (
                    <IonButton onClick={() => setPrintType('simple')}>
                      <IonIcon icon={print} slot='start' />
                      {'چاپ آشپزخانه'}
                    </IonButton>
                  )}
                  <IonButton onClick={() => setPrintType('advance')}>
                    <IonIcon icon={print} slot='start' />
                    {'چاپ'}
                  </IonButton>
                  {item?.paymentUrl && (
                    <CopyToClipboard text={item.paymentUrl} onCopy={() => toast.info('لینک پرداخت کپی شد')}>
                      <IonButton>
                        <IonIcon icon={copy} slot='start' />
                        {'کپی لینک'}
                      </IonButton>
                    </CopyToClipboard>
                  )}
                  {item?.paymentUrl && (
                    <IonButton onClick={() => socialShareAyriaPayment(item)}>
                      <IonIcon icon={shareSocial} slot='start' />
                      {'اشتراک گذاری'}
                    </IonButton>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <Modal
            showModal={showCancelConfirmModal}
            toggle={setShowCancleConfigmModal}
            title={'تایید لغو'}
            isPlatformTheme={item.payeeType === 'ACCEPTOR'}
            onClosed={() => setShowCancleConfigmModal(false)}
            content={
              <div>
                <div>{'آیا از لغو کردن درخواست اطمینان دارید؟'}</div>
                <br />
                <IonItem>
                  <IonTextarea
                    autoGrow={true}
                    onIonChange={(e) => setCancelDescription(e.detail.value!)}
                    placeholder='توضیحات لغو'></IonTextarea>
                </IonItem>
                <br />
                <div className='ion-text-right'>
                  <IonButton color='danger' onClick={cancelForReal} className='non-printable'>
                    {'تایید'}
                  </IonButton>
                  <IonButton
                    color='light'
                    className='ion-padding-start non-printable'
                    onClick={(e) => setShowCancleConfigmModal(false)}>
                    {'انصراف'}
                  </IonButton>
                </div>
              </div>
            }
          />
        </div>
      )}
      <Modal
        showModal={showAttachDeliveryModal}
        toggle={setShowAttachDeliveryModal}
        title={'ارسال به پیک'}
        isPlatformTheme={true}
        onClosed={() => setShowAttachDeliveryModal(false)}
        content={
          <AcceptorAttachDelivery acceptorCode={acceptorCode} ayriaPayment={item} onSuccess={() => onSuccess(true)} />
        }
      />
      <Modal
        showModal={showAttachWaiterModal}
        toggle={setShowAttachWaiterModal}
        title={'انتخاب پیشخدمت'}
        isPlatformTheme={true}
        onClosed={() => setShowAttachWaiterModal(false)}
        content={
          <AcceptorAttachWaiter acceptorCode={acceptorCode} ayriaPayment={item} onSuccess={() => onSuccess(true)} />
        }
      />
      <Modal
        showModal={showConfirmModal}
        toggle={setShowConfirmModal}
        title={t('label.confirm')}
        isPlatformTheme={item.payeeType === 'ACCEPTOR'}
        onClosed={() => setShowConfirmModal(false)}
        content={
          <div>
            <p>{confirmationText}</p>
            <div>
              <IonButton disabled={inprogress} onClick={executeCallback}>
                {t('label.yes')}
              </IonButton>
              <IonButton color='light' className='ion-padding-start' onClick={() => setShowConfirmModal(false)}>
                {t('label.cancel')}
              </IonButton>
            </div>
          </div>
        }
      />
      <Modal
        showModal={showPickupNotifyModal}
        toggle={setShowPickupNotifyModal}
        title={'ارسال پیامک برای تحویل حضوری'}
        isPlatformTheme={true}
        onClosed={() => setShowPickupNotifyModal(false)}
        content={
          <div className='ion-padding'>
            {'ارسال پیامک جهت اطلاع رسانی دریافت حضوری سفارش'}
            <IonItem>
              <IonLabel>
                <span className='h4'>{item.trackingNumber}</span>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {item.payerMobile} {item.payerName}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <span className='h5'>{item.receiveTime}</span> {item.receiveDate}
              </IonLabel>
            </IonItem>
          </div>
        }
        actionButtons={
          <>
            <IonButton color='primary' onClick={() => sendPickupNotify()}>
              {'ارسال'}
            </IonButton>
            <IonButton color='dark' onClick={() => setShowPickupNotifyModal(false)}>
              {'انصراف'}
            </IonButton>
          </>
        }
      />
      <IonAlert
        isOpen={printConfirm}
        onDidDismiss={() => setPrintConfirm(false)}
        header={'پرینت صورتحساب'}
        subHeader={''}
        message={'پرینت مورد نظر را انتخاب کنید'}
        buttons={[
          {
            text: 'بدون قیمت',
            handler: () => {
              setPrintConfirm(false)
              setTimeout(() => {
                setPrintType('simple')
              }, 300)
            },
          },
          {
            text: 'با قیمت',
            handler: () => {
              setPrintConfirm(false)
              setTimeout(() => {
                setPrintType('advance')
              }, 300)
            },
          },
        ]}
      />
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    hasDeliveryMan: state.shop.hasDeliveryMan,
    hasWaiter: state.shop.hasWaiter,
    sendDeliveryStatus: state.shop.sendDeliveryStatus,
    specifyWaiterStatus: state.shop.specifyWaiterStatus,
    loggedUser: state.user.loggedUser,
  }),
  component: AyriaPayment,
})
