import { IonButton, IonIcon, IonItem, IonItemDivider, IonItemOption } from '@ionic/react'
import { IonItemOptions, IonItemSliding, IonLabel, IonSpinner, IonToggle } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { add, trash, leaf } from 'ionicons/icons'
import { Modal, useToast } from '../../utility'
import ClubSpecEdit from './MyClubSpecEdit'
import { ApiService } from '../../ayria'
import { useTranslation } from 'react-i18next'

interface MyClubSpecsProps {
  code: string
}

const MyClubSpecs: FC<MyClubSpecsProps> = ({ code }) => {
  const api = new ApiService()
  const toast = useToast()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [items, setItems] = useState([])
  const [inprogress, setInprogress] = useState(false)
  const [clubSpecId, setClubSpecId] = useState()

  const onCreateSuccess = async () => {
    setShowModal(false)
    await fetchSpecs()
  }

  const fetchSpecs = async () => {
    setInprogress(true)
    const res: any = await api.get(`/club-spec/list/${code}`)
    if (res.ok) {
      setItems(res.data)
    } else {
      toast.error(res)
    }
    setInprogress(false)
  }

  const changeActivation = async (clubSpec: any, value: boolean) => {
    if (clubSpec?.activated !== value && !inprogress) {
      setInprogress(true)
      const res: any = await api.put(`/club-spec/activation/${code}/${clubSpec?.id}/${value}`, {})
      if (res.ok) {
        await fetchSpecs()
      } else {
        toast.error(res)
        setInprogress(false)
      }
    }
  }

  const markAsDeleted = async (clubSpec: any, deleted: boolean) => {
    if (clubSpec?.deleted !== deleted && !inprogress) {
      setInprogress(true)
      const res: any = await api.delete(`/club-spec/deletion/${code}/${clubSpec?.id}/${deleted}`)
      if (res.ok) {
        await fetchSpecs()
      } else {
        toast.error(res)
        setInprogress(false)
      }
    }
  }

  useEffect(() => {
    fetchSpecs()
  }, []) // eslint-disable-line

  return (
    <>
      <IonItemDivider>
        {'خصوصیات باشگاه'}
        {inprogress && <IonSpinner slot='end' />}
        <IonButton
          slot='end'
          onClick={() => {
            setClubSpecId(undefined)
            setShowModal(true)
          }}>
          <IonIcon icon={add} />
          {'خصوصیت جدید'}
        </IonButton>
      </IonItemDivider>
      {items.map((itm: any, idx: number) => (
        <IonItemSliding key={itm.id + ' ' + idx}>
          <IonItem>
            <IonLabel color={itm?.activated ? 'success' : itm?.deleted ? 'danger' : 'medium'}>
              {t(itm?.specType)} {!!itm?.group?.name ? `(${itm?.group?.name})` : ''}
              <p className='text-dark'>{t(itm?.rewardType)}</p>
            </IonLabel>
            <IonToggle
              checked={itm?.activated}
              onIonChange={(e) => changeActivation(itm, e.detail.checked)}
              disabled={inprogress || itm?.deleted}
            />
            <IonButton
              color={itm?.activated ? 'success' : 'light'}
              disabled={itm?.deleted || !itm?.activated}
              className='ion-margin-horizontal'
              onClick={() => {
                setClubSpecId(itm?.id)
                setShowModal(true)
              }}>
              {itm?.rewardType === 'VOUCHER'
                ? itm?.amount.toLocaleString(navigator.language) + ' ریال '
                : itm?.amount + ' امتیاز '}
            </IonButton>
          </IonItem>
          <IonItemOptions slot='end' style={{ width: '40px' }}>
            {!itm?.deleted && (
              <IonItemOption color='danger' onClick={() => markAsDeleted(itm, true)}>
                <IonIcon icon={trash} />
              </IonItemOption>
            )}
            {itm?.deleted && (
              <IonItemOption color='success' onClick={() => markAsDeleted(itm, false)}>
                <IonIcon icon={leaf} />
              </IonItemOption>
            )}
          </IonItemOptions>
        </IonItemSliding>
      ))}
      <Modal
        title={'خصوصیت باشگاه'}
        showModal={showModal}
        toggle={setShowModal}
        onClosed={() => {
          setShowModal(false)
        }}
        content={
          <ClubSpecEdit
            code={code}
            onSuccess={onCreateSuccess}
            onCancel={() => setShowModal(false)}
            clubSpecId={clubSpecId}
          />
        }
      />
    </>
  )
}

export default MyClubSpecs
